import "./all-tim.css"
import { useLocation, useNavigate } from "react-router-dom"
import InfoBox from "../../components/infoBox/InfoBox";
import RectangleCard from "../../components/rectangleCard/RectangleCard";
import HeaderSecondary from "../../components/headerSecondary/HeaderSecondary";
import DownBar from "../../components/downBar/DownBar";
import { useEffect, useState } from "react";
import AuthService from "../../Auth/auth";
import Button1 from "../../components/Button1";
import Loader from "../../components/Loader/Loader";
import Mod1 from "../../components/mod1/Mod1";
import infoIcon from "../../assets/images/infoIcon.svg"
import alertIcon from "../../assets/images/alert.svg"
// import Progress_bar from "../../components/ProgressBar";


function AllTim() {
    const location = useLocation();
    const navigate = useNavigate();
    const maxValid = 4;
    const minValid = 1;
    const retestAttemt = 3;

    const [downBar, setDownBar] = useState(false);
    const [dataForDownBar, setDataForDownBar] = useState({});
    const [calibrate, setCalibrate] = useState(false);
    const [timdevices, setTimDevices] = useState(location.state.child ? location.state.child : location.state.parent)
    const [loading, setloading] = useState(true);
    const [progressBar, setProgressBar] = useState(false);
    const [calibrateNowButtonClicked, setcalibrateNowButtonClicked] = useState({ showModal: false, modalValue: false })
    const [modalDetails, setmodalDetails] = useState({
        primaryText: "Please ensure the room is vacant.",
        secondaryText: "Click below to calibrate TIM. Please ensure room is vacant during calibration.",
        icon: alertIcon,
        inputBox: false,
        confirmButtonText: "Confirm Vacant"
    });
    const [modalInputBox, setModalInputBox] = useState(1);
    const [testButtonClicked, setTestButtonClicked] = useState(false)
    const [dataForTest, setDataForTest] = useState({})
    const [infoBoxText, setinfoBoxText] = useState({ text: "test" })

    let floorName = location.state.floorDetails[0].name;
    useEffect(() => {
        async function fetchAgentId() {
            setloading(true)
            let rawDatawithAgentId = {}
            let deviceIdArr = (timdevices).map(obj =>
                obj.device.id
            );
            console.log("------", timdevices)
            deviceIdArr.forEach(deviceId => {
                rawDatawithAgentId[deviceId] = location.state.rawData[deviceId]
            })
            location.state.rawData = rawDatawithAgentId
            // console.log(location.state.rawData);
            const key = localStorage.getItem("spaceio-key")


            const timDev = location.state.child ? [...location.state.child] : [...location.state.parent];

            // let result1 = await Promise.all((timDev).map((val) =>
            //     AuthService.getAssetId(val.id)
            // ))

            for (let i = 0; i < timDev.length; i++) {
                // timDev[i]["assetId"] = result1[i].assetId
                timDev[i].device.hardware.agentId = location.state.rawData[timDev[i].device.id].hardware.agentId
                timDev[i]["macId"] = location.state.rawData[timDev[i].device.id].hardware.deviceId
            }

            if (location.state.child) location.state.child = timDev
            else location.state.parent = timDev

            let result2 = await Promise.all(timDev.map(async (val) =>
                AuthService.readDataSPOT(key, Array(`${val.assetId}`))
            ))

            for (let i = 0; i < timDev.length; i++) {
                // timDev[i].status = result2[i][0]["setup_status"]
                timDev[i]['spotData'] = result2[i][0]

            }
            let timDevSetUpObj = {}
            let setUp = []
            let calstatus = []
            let calibrationStatus = []
            timDev.forEach(obj => {
                setUp.push(obj.spotData.setup_status)
                calstatus.push(obj.spotData.cal_status)
                calibrationStatus.push(obj.spotData.calibration_status)
                timDevSetUpObj = {
                    setup_status: setUp,
                    cal_status: calstatus,
                    calibration_status: calibrationStatus,
                }
            })
            if (timDevSetUpObj["setup_status"].every(status => status === 1) && timDevSetUpObj["cal_status"].every(status => (status === 2 || status === 3)) && (timDevSetUpObj["calibration_status"].every(status => status === 0) || timDevSetUpObj["calibration_status"].includes(1))) {
                setCalibrate(true)
                setinfoBoxText({ text: "Set-up for all the TiMs in this room is done now. To calibrate ensure that the room is vacant." });

            }
            else {
                setCalibrate(false)
                setinfoBoxText({ text: "Set-up all the TIM devices in each room to start calibration." });

            }
            //check this
            if (timDevSetUpObj["cal_status"].every(status => status === 3) && (timDevSetUpObj["calibration_status"].every(status => status === 3))) setinfoBoxText({ text: "Click on respective test button" })
            else if (timDevSetUpObj["cal_status"].every(status => status === 2) && (timDevSetUpObj["calibration_status"].every(status => (status === 2 || status === 1)))) {
                setinfoBoxText({ text: "Device not responding. Please recalibrate." })
                setCalibrate(true)
            }


            //show calibrate button
            setTimDevices(timDev);
            setloading(false)

        }
        fetchAgentId()


    }, [])

    // useEffect(() => {
    //     console.log("timDevices changed", timdevices)
    // }, [timdevices])

    const setModalDetailsAfterCheck = (value) => {

        let val = parseInt(value)
        //console.log(val)
        setModalInputBox(val)
        setTimeout(function () {
            if (val < minValid) setModalInputBox(1)
            else if (val > maxValid) setModalInputBox(maxValid)
        }
            , 1500)
    }

    const handleCalibrateButtonClicked = () => {
        setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: true })
    }

    const handleCancelButtonClicked = () => {
        setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: false })
    }
    //console.log("aaaa")

    const handleConfirmButtonClickedForVacant = () => {

        setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: false, modalValue: true })

        const timDev = [...timdevices]

        async function fetchAgentStatus() {
            //console.log("fetching agent status")
            setloading(true)
            setProgressBar(true);

            let agentIdArr = []
            let dataToWrite = []

            let myresult = await Promise.all((timdevices).map((i) => {
                agentIdArr.push({ agentId: i.device.hardware.agentId, assetId: i.assetId, ...i.spotData })
                return AuthService.readTIMData(i.device.hardware.agentId)
            }
            ))
            // console.log("--//--", timdevices)
            // console.log("--//--", agentIdArr)
            // let spotDataToWrite = {
            //     "utcEpoch": utcTimestamp,
            //     "height_set": agentIdArr[i]["height_set"],
            //     "orientation_set": agentIdArr[i]["orientation_set"],
            //     "x_value": agentIdArr[i]["x_value"],
            //     "y_value": agentIdArr[i]["y_value"],
            //     "device_id ": agentIdArr[i]["deviceId"],
            //     "marker_id ": agentIdArr[i]["markerId"],
            //     "number_testers": 1,
            //     "people_count": [
            //         0,
            //         0,
            //         0,
            //         0
            //     ],
            //     "test_status": [
            //         0,
            //         0,
            //         0,
            //         0
            //     ],
            //     "instalation_status": 0,
            //     " link_screenshot ": [
            //         "test"
            //     ]



            // }
            const key = localStorage.getItem("spaceio-key")
            let nackFlag = false
            for (let i in myresult) {
                if (myresult[i].state === 'Online') {
                    //console.log("device online", agentIdArr[i].id)
                    let ackStatus = await AuthService.sendCalibrationCommand(agentIdArr[i].agentId)

                    if (ackStatus["calibartionStatus"] === 'ACK') {
                        nackFlag = false
                        //console.log(ackStatus["calibartionStatus"], agentIdArr[i]["assetId"])
                        let utcTimestamp = new Date().getTime();
                        dataToWrite = [{
                            "assetId": agentIdArr[i]["assetId"],
                            "data": {
                                "utcEpoch": utcTimestamp,
                                "height_set": agentIdArr[i]["height_set"],
                                "orientation_set": agentIdArr[i]["orientation_set"],
                                "x_value": agentIdArr[i]["x_value"],
                                "y_value": agentIdArr[i]["y_value"],
                                "device_id ": agentIdArr[i]["device_id "],
                                "marker_id ": agentIdArr[i]["marker_id "],
                                "number_testers": 1,
                                "people_count": [
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "test_status": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "instalation_status": 0,
                                " link_screenshot ": [
                                    "test"
                                ],
                                "setup_status": 1,
                                "cal_status": 3,
                                "calibration_status": 3

                            }
                        }]
                        AuthService.writeDataToSpot(dataToWrite)
                        setinfoBoxText({ text: "Click on respective test button" })


                    }
                    else if (ackStatus["calibartionStatus"] === 'NACK' || ackStatus["calibartionStatus"] === 'PIR_ACTIVE') {
                        nackFlag = true
                        //console.log(ackStatus["calibartionStatus"], agentIdArr[i]["assetId"])
                        let utcTimestamp = new Date().getTime();
                        dataToWrite = [{
                            "assetId": agentIdArr[i]["assetId"],
                            "data": {
                                "utcEpoch": utcTimestamp,
                                "height_set": agentIdArr[i]["height_set"],
                                "orientation_set": agentIdArr[i]["orientation_set"],
                                "x_value": agentIdArr[i]["x_value"],
                                "y_value": agentIdArr[i]["y_value"],
                                "device_id ": agentIdArr[i]["device_id "],
                                "marker_id ": agentIdArr[i]["marker_id "],
                                "number_testers": 1,
                                "people_count": [
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "test_status": [
                                    0,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                "instalation_status": 0,
                                " link_screenshot ": [
                                    "test"
                                ],
                                "setup_status": 1,
                                "cal_status": 2,
                                "calibration_status": 2


                            }
                        }]
                        AuthService.writeDataToSpot(dataToWrite)
                        setinfoBoxText({ text: "Click on respective test button" })
                        setCalibrate(true)

                        //check this
                        setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: false, modalValue: false })


                    }
                    else {
                        //console.log("calibration status neither ACK nor NACK")
                    }

                }
                else {
                    //console.log("device offline", agentIdArr[i]["assetId"])
                    nackFlag = true
                    let utcTimestamp = new Date().getTime();
                    dataToWrite = [{
                        "assetId": agentIdArr[i]["assetId"],
                        "data": {
                            "utcEpoch": utcTimestamp,
                            "height_set": agentIdArr[i]["height_set"],
                            "orientation_set": agentIdArr[i]["orientation_set"],
                            "x_value": agentIdArr[i]["x_value"],
                            "y_value": agentIdArr[i]["y_value"],
                            "device_id ": agentIdArr[i]["device_id "],
                            "marker_id ": agentIdArr[i]["marker_id "],
                            "number_testers": 1,
                            "people_count": [
                                0,
                                0,
                                0,
                                0
                            ],
                            "test_status": [
                                0,
                                0,
                                0,
                                0,
                                0
                            ],
                            "instalation_status": 0,
                            " link_screenshot ": [
                                "test"
                            ],
                            "setup_status": 1,
                            "cal_status": 2,
                            "calibration_status": 1


                        }
                    }]
                    AuthService.writeDataToSpot(dataToWrite)
                    setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: false, modalValue: false })

                }
                for (let i = 0; i < timDev.length; i++) {
                    // console.log("------------------", dataToWrite[0])
                    if (timDev[i]['assetId'] === dataToWrite[0]["assetId"]) {
                        //console.log(dataToWrite[0]["assetId"], " | ", timDev[i], " | ", dataToWrite)
                        timDev[i]['spotData'] = dataToWrite[0]["data"]


                    }
                }
                setTimDevices(timDev)

                //check this
                // setinfoBoxText({ text: "test" })
            }

            //console.log("nack flag--------", nackFlag)
            if (nackFlag === true) setinfoBoxText({ text: "Device not responding. Please recalibrate." })
            setProgressBar(false);
            setloading(false)
        }
        fetchAgentStatus()
    }



    const handleRectangleCardClickForSetUp = (timData) => {
        let data = {}
        data["batteryVoltage"] = timData.batteryVoltage;
        data["markerId"] = timData.serial;
        data["lastUpdated"] = timData.lastUpdated;
        data["deviceId"] = addDashes(timData.device.id, [4, 3, 3]);
        data["spaceName"] = timData.name;
        data["spaceId"] = timData.id;
        data["locationId"] = timData.locationId;
        data["status"] = timData.status;
        data["assetId"] = timData.assetId;
        data['blessId'] = addDashes(timData.device.blessId, [4, 3, 3]);
        data['productId'] = timData.device.hardware.productId;
        data['x_value'] = timData.spotData.x_value;
        data['y_value'] = timData.spotData.y_value;

        let setUpStatus = (timData.spotData || {}).setup_status
        let calSetUp = (timData.spotData || {}).cal_status
        let calibrationStatus = (timData.spotData || {}).calibration_status
        let instalationStatus = (timData.spotData || {}).instalation_status
        let cal = {
            height: (timData.spotData || {})["height_set"],
            orientation: (timData.spotData || {})["orientation_set"]
        }

        let instalationData = { peopleCount: (timData.spotData || {})["people_count"] }
        if (instalationStatus > 0) {
            if (instalationStatus === 1) {
                data["calibration"] = {
                    span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#29CF81" }}>
                        Test Passed
                    </span>,
                    ...cal,
                }
                data["instalationData"] = { ...instalationData }
            }
            else if (instalationStatus === 2) {
                data["calibration"] = {
                    span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#29CF81" }}>
                        Partial Passed
                    </span>,
                    ...cal,
                }
                data["instalationData"] = { ...instalationData }
            }
            else if (instalationStatus === 3) {
                data["calibration"] = {
                    span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#F65162" }}>
                        Test Failed
                    </span>,
                    ...cal,
                }
                data["instalationData"] = { ...instalationData }
            }
            else {
                data["calibration"] = {
                    span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#F65162" }}>
                        Test Error
                    </span>,
                    ...cal,
                }
                data["instalationData"] = { ...instalationData }
            }
        }
        else {

            if (setUpStatus !== 0) {
                if (setUpStatus === 1 && calSetUp === 2 && calibrationStatus === 0) {
                    data["calibration"] = {
                        span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#29CF81" }}>
                            Set-up done
                        </span>,
                        ...cal
                    }

                }

                else if (setUpStatus === 1 && calSetUp === 2 && calibrationStatus === 1) {
                    data["calibration"] = {
                        span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#BBBBBB" }}>
                            Tim Offline
                        </span>,
                        ...cal
                    }
                }
                else if (setUpStatus === 1 && calSetUp === 2 && calibrationStatus === 2) {

                    data["calibration"] = {
                        span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#F65162" }}>
                            Not Detected
                        </span>,
                        ...cal
                    }


                }
                else if (setUpStatus === 1 && calSetUp === 3 && calibrationStatus === 3) {
                    data["calibration"] = {
                        span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#29CF81" }}>
                            Calibrated
                        </span>,
                        ...cal
                    }

                }
                else {
                    data["calibration"] = {
                        span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#F65162" }}>
                            Error
                        </span>,
                        ...cal
                    }
                }
            }
        }
        console.log("alltim =>", data)

        setDataForDownBar(data)
        setDownBar(!downBar)
    }

    const handleRectangleCardButtonClickFortest = (timData) => {
        setmodalDetails({
            primaryText: "Number of Testers",
            secondaryText: `Please share with how many people you will be running this test.(max ${maxValid})`,
            icon: infoIcon,
            inputBox: true,
            confirmButtonText: "Done"
        })
        setTestButtonClicked(true)
        let data = {};
        data["deviceId"] = timData.device.id;
        data["spaceName"] = timData.name;
        data["spaceId"] = timData.id;
        data["locationId"] = timData.locationId;
        data["status"] = timData.status;
        data["assetId"] = timData.assetId
        data["test"] = modalInputBox
        data["spotData"] = timData.spotData
        data["macId"] = timData.macId
        data["agentId"] = timData.device.hardware.agentId
        data["spaceName"] = timData.name;
        data['productId'] = timData.device.hardware.productId;
        data['x_value'] = timData.spotData.x_value;
        data['y_value'] = timData.spotData.y_value;

        setDataForTest(data)
    }

    const handleConfirmButtonClickedForTest = async () => {
        if (modalInputBox >= minValid && modalInputBox <= maxValid) {
            setloading(true)
            setDataForTest({ ...dataForTest, test: modalInputBox })
            //console.log("agentID", dataForTest.agentId)
            await AuthService.increaseFrameRate(dataForTest.agentId)
            navigate("/live-grid",
                { state: { ...location.state, dataForTest: { ...dataForTest, test: modalInputBox } } })
        }
    }

    const onCardClick = (timData, setupClicked) => {
        // console.log("hello", timData, setupClicked)
        let data = {}
        if (setupClicked) {
            data["batteryVoltage"] = timData.batteryVoltage;
            data["markerId"] = timData.serial;
            data["lastUpdated"] = timData.lastUpdated;
            data["deviceId"] = addDashes(timData.device.id, [4, 3, 3]);
            data['blessId'] = addDashes(timData.device.blessId, [4,3,3])
            data["spaceName"] = timData.name;
            data["spaceId"] = timData.id;
            data["locationId"] = timData.locationId;
            data["status"] = timData.status;
            data["assetId"] = timData.assetId;
            data['productId'] = timData.device.hardware.productId;
            data['x_value'] = timData.spotData.x_value;
            data['y_value'] = timData.spotData.y_value;
            

            setDataForDownBar(data)
            // console.log("Card clicked", dataForDownBar.assetId);
        }

        navigate("/grid",
            { state: { ...location.state, "dataForDownBar": setupClicked ? data : dataForDownBar, } })
    }

    const addDashes = (deviceId, dashPosition) => {
        deviceId = deviceId.toString()
        return deviceId.slice(0, dashPosition[0]) + "-" + deviceId.slice(dashPosition[0], dashPosition[0] + dashPosition[1]) + "-" + deviceId.slice(dashPosition[0] + dashPosition[1], dashPosition[0] + dashPosition[1] + dashPosition[2])
    }

    const goBack = () =>
        navigate(
            {
                pathname: "/selectfloor",
                search: `?floor=${location.state.floorDetails[0].id}&name=${floorName}`,
                state: { ...location.state }
            }
        )


    return (
        <div className="container-fluid g-0">
            <div class="row justify-content-center g-0 mainDiv">
                <div className="col-sm-12 col-lg-8 position-relative" style={{ background: "#F5F5F5" }}>
                    <div className="row g-0" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                        {loading && <Loader />}

                        <div className="col-11 mx-auto">
                            <HeaderSecondary tabInd={downBar || testButtonClicked}
                                goBack={goBack}
                                primaryText={location.state.parent[0].name}
                                secondaryText={[location.state.floorDetails[0].name, "   ", location.state.floorDetails[0].anchorAddress]}
                                buttonSecondary={<button tabIndex={downBar || testButtonClicked ? -1 : 0} className="header-secondary-content2" onClick={() => navigate("/floor-planview", { state: { ...location.state, data: timdevices } })}>View on floor plan</button>}
                            />
                        </div>
                        <div className="col-11 mx-auto my-4">
                            {/* <InfoBox text={calibrate ? "Set-up for all the TiMs in this room is done now. To calibrate ensure that the room is vacant." : "Set-up all the TIM devices in each room to start calibration."} /> */}
                            <InfoBox text={infoBoxText.text} />
                        </div>

                        {calibrate && !calibrateNowButtonClicked.modalValue ? <div className="col-11 mx-auto">
                            <Button1
                                text={"Calibrate Now"}
                                borderRadius={"10px"}
                                background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"}
                                color={"#ffffff"}
                                onClick={handleCalibrateButtonClicked}
                            />
                        </div> : null}

                        {calibrateNowButtonClicked.showModal || testButtonClicked ?
                            <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                                <Mod1 text={{ primaryText: modalDetails.primaryText, secondaryText: modalDetails.secondaryText, icon: modalDetails.icon }}
                                    closeModal={testButtonClicked ? () => setTestButtonClicked(false) : handleCancelButtonClicked}
                                    confirmButton={{ text: modalDetails.confirmButtonText, onClick: testButtonClicked ? handleConfirmButtonClickedForTest : handleConfirmButtonClickedForVacant }}
                                    inputBox={modalDetails.inputBox}
                                    value={modalDetails.inputBox ? modalInputBox : null}
                                    setValue={modalDetails.inputBox ? setModalDetailsAfterCheck : null}
                                    tabInd={downBar}
                                />
                            </div>
                            : null}

                        {/* {progressBar ? <div className="col-11 mx-auto">
                    <Progress_bar bgcolor="orange" progress='100' height={30} />
                </div>
                    : null} */}






                        <div className="col-11 mx-auto">
                            {
                                (timdevices).map((obj, index) => {
                                    let instalationStatus = (obj.spotData || {}).instalation_status
                                    let calstatus = (obj.spotData || {}).cal_status
                                    let calibrationStatus = (obj.spotData || {}).calibration_status
                                    let setUpStatus = (obj.spotData || {}).setup_status
                                    let testORSetup;
                                    let retest = (obj.spotData || {}).test_status
                                    retest = retest ? retest[retest.length - 1] : {}
                                    // console.log("retest", retest,
                                    //     {/* retest>0 && retest<=retestAttemt? "true":"false" */ }
                                    // )
                                    if (retest > 0 && retest <= retestAttemt) retest = true
                                    else retest = false

                                    if (calstatus === 3 && calibrationStatus === 3) testORSetup = "test"
                                    else testORSetup = "setUp"
                                    return (

                                        <RectangleCard
                                            tabInd={downBar || testButtonClicked}
                                            key={obj.device.id}
                                            markerId={obj.serial}
                                            productId = {obj.device.hardware.productId}
                                            deviceName={obj.name}
                                            deviceId={addDashes(obj.device.blessId, [4, 3, 3])}
                                            onClick={() => handleRectangleCardClickForSetUp(obj)}
                                            setUpDevice={testORSetup === "setUp" ? (e) => { e.stopPropagation(); onCardClick(obj, true) } : (e) => { e.stopPropagation(); handleRectangleCardButtonClickFortest(obj) }}
                                            setUpStatus={setUpStatus}
                                            calSetUp={calstatus}
                                            calibrationStatus={calibrationStatus}
                                            instalationStatus={instalationStatus}
                                            retest={retest ? (e) => { e.stopPropagation(); handleRectangleCardButtonClickFortest(obj) } : null}
                                        />
                                    )


                                })
                            }
                        </div>
                        {<div className="col-12 mx-auto">
                            <DownBar deviceData={dataForDownBar}
                                className={downBar ? "downbar-card" : "downbar-card hide"}
                                closeDownBar={() => { setDownBar(!downBar) }}
                                onClick={dataForDownBar.calibration ? () => setDownBar(!downBar) : onCardClick}
                                buttonText={dataForDownBar.calibration ? "Done" : "Set-Up TIM"}
                            />
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>);
}

export default AllTim;
