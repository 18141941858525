
function InputFieldNumFLO({
	width = "100%",
	placeHolder,
	reset,
	label,
	value = "",
	setValue,
	type,
	min,
	max,
	step = 1,
	disabled = false }) {


	// console.log(min, max, step);

	function onChange(value) {
		let tempVal = value;
		setValue(tempVal);
	}


	return (
		<div style={{
			background: reset ? '#F5F5F5' : "#FFFFFF",
			border: '1px solid #E5E5E5',
			borderRadius: '10px',
			width: '100%',
			padding: '10px',
			display: 'flex',
			alignItems: 'center'
		}}>
			<label className="set-up-grid-ht-orientation" style={{ display: 'inline-block', width: '170%' }}>
				{label}
			</label>
			<input
				style={{ background: 'transparent', color: '#444444', borderRadius: "10px", marginLeft: "10px", border: "none" }}
				placeholder={placeHolder}
				autoComplete="off"
				required
				value={value == null ? '' : value}
				onChange={onChange}
				type={type ? type : null}
				min={min}
				max={max}
				step={step}
				disabled={disabled}
			/>
		</div>
	);
}

export default InputFieldNumFLO;