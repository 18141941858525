// import React from 'react';
// import { TopViewGrid } from '../live-grid-FLO/TopViewGridFLO';

// const colors = [
//     '#FFFFFF',
//     '#ff0000',
//     '#ff1c00',
//     '#ff2b00',
//     '#fe3700',
//     '#fe4100',
//     '#fd4a00',
//     '#fc5200',
//     '#fa5a00',
//     '#f96100',
//     '#f76800',
//     '#f56f00',
//     '#f37500',
//     '#f07c00',
//     '#ee8200',
//     '#eb8800',
//     '#e78e00',
//     '#e49300',
//     '#e19900',
//     '#dd9e00',
//     '#d9a400',
//     '#d4a900',
//     '#d0ae00',
//     '#cbb300',
//     '#c6b800',
//     '#c1bd00',
//     '#bbc200',
//     '#b6c700',
//     '#b0cc00',
//     '#a9d000',
//     '#a2d500',
//     '#9bd900',
//     '#93de00',
//     '#8ae200',
//     '#81e600',
//     '#77eb00',
//     '#6bef00',
//     '#5ef300',
//     '#4df700',
//     '#37fb00',
//     '#00ff00',


//     '#00fd28',
//     '#00fa3c',
//     '#00f84d',
//     '#00f55d',
//     '#00f26b',
//     '#00ef79',
//     '#00eb87',
//     '#00e895',
//     '#00e4a3',
//     '#00e1b0',
//     '#00ddbe',
//     '#00d9cb',
//     '#00d5d8',
//     '#00d1e5',
//     '#00cdf2',
//     '#00c9fe',
//     '#00c5ff',
//     '#00c1ff',
//     '#00bcff',
//     '#00b8ff',
//     '#00b3ff',
//     '#00afff',
//     '#00aaff',
//     '#00a5ff',
//     '#00a0ff',
//     '#009bff',
//     '#0095ff',
//     '#008fff',
//     '#0089ff',
//     '#0082ff',
//     '#007bff',
//     '#0073ff',
//     '#006aff',
//     '#0061ff',
//     '#0056ff',
//     '#004aff',
//     '#003cff',
//     '#0029ff',
//     '#0000ff',
// ];


// const imageUrl = 'https://cdn.afreespace.com/u/sensors/flo.png';

// const BACKGROUND_COLOR = 'black';

// export function TempGrid({
//     frameData = [],
//     rows = 24,
//     columns = 32,
//     orientation = 0,
//     // height = 800,
//     width = 500,
//     fontSize = 7,
//     keyColor = 'lightgray',
//     borderColor = 'darkgray',
//     keyFontSize = '70%',
//     hideKey = false,
//     hideBorder = false,
//     verticalLines = [],
//     horizontalLines = [],
//     zones = [],
// }) {
// console.log("Zones prop in TempGrid:", zones);

//     // console.log("orientation :", orientation)
//     // return '';
//     const getRows = () => [0, 180].includes(orientation) ? rows : columns;
//     const getColumns = () => [0, 180].includes(orientation) ? columns : rows;

//     // console.log("getRows", getRows);

//     const getKey = (r, c) => {
//         switch (orientation) {
//             case 0:
//                 return r * getColumns() + (getColumns() - c - 1);
//             case 90:
//                 return r + c * getRows();
//             case 180:
//                 return (rows * columns - 1) - (r * getColumns() + (getColumns() - c - 1));
//             case 270:
//                 return (rows * columns - 1) - (r + c * getRows());
//             default:

//         }
//     };


//     const max = Math.max(...frameData) + 1;
//     const min = Math.min(...frameData) - 1;
//     // const min = 15;
//     const step = (max - min) / colors.length;

//     const cells = [];
//     for (let r = 0; r < getRows(); r++) {
//         const row = [];
//         for (let c = 0; c < getColumns(); c++) {
//             const key = getKey(r, c);
//             const value = frameData[key].toFixed(1);



//             const backgroundIndex = colors.length - Math.ceil((frameData[key] - min) / step);
//             //console.log(max, min, step, backgroundIndex);

//             const zoneColors = {
//                 '11': '#f7a1a7', // red
//                 '12': '#aef5c9', // Green 
//                 '0': 'black'
//             };
//             let background;
//             if (value > 7) {
//                 background = colors[backgroundIndex];
//             } else if (zones.length > 0) {
//                 background = zoneColors[zones[key]];
//             }
//             if (!background) {
//                 background = 'black';
//             }

//             const cell = {
//                 key,
//                 value,
//                 colors: {
//                     background,
//                     text: 'white'
//                 }
//             };
//             row.push(cell);
//         }
//         cells.push(row);
//     }

//     return <div style={{ margin: 10, position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//         <TopViewGrid
//             fontSize={fontSize}
//             keyFontSize={keyFontSize}
//             height={width * getRows() / getColumns()}
//             hideKey={hideKey}
//             width={width}
//             cells={cells}
//             borderWidth={0}
//             keyColor={keyColor}
//             verticalLines={verticalLines.map(v => getColumns() - v - 2)}
//             horizontalLines={horizontalLines}
//             borderColor={borderColor}
//             backgroundColor={hideBorder ? BACKGROUND_COLOR : borderColor}
//         />
//         <div style={{
//             height: 100,
//             width: 100,
//             backgroundPosition: 'center',
//             backgroundSize: 'contain',
//             position: 'absolute',
//             backgroundImage: `url(${imageUrl})`,
//             opacity: 0.6,
//             transform: `rotate(${360 - orientation + 90}deg)`
//         }} />
//     </div>;
// }


import React from 'react';
import { TopViewGrid } from '../live-grid-FLO/TopViewGridFLO';

const colors = [
    '#FFFFFF',
    '#ff0000',
    '#ff1c00',
    '#ff2b00',
    '#fe3700',
    '#fe4100',
    '#fd4a00',
    '#fc5200',
    '#fa5a00',
    '#f96100',
    '#f76800',
    '#f56f00',
    '#f37500',
    '#f07c00',
    '#ee8200',
    '#eb8800',
    '#e78e00',
    '#e49300',
    '#e19900',
    '#dd9e00',
    '#d9a400',
    '#d4a900',
    '#d0ae00',
    '#cbb300',
    '#c6b800',
    '#c1bd00',
    '#bbc200',
    '#b6c700',
    '#b0cc00',
    '#a9d000',
    '#a2d500',
    '#9bd900',
    '#93de00',
    '#8ae200',
    '#81e600',
    '#77eb00',
    '#6bef00',
    '#5ef300',
    '#4df700',
    '#37fb00',
    '#00ff00',


    '#00fd28',
    '#00fa3c',
    '#00f84d',
    '#00f55d',
    '#00f26b',
    '#00ef79',
    '#00eb87',
    '#00e895',
    '#00e4a3',
    '#00e1b0',
    '#00ddbe',
    '#00d9cb',
    '#00d5d8',
    '#00d1e5',
    '#00cdf2',
    '#00c9fe',
    '#00c5ff',
    '#00c1ff',
    '#00bcff',
    '#00b8ff',
    '#00b3ff',
    '#00afff',
    '#00aaff',
    '#00a5ff',
    '#00a0ff',
    '#009bff',
    '#0095ff',
    '#008fff',
    '#0089ff',
    '#0082ff',
    '#007bff',
    '#0073ff',
    '#006aff',
    '#0061ff',
    '#0056ff',
    '#004aff',
    '#003cff',
    '#0029ff',
    '#0000ff',
];


const imageUrl = 'https://cdn.afreespace.com/u/sensors/flo.png';

const BACKGROUND_COLOR = 'black';

export function TempGrid({
    frameData = [],
    rows = 24,
    columns = 32,
    orientation = 0,
    // height = 800,
    width = 500,
    fontSize = 7,
    keyColor = 'lightgray',
    borderColor = 'darkgray',
    keyFontSize = '70%',
    hideKey = false,
    hideBorder = false,
    verticalLines = [],
    horizontalLines = [],
    zones = [],
}) {
    // return '';
    const getRows = () => [0, 180].includes(orientation) ? rows : columns;
    const getColumns = () => [0, 180].includes(orientation) ? columns : rows;

    const getKey = (r, c) => {
        switch (orientation) {
            case 0:
                return r * getColumns() + (getColumns() - c - 1);
            case 90:
                return r + c * getRows();
            case 180:
                return (rows * columns - 1) - (r * getColumns() + (getColumns() - c - 1));
            case 270:
                return (rows * columns - 1) - (r + c * getRows());
            default:

        }
    };


    const max = Math.max(...frameData) + 1;
    const min = Math.min(...frameData) - 1;
    // const min = 15;
    const step = (max - min) / colors.length;

    const cells = [];
    for (let r = 0; r < getRows(); r++) {
        const row = [];
        for (let c = 0; c < getColumns(); c++) {
            const key = getKey(r, c);
            const value = frameData[key].toFixed(1);



            const backgroundIndex = colors.length - Math.ceil((frameData[key] - min) / step);
            //console.log(max, min, step, backgroundIndex);

            const zoneColors = {
                '11': '#f7a1a7', // red
                '12': '#aef5c9', // Green 
                '0': 'black'
            };
            let background;
            if (value > 7) {
                background = colors[backgroundIndex];
            } else if (zones.length > 0) {
                background = zoneColors[zones[key]];
            }
            if (!background) {
                background = 'black';
            }

            const cell = {
                key,
                value,
                colors: {
                    background,
                    text: 'white'
                }
            };
            row.push(cell);
        }
        cells.push(row);
    }

    return <div style={{ margin: 10, position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TopViewGrid
            fontSize={fontSize}
            keyFontSize={keyFontSize}
            height={width * getRows() / getColumns()}
            hideKey={hideKey}
            width={width}
            cells={cells}
            borderWidth={0}
            keyColor={keyColor}
            verticalLines={verticalLines.map(v => getColumns() - v - 2)}
            horizontalLines={horizontalLines}
            borderColor={borderColor}
            backgroundColor={hideBorder ? BACKGROUND_COLOR : borderColor}
        />
        <div style={{
            height: 100,
            width: 100,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            position: 'absolute',
            backgroundImage: `url(${imageUrl})`,
            opacity: 0.6,
            transform: `rotate(${360 - orientation + 90}deg)`
        }} />
    </div>;
}