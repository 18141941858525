

function InputField1({ width = "100%", placeHolder, reset, label, value = "", setValue, type, min, max = 9999, step = 1, disabled = false }) {
	function onChange(event) {
		let tempVal = event.target.value;
		if (tempVal > max) {
			tempVal = max;
		}
		setValue(tempVal);
	}
	return (
		<div style={{ background: reset ? '#F5F5F5' : "#FFFFFF", border: '1px solid #E5E5E5', borderRadius: '50px', width: width, padding: '10px' }}>
			<label className="set-up-grid-ht-orientation">{label}</label>
			<input placeholder={placeHolder} autoComplete="off" style={{ background: 'transparent', color: '#444444', borderRadius: "10px", marginLeft: "10px", border: "none" }} required value={value == null ? '' : value} onChange={onChange} type={type ? type : null} min={min} max={max} step={step} disabled={disabled} />
		</div>
	);
}

export default InputField1;