import "./slectProduct.css";
import TIM from "../../assets/images/TimLogo.svg";
import FLO from "../../assets/images/FLO.svg";
import PAM from "../../assets/images/pam.svg";
import TIMP from "../../assets/images/timPLogo.svg";
import FSO from "../../assets/images/FSO.svg";
import BAT_TIM from "../../assets/images/BTIM.svg";
import freespaceLogo from "../../assets/images/Dot.svg";
import logoutIcon from "../../assets/images/logoutIcon.svg";
import AuthService from "../../Auth/auth";

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import InfoBox from "../../components/infoBox/InfoBox";
import ProductCard from "../../components/productCard/ProductCard";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader/Loader";
import Mod1 from "../../components/mod1/Mod1";
import alertIcon from "../../assets/images/alert.svg";

const svgMapping = {
  TIM: TIM,
  "TIM+": TIMP,
  "PAM-C": PAM,
  "BAT-TIM": BAT_TIM,
  FLO: FLO,
  ONE: FSO,
  ROS: FSO,
  QSense: FSO,
  // 'POE TIM': TIM,
  // 'POE TIM+': TIMP,
  // 'POE FLO': FLO
};

function SelectProduct(props) {
  const navigate = useNavigate();
  const [loading, setloading] = useState(true);
  const [productsNameIwithId, setProductsNameIwithId] = useState([]);
  const [authenticate, setAuthenticate] = useState(false);
  const [info, setInfo] = useState();
  const [productIds, setProductIds] = useState([]);
  const [POEProductIds, setPOEProductIds] = useState([]);

  useEffect(() => {
    async function getProduct() {
      try {
        setloading(true);
        setInfo("Select the product for calibration or testing.");

        const key = window.localStorage.getItem("spaceio-key");
        const productsNameIwithId = await AuthService.getProductIdAndNameUsingProductId(key) 
        // const productsNameIwithId = await AuthService.getProductIdAndNameUsingProdcutId(key);

        const prodcutIdArray = [];
        let productName;

        productsNameIwithId.forEach((element) => {
          if (element.name === "TIM" || element.name === "TIM+" || element.name === "FLO" || element.name === "POE FLO" || element.name === "POE TIM+" || element.name === "POE TIM" || element.name === "BAT-TIM") {
            let arayOfProdcutId = [element.id];

            if (element.name.startsWith("POE")) {
              productName = element.name.split(" ")[1];
              element.name = productName;
              const matchingObj = prodcutIdArray.find(
                (obj) => obj.name === productName
              );
              
              if (matchingObj) {
                arayOfProdcutId.push(...matchingObj.id);
                matchingObj.id = arayOfProdcutId;
                return;
              }
            }
            element.id = arayOfProdcutId;
            element.svg = svgMapping[element.name];
            prodcutIdArray.push(element);
          }
        });
        setProductsNameIwithId(prodcutIdArray);

        // Set other states as needed
        setAuthenticate(true);
        setloading(false);
      } catch (error) {
        console.error("Error fetching product information:", error);
        // Handle error if needed
        setloading(false);
      }
    }

    getProduct();
  }, []);

  const onProductCardClick = (product) => {
    props.setIamge(product.svg);
    props.setProductId(product.id);

    localStorage.setItem("productSvg", product.svg);
    localStorage.setItem("productId", product.id);

    if (product.name === "TIM+") {
      navigate({ pathname: "/selectTimPfloor" });
    } else if (product.name === "FLO") {
      navigate({ pathname: "/selectFLOfloor" });
    } else if (product.name === "BAT-TIM") {
      navigate({ pathname: "/selectBatTimfloor" });
    } else {
      navigate({ pathname: "/selectfloor" });
    }
  };

  return (
    <div className="container-fluid g-0">
      <div className="row justify-content-center g-0 mainDiv">
        <div
          className="col-lg-8"
          style={{
            background: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="row g-0">
            {loading && <Loader />}

            <div className="col-11 mx-auto my-4 icon-logout">
              <img src={freespaceLogo} alt="freespaceLogo" height="50px"></img>
              <button
                className="logout-background"
                onClick={() => {
                  AuthService.logout();
                  navigate("/");
                }}
              >
                <img src={logoutIcon} alt="logout"></img>
              </button>
            </div>

            {productsNameIwithId && (
              <div className="col-11 my-4 mx-auto">
                <InfoBox text={info} />
                <label className="label-class mt-4">Select a product</label>
                <div className="row g-0 select-product-squareCardDiv">
                  {productsNameIwithId.map((product, index) => (
                    <ProductCard
                      key={index}
                      image={product.svg}
                      label={product.name}
                      onClick={() => {
                        onProductCardClick(product);
                      }}
                    ></ProductCard>
                  ))}
                </div>
              </div>
            )}
            { !loading && !authenticate ? (
              <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                <Mod1
                
                  text={{
                    primaryText:
                      "It seems you do not have permission to access this tool",
                    secondaryText: "Click below to retry",
                    icon: alertIcon,
                  }}
                  confirmButton={{
                    text: "OK",
                    onClick: () => {
                      navigate("/");
                    },
                  }}
                />
              </div>
            ) : null}
          </div>
          <Footer style={{ marginTop: "auto" }} />
        </div>
      </div>
    </div>
  );
}

export default SelectProduct;
