import lightBulb from "../../assets/images/lightBulb.svg"
import "./infoBox.css"

function InfoBox({ text, image = lightBulb, background = "rgba(56, 214, 139, 0.1)", confirm, cancel }) {
  return (
    <div role="complementary" className={"info-body"} style={{ background: background }}>
      <div className="info-body-content">
        <div className={"info-icon"}>
          <img src={image} alt={"infoIcon"} style={{ verticalAlign: "initial" }} />
        </div>
        <div className={"info-text"}>
          {text}
          {confirm ?
            <div className="info-button-parent">
              <button className="buttonStandard2" onClick={confirm}>Yes! Can See</button>
              <button className="buttonStandard2" style={{ marginLeft: "0.5rem" }} onClick={cancel}>Not Showing</button>
            </div> : null}
        </div>
      </div>
    </div>
  );
}

export default InfoBox;
