import ButtonViewTest from "./ButtonViewTest"
import ileftright from "../assets/images/leftright.svg"
import itopbottom from "../assets/images/topbottom.svg"

import left from "../assets/images/left.svg"
import right from "../assets/images/right.svg"

function BottomGrid2FLO(props) {

    function reset() {
        props.callback();
    }
    return (
        <div style={{ textAlign: 'center' }}>
            {props.showLeftRight &&
                <div style={{ marginBottom: '2%', display: 'flex', justifyContent: 'center', gap: '15px' }}>
                    <span onClick={() => { /*console.log("Left");*/ props.leftShiftZone(); }}><img alt="" src={left} style={{ cursor: 'pointer' }} /></span>
                    <span onClick={() => { /*console.log("Right");*/ props.rightShiftZone(); }}><img alt="" src={right} style={{ cursor: 'pointer' }} /></span>
                </div>
            }

            <div style={{ background: "transparent", display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '30px' }}>
                <ButtonViewTest text="Reset" borderRadius="7px" width="100px" borderColor="#ff9170" background="white" color="#ff9170" onClick={reset} />
                <ButtonViewTest text="Done" borderRadius="7px" width="100px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white" onClick={() => props.changeEdit()} />
            </div>
        </div>
    );
}

export default BottomGrid2FLO;