import { useEffect, useState } from 'react';
import ButtonViewTest from "./ButtonViewTest"
// import iangle from "../assets/images/angle.svg"
import ileftright from "../assets/images/leftright.svg"
import itopbottom from "../assets/images/topbottom.svg"
import Slider from "../components/slider/Slider";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useEffect, useMemo, useState } from "react";



function BottomGrid3(props) {

	let [topBottom, setTopBottom] = useState("");
	let [leftRight, setLeftRight] = useState("");

	let [downmove, setDownmove] = useState(props.slider_val[0])
	let [leftmove, setLeftmove] = useState(props.slider_val[1])
	let [isZoneSelected, setisZoneSelected] = useState(false);
	let OrientationAngle = props.OrientationAngle;

	function whichOne() {
		return (topBottom || leftRight)
	}

	function leftRightMarks() {
		let temp = [];
		for (let i = -16; i <= 16; i++) {
			temp.push({
				value: i,
				label: i + ''
			})
		}
		return temp;
	}

	function reset() {
		props.shiftChildHoriVert(0, 0, true);
		setDownmove(0);
		setLeftmove(0);
		// setRange(_range);
		// setLeft(parseFloat(_left));
		// setTop(parseFloat(_top));
		props.callback();
	}

	useEffect(() => {
		console.log("this zone zone zone ", props.defaultSelectedChildPixels);
		setDownmove(0);
		setLeftmove(0);
	}, [props.defaultSelectedChildPixels])

	useEffect(() => {
		// console.log("isZoneSelected", isZoneSelected, props.defaultSelectedChildPixels.length)
		if (props.defaultSelectedChildPixels.length !== 0) {
			setisZoneSelected(true);
		}
	}, [isZoneSelected, props.defaultSelectedChildPixels])

	function between(x, min, max) {
		return x >= min && x <= max;
	}

	function checkAngleDoActionForButton(value) {
		if (whichOne() === "leftright") {
			
			if (OrientationAngle === 360)
			{
				setLeftmove(value);
				props.shiftChildHoriVert(value, 1)
			}
			else if (between(OrientationAngle, 0, 90))
			{
				setLeftmove(value);
				props.shiftChildHoriVert(value, 0)
			}
			else if (between(OrientationAngle, 91, 180))
			{
				setLeftmove(value);
				props.shiftChildHoriVert(value, 0)
			}
			else if (between(OrientationAngle, 181, 359))
			{
				setLeftmove(value);
				props.shiftChildHoriVert(value, 0)
			}
			
			// console.log(value);
			// setLeftmove(value);
			// props.shiftChildHoriVert(value, 0)
		} else if (whichOne() === "topbottom") {

			if (between(OrientationAngle, 0, 90))
			{
				setDownmove(value);
				props.shiftChildHoriVert(value, 1)
			}
			else if (between(OrientationAngle, 91, 180))
			{
				setDownmove(value);
				props.shiftChildHoriVert(value, 1)
			}
			
			else if (between(OrientationAngle, 181, 359))
			{
				setDownmove(value);
				props.shiftChildHoriVert(value, 1)
			}
			else if(OrientationAngle===360) {
				setDownmove(value);
				props.shiftChildHoriVert(value, 0)
			}

			// console.log(value);
			// setDownmove(value);
			// props.shiftChildHoriVert(value, 1)
		}
	}

	return (

		<div style={{ textAlign: 'center' }}>
			{isZoneSelected ?
				<div style={{ marginBottom: '2%', display: 'flex', justifyContent: 'center' }}>
					<span onClick={() => { setLeftRight("leftright"); setTopBottom("") }}><img alt="" src={ileftright} className={(topBottom || leftRight) === "leftright" ? 'rolar' : ''} /></span>
					<span> &nbsp; &nbsp;&nbsp;</span>
					<span onClick={() => { setLeftRight(""); setTopBottom("topbottom") }}><img alt="" src={itopbottom} className={(topBottom || leftRight) === "topbottom" ? 'rolar' : ''} /></span>
				</div>
				:
				null
			}
			<br />
			<div>
				{
					whichOne() === "leftright" &&
					<Slider scalingFactor={props.data.scalingFactor} marks={leftRightMarks()} min={-16} max={16} value={leftmove} step={1} onChange={(event, value) => { checkAngleDoActionForButton(value) }} style={{ width: props.data.style.width, accentColor: 'green' }} />
				}
				{
					whichOne() === "topbottom" &&
					<Slider scalingFactor={props.data.scalingFactor} marks={leftRightMarks()} min={-16} max={16} value={downmove} step={1} onChange={(event, value) => { checkAngleDoActionForButton(value) }} style={{ width: props.data.style.width, accentColor: 'red' }} />
				}
				<div style={{ marginBottom: '10px' }}>
				</div>
			</div>
			<div style={{ background: "transparent", display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '30px' }}>
				<ButtonViewTest text="Reset" borderRadius="7px" width="100px" borderColor="#ff9170" background="white" color="#ff9170" onClick={reset} />
				<ButtonViewTest text="Done" borderRadius="7px" width="100px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white" onClick={() => props.changeEdit()} />
			</div>
		</div>
	);
}

export default BottomGrid3;