import ButtonViewTest from "./ButtonViewTest"

function BottomGridEditFLO(props) {
	return (
		<div style={{ background: "transparent", display: "flex", alignItems: "center", justifyContent: "space-between", gap: '5px' }}>
			<ButtonViewTest text={props.buttonTextEdit} borderRadius="7px" borderColor="#ff9170" background="white" color="#ff9170" onClick={() => props.onClick()} />
			<ButtonViewTest text={props.buttonTextCalibration} borderRadius="7px" borderColor="#ff9170" background="white" color="#ff9170" onClick={() => props.onCalibrate()} />
			<ButtonViewTest text={props.buttonTextDone} borderRadius="7px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white" onClick={() => props.setupDone()} />
		</div>);
}

export default BottomGridEditFLO;