import ButtonViewTest from "./ButtonViewTest"

function BottomGridEditFLO(props) {
	return (
		<div style={{ background: "#f5f5f5", display: "flex", alignItems: "center" }}>
			<ButtonViewTest text={props.buttonTextEdit} borderRadius="0px" borderColor="#ff9170" background="white" color="#ff9170" onClick={() => props.onClick()} />
			<ButtonViewTest text={props.buttonTextDone} borderRadius="0px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white" onClick={() => props.setupDone()} />
		</div>);
}

export default BottomGridEditFLO;