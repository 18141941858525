import "./DownBar.css"
import TIM from "../../assets/images/tim.svg"
import closeIcon from "../../assets/images/closeIcon.svg"
import Button1 from "../Button1"
import { useEffect, useState } from "react";
function DownBar({ className, closeDownBar, deviceData, styleCustom, onClick }) {
    const [overlay, setOverlay] = useState(null);

    useEffect(() => {
        if (className === "downbar-card") setOverlay(true)
        else setOverlay(false)

    }, [className])
    
    function productTypeFromProductID(deviceId) {
        switch (deviceId) {
          case "6875f0bf-157d-332e-883f-70e58401dd14":
            return "TIM";
          case "62d3da73-debf-8660-5fb4-f5d6c646358f":
            return "TIM+";
          case "2d5ef6ed-051c-0bb9-70a4-718d31215e0e":
            return "FLO";
          case "cb1a34bf-e4ea-a74b-d496-37c1183ffa97":
            return "BAT-TIM";
          case "4b357a17-cc2d-5d63-116f-a539a78a2802":
            return "POE TIM+";
          case "6a36e934-426c-025f-a025-89bed058974d":
            return "POE TIM";
          case "85939db1-afba-b79e-b424-2e295ec2a9b5":
            return "POE FLO";
          default:
            return "--"; // Default value when deviceId doesn't match any case
        }
      }

    const style = { display: "flex", flexFlow: "row wrap", justifyContent: "space-around" }
    const zInd = { zIndex: 2 }
    function dateToDisplay(data) {
        let today = new Date(data);
        let time = today.getHours() + ":" + today.getMinutes() + " " + today.toDateString()
        return time
    }

    return (<>

        <div onClick={closeDownBar} className={overlay ? "downbar-card-overlay1" : "downbar-card-overlay1 hide"} />
        <div className={className} style={{ ...styleCustom, ...zInd }} >
            <div className="downbar-card-secondary-content1" >
                <div className="downbar-card-secondary-content1-icon">
                    <img src={TIM} alt="Tim icon for representational purpose"></img>
                    <button onClick={closeDownBar} className="downbar-card-secondary-content1-close-icon" aria-label="close icon" tabIndex={overlay ? 0 : -1}>
                        <img src={closeIcon} onClick={closeDownBar} alt="close icon"></img>
                    </button>
                </div>
                <div className="downbar-card-secondary-content1-item2">
                    <span className="downbar-card-secondary-content1-item2-floor-name">{deviceData?.deviceApi?.displayName}</span>
                    <span className="downbar-card-secondary-content1-item2-time">Updated:&nbsp;{dateToDisplay(deviceData?.deviceApi?.lastUpdated)}</span>
                </div>
            </div>
            <div className="downbar-card-body">
                <div style={style}><span className="downbar-card-body-fixwidth1">Device ID:</span> <span className="downbar-card-body-fixwidth2">{deviceData?.deviceId}</span></div>
                <div style={style}><span className="downbar-card-body-fixwidth1">Marker ID:</span> <span className="downbar-card-body-fixwidth2">{deviceData?.deviceApi?.assignedSpace?.category?.id}</span> <span className="downbar-card-body-fixwidth1">Product Type:</span> <span className="downbar-card-body-fixwidth2">{productTypeFromProductID(deviceData?.deviceApi?.assignedSpace?.device?.hardware?.productId)}</span></div>
                <div style={style}><span className="downbar-card-body-fixwidth1">Battery:</span> <span className="downbar-card-body-fixwidth2">{deviceData?.deviceApi?.batteryVoltage} V</span></div>

            </div>

            <div className="downbar-card-body">
                <div className="downbar-card-body-header">CAlIBRATION SUMMARY</div>
                <div style={style}><span className="downbar-card-body-fixwidth1">Height:</span> <span className="downbar-card-body-fixwidth2">{deviceData?.spotDataFLO?.doorDimensions?.door_height} m</span></div>
                <div style={style}><span className="downbar-card-body-fixwidth1">Width:</span> <span className="downbar-card-body-fixwidth2">{deviceData?.spotDataFLO?.doorDimensions?.door_width} m</span></div>
                <div style={style}><span className="downbar-card-body-fixwidth1">Orientation:</span> <span className="downbar-card-body-fixwidth2">{deviceData?.spotDataFLO?.orientationAngle} {'\u00B0'}</span></div>
                <div style={style}><span className="downbar-card-body-fixwidth1">Height Of Sensor:</span> <span className="downbar-card-body-fixwidth2">{deviceData?.spotDataFLO?.doorDimensions?.sensor_height_fr_floor} m</span></div>

                {
                    deviceData?.spotDataFLO?.floMetaDataConfigStatus !== 0 || deviceData?.deviceStatus?.device_status === "Offline" ?
                        <div style={style}>
                            <span className="downbar-card-body-fixwidth1">Status:</span>
                            <span className="downbar-card-body-fixwidth2" style={{ fontWeight: "700", fontSize: "0.8rem", color: "white" }}>
                                {deviceData?.spotDataFLO?.floMetaDataConfigStatus === 1 && deviceData?.deviceStatus?.device_status !== "Offline" ? (
                                    <span style={{ background: "#29CF81", type: "span", borderRadius: "20px", padding: "0.2rem 0.5rem" }}>
                                        Set-up done
                                    </span>
                                ) : deviceData?.spotDataFLO?.floMetaDataConfigStatus === 2 && deviceData?.deviceStatus?.device_status !== "Offline" ? (
                                    <span style={{ background: "#F65162", type: "span", borderRadius: "20px", padding: "0.2rem 0.5rem" }}>
                                        To be tested
                                    </span>
                                ) : deviceData?.spotDataFLO?.floMetaDataConfigStatus === 3 && deviceData?.deviceStatus?.device_status !== "Offline" ? (
                                    <span style={{ background: "#29CF81", type: "span", borderRadius: "20px", padding: "0.2rem 0.5rem" }}>
                                        Test Passed
                                    </span>
                                ) : deviceData?.spotDataFLO?.floMetaDataConfigStatus === undefined && deviceData?.deviceStatus?.device_status !== "Offline" ? (
                                    <span style={{ background: "#F65162", type: "span", borderRadius: "20px", padding: "0.2rem 0.5rem" }}>
                                        Admin Setup Pending
                                    </span>
                                ) : deviceData?.deviceStatus?.device_status === "Offline" ? (
                                    <span style={{ background: "grey", type: "span", borderRadius: "20px", padding: "0.2rem 0.5rem" }}>
                                        Offline
                                    </span>
                                ) : null
                                }
                            </span>
                        </div> : null
                }
            </div >
            {
                true && deviceData?.spotDataFLO?.floMetaDataConfigStatus === 0 && deviceData?.deviceStatus?.device_status !== "Offline" ?

                    <Button1
                        text={
                            deviceData?.spotDataFLO?.floMetaDataConfigStatus === 0
                                ? "Set-up"
                                : "Admin Set-up Pending"
                        }
                        style={{ marginBottom: "0", textColor: "white" }}
                        background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"} color={"#ffffff"}
                        onClick={onClick}
                        tabIndex={overlay ? 0 : -1}
                    /> : null
            }
        </div >

    </>);
}

export default DownBar;