import './App.css';
import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Authenticate from './components/AuthUI';
import SelectProduct from './pages/selectProduct/SlectProduct'
import SelectFloor from './pages/select-floor/SelectFloor';
import SelectTimPFloor from './pages/selectTimPfloor/selectTimPfloor';
import SelectFLOFloor from './pages/selectFLOfloor/selectFLOfloorEasy';

// import SelectProduct from './pages/selectProduct/SlectProduct'
import SelectBatTimFloor from './pages/selectBatTimfloor/selectBatTimfloorEasy'
import AllTim from './pages/all-tim/AllTim';

import FloorPlanView from './pages/floor-plan-view/FloorPlanView';
import Grid from './pages/grid/grid';
import GridBatTim from './pages/grid/gridBatTim'
import GridTimp from './pages/grid/gridTimp'
import LiveGrid from './pages/live-grid/LiveGrid';
import LiveGridTimp from './pages/live-grid/LiveGridTimp';
import LiveGridBatTim from './pages/live-grid/LiveGridBatTimTest';
import LiveGridTimpZones from './pages/live-grid/LiveGridTimpZones';
import LiveGridTimpTest from './pages/live-grid/LiveGridTimpTest';
import LiveGridFLOZones from './pages/live-grid/LiveGridFLOZones';
import LiveGridFLOTest from './pages/live-grid/LiveGridFLOTest';

import SetUpFLO from './pages/grid/setupFLOPage'

import freespaceLogo from "../src/assets/images/freespace_1.svg"
import { useState } from "react";
import AllBatTim from './pages/all-Bat-tim/AllBatTim';
import AllBatTimDeepak from './pages/all-Bat-tim/AllBatTimDeepak';
import AllBatTimCopy from './pages/all-Bat-tim/AllBatTIMEasy';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const RouteElement = ({ location, from, children }) => {
  // console.log("in route element")
  const { pathname } = location
  // console.log(pathname)
  const LoggedinFlag = window.localStorage.getItem('spaceio-key')
  return pathname === '/login' ? (LoggedinFlag ?
    <Navigate to={from} /> : (children)) : (LoggedinFlag ? children :
      <Navigate to={'/login'} state={{ from: location }} />)
}

const ProtectedRoute = ({ children, path }) => {
  // console.log("in protected route")
  const location = useLocation()
  const { from } = location.state || { from: { pathname: '/' } }
  return <RouteElement location={location} from={from} children={children} caseSensitive />
}

const App = () => {
  const [image, setIamge] = useState();
  const [productId, setProductId] = useState(null);
  return (<Router>
    <Routes>
      <Route index element={<Authenticate />}></Route>
      <Route path='login' element={<ProtectedRoute><Authenticate /></ProtectedRoute>}></Route>
      <Route path='all-tim' element={<ProtectedRoute><AllTim /></ProtectedRoute>}></Route>
      <Route path='floor-planview' element={<ProtectedRoute><FloorPlanView></FloorPlanView></ProtectedRoute>}></Route>
      <Route path='selectfloor' element={<ProtectedRoute><SelectFloor image={image} productId={productId} /></ProtectedRoute>}></Route>
      <Route path='selectTimPfloor' element={<ProtectedRoute><SelectTimPFloor image={image} productId={productId} /></ProtectedRoute>}></Route>
      <Route path='selectFLOfloor' element={<ProtectedRoute><SelectFLOFloor image={image} productId={productId} /></ProtectedRoute>}></Route>
      <Route path='grid' element={<ProtectedRoute><Grid /></ProtectedRoute>}></Route>
      <Route path='gridTimp' element={<ProtectedRoute><GridTimp /></ProtectedRoute>}></Route>
      <Route path='live-grid' element={<ProtectedRoute><LiveGrid /></ProtectedRoute>}></Route>
      <Route path='live-grid-Timp' element={<ProtectedRoute><LiveGridTimp /></ProtectedRoute>}></Route>
      <Route path='live-grid-Timp-zones' element={<ProtectedRoute><LiveGridTimpZones /></ProtectedRoute>}></Route>
      <Route path='live-grid-Timp-test' element={<ProtectedRoute><LiveGridTimpTest /></ProtectedRoute>}></Route>
      <Route path='selectProduct' element={<ProtectedRoute><SelectProduct setIamge={setIamge} setProductId={setProductId} /></ProtectedRoute>}></Route>
      <Route path='selectBatTimfloor' element={<ProtectedRoute><SelectBatTimFloor /></ProtectedRoute>}></Route>
      <Route path='allBatTim' element={<ProtectedRoute><AllBatTim /></ProtectedRoute>}></Route>
      <Route path='allBatTimDeepak' element={<ProtectedRoute><AllBatTimDeepak /></ProtectedRoute>}></Route>
      <Route path='gridBatTim' element={<ProtectedRoute><GridBatTim /></ProtectedRoute>}></Route>
      <Route path='AllBatTIMEasy' element={<ProtectedRoute><AllBatTimCopy /></ProtectedRoute>}></Route>
      <Route path='live-grid-Bat-Tim' element={<ProtectedRoute><LiveGridBatTim /></ProtectedRoute>}></Route>
      

      <Route path='setupFLoPage' element={<ProtectedRoute><SetUpFLO /></ProtectedRoute>}></Route>
      <Route path='live-grid-flo-zones' element={<ProtectedRoute><LiveGridFLOZones /></ProtectedRoute>}></Route>
      <Route path='live-grid-flo-test' element={<ProtectedRoute><LiveGridFLOTest /></ProtectedRoute>}></Route>

      
      

    </Routes>
    <div><ToastContainer /></div>
  </Router>
    
  )

}
export default App