import React from 'react';

export function Cell({ backgroundColor, color, fontSize, borderColor, keyFontSize, keyColor, id, value, borderWidth, hideKey, borderRight, borderBottom }) {
    return <span style={{
        backgroundColor,
        color,
        borderWidth,
        // borderStyle: 'dotted',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingLeft: 3,
        marginLeft: 1,
        fontSize,
        // borderColor,
        borderRight: borderRight ? `solid 3px ${borderColor}` : 'none',
        borderBottom: borderBottom ? `solid 3px ${borderColor}` : 'none',
    }} key={id}>
        {value}
        {
            hideKey ? null
                : <span style={{
                    bottom: 1,
                    right: 1,
                    position: 'absolute',
                    lineHeight: 1,

                    fontSize: keyFontSize,
                    color: keyColor,
                }}>{id}</span>
        }
    </span>;
}

export function TopViewRow({ cells, borderColor = 'white', keyColor = 'gray', fontSize, keyFontSize = '50%', hideKey = false, borderWidth, borderBottom, verticalLines }) {
    const Cells = [];
    for (let i = 0; i < cells.length; i++) {
        const { colors, value, key } = cells[i];
        const cell = <Cell colors={colors}
            key={key}
            id={key}
            backgroundColor={colors ? colors.background : 'white'}
            color={colors ? colors.text : 'black'}
            keyFontSize={keyFontSize}
            fontSize={fontSize}
            keyColor={colors && colors.keyText ? colors.keyText : keyColor}
            borderColor={borderColor}
            hideKey={hideKey}
            borderWidth={borderWidth}
            value={value}
            borderRight={verticalLines.includes(i)}
            borderBottom={borderBottom}
        />;
        Cells.push(cell);
    }
    return <div style={{
        display: 'flex',
        marginTop: 1,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignContent: 'stretch',
        flexGrow: 1,

        // borderWidth: 1,
        // borderStyle: 'solid',
        width: '100%',
        height: '100%',
    }}>{Cells}</div>;


}

export function TopViewGrid({
    height = '100%', width = '100%',
    borderColor,
    fontSize = 15,
    keyFontSize = '50%',
    cells,
    borderWidth = 1,
    hideKey = false,
    verticalLines = [],
    horizontalLines = [],
    backgroundColor = 'black',
    keyColor }) {
    const TopViewRows = [];
    for (let i = 0; i < cells.length; i++) {
        TopViewRows.push(<TopViewRow
            key={i}
            keyFontSize={keyFontSize}
            cells={cells[i]}
            borderColor={borderColor}
            hideKey={hideKey}
            borderWidth={borderWidth}
            keyColor={keyColor}
            borderBottom={horizontalLines.includes(i)}
            verticalLines={verticalLines}
            fontSize={fontSize} />);
    }
    return <div style={{
        height,
        width,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        backgroundColor: backgroundColor,
        alignContent: 'stretch',
    }}>{TopViewRows}</div>;
}