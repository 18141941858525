import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom"
import AuthService from "../../Auth/auth";
import InfoBox from "../../components/infoBox/InfoBox";
import Loader from "../../components/Loader/Loader";
import HeaderSecondary from "../../components/headerSecondary/HeaderSecondary";
import { SpotWSS } from 'wf-utils/spot/spot-wss';
import { TempGrid } from '../live-grid/live-grid-FLO/LiveGridFLO';
import BottomFLOEventTest from '../../components/BottomFLOEventTest';
import ButtonViewTest from "../../components/ButtonViewTest";
import ResetBtnFLO from "../../components/resetBtnFLO";

import { useCallback, useRef } from 'react';
import { toJpeg } from 'html-to-image';
import _ from 'lodash'
import config from '../../config';



const subscribeSpot = async (onData, assetId, templateId) => {
    const subscriptions = [{ assetId: assetId, templateId: templateId }];
    const key = localStorage.getItem("spaceio-key");
    const spotWs = new SpotWSS({
        subscriptions,
        wsUrl: config.WEB_SOCKET_URL,
        token: key,
        onData,
    });

    return () => {
        spotWs.disposeConnection();
    };
};

const LiveGridFLOTest = () => {

    const location = useLocation();
    const navigate = useNavigate();
    let lastEventUpdated = true;
    let title = 'Test';
    let [reset, setReset] = useState(true);
    let floorId = location.state.selectedFLOSpaceData.deviceApi?.assignedSpace?.locationId;
    let floorName = location.state.selectedFLOSpaceData.deviceApi?.assignedSpace?.locationName;
    let room = location.state.selectedFLOSpaceData.deviceApi.displayName;
    let assetId = location.state.selectedFLOSpaceData.assetId;
    let agentId = location.state.selectedFLOSpaceData.deviceApi?.assignedSpace?.device.hardware.agentId;
    let [link_screenshot] = useState([]);
    const ref = useRef();
    const info = 'Please Perform 10 entry & exit.';
    const columns = 32;
    const rows = 24;
    const [loading, setloading] = useState(false);
    const [params, setParams] = useState({
        enableFrame: true,
        width: 700,
        showKeys: false,
        showBorder: false,
        verticalLines: [],
        horizontalLines: [],
    })
    const [activity, setActivity] = useState(null);
    let [floConfigData, setfloConfigData] = useState({})
    const [resetCall, setResetCall] = useState(0);
    const [currentEvent, setCurrentEvent] = useState({
        "entriesToday": 0,
        "exitsToday": 0
    });

    let [lastEvent, setLastEvent] = useState({
        "entriesToday": 0,
        "exitsToday": 0
    });
    let [dummyEvent, setDummyEvent] = useState({
        "entriesToday": 0,
        "exitsToday": 0
    })
    let [zoneConfig, setZoneConfig] = useState({});

    let [event, setEvent] = useState({
        okEvent: 0,
        notOKEvent: 0,
        accuracy: 0
    })
    const [counter, setCounter] = useState(0);
    const [showSnapshotButton, setShowSnapshotButton] = useState(false);

    useEffect(() => {
    }, [event])

    // useEffect(() => {
    //     if (assetId && params.enableFrame === true) {

    //         const unsubscribe = subscribeSpot(handleSpotData, assetId, 11);
    //         return () => {
    //             // unsubscribe();
    //         };
    //     }
    //     else {
    //         // console.log('wating for Asset Id frame ......');
    //     }
    // }, [params]);

    useEffect(() => {
        if (assetId && params.enableFrame === true) {
            const unsubscribe = subscribeSpot(SoptEventHandleData, assetId, 9);
            return () => {
                // unsubscribe();
            };
        }
        else {
            // console.log('wating for Asset Id for Event ......');
        }

    }, [params]);

    useEffect(function () {
        async function fetchData() {
            let key = window.localStorage.getItem('spaceio-key');
            if (key !== null) {
                setloading(true)
                const getData = await AuthService.getConfigZone([assetId]);
                setfloConfigData(getData)
                setZoneConfig(getData.zone);
                setloading(false);
            }
        }
        fetchData();
    }, [resetCall]);

    // useEffect(() => {
    //     if (params != null && params.enableFrame != null && agentId) {
    //         let myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");
    //         let raw = JSON.stringify({
    //             "frame_debug": params.enableFrame
    //             // "frame_debug" : false
    //         });
    //         let url = `https://agent.electricimp.com/${agentId}/frame_debug`;
    //         let requestOptions = {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: 'follow'
    //         };
    //         fetch(url, requestOptions)
    //             .then(response => response.text())
    //             .catch(error => console.log('error', error));
    //     }
    // }, [params && params.enableFrame]);

    const handleSpotData = ({ data }) => {
        setActivity(data);

    };

    const SoptEventHandleData = ({ data }) => {
        if (lastEventUpdated === true) {
            setLastEvent({
                ...lastEvent,
                "entriesToday": data.entriesToday,
                "exitsToday": data.exitsToday
            })
            lastEventUpdated = false
        }
        setCurrentEvent(() => ({
            ...data,
        }));
    };

    useEffect(() => {
        setDummyEvent({
            ...dummyEvent,
            "entriesToday": dummyEvent.entriesToday + (currentEvent.entriesToday - lastEvent.entriesToday),
            "exitsToday": dummyEvent.exitsToday + (currentEvent.exitsToday - lastEvent.exitsToday)
        })

        setLastEvent({
            ...lastEvent,
            "entriesToday": currentEvent.entriesToday,
            "exitsToday": currentEvent.exitsToday
        })
    }, [currentEvent])

    function resetAndDone() {
        setResetCall(resetCall + 1);
        setReset(!reset)
    }

    function card(backgroundColor, header, value) {
        if (value === null) {
            return 0;
        }

        return <span style={{
            backgroundColor,
            margin: '0px 10px',
            // marginBottom: '5px',
            padding: '10px 20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            flex: 1,
            color: 'white',
            borderStyle: 'solid',
            borderRadius: "5px",
        }}>
            <span style={{
                fontSize: 'xx-large',
                textAlign: 'center',
            }}>
                {value}
            </span>
            <span style={{
                fontSize: '70%',
                textAlign: 'center'
            }}>
                {header}
            </span>
        </span>;
    }

    const entryLabel = () => {
        if (params.enableFrame) {
            return (
                <div
                    style={{
                        color: "white",
                        backgroundColor: "#66DE93",
                        width: "35px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                    }}
                >
                    <p
                        style={{
                            fontSize: "12px",
                            textAlign: "center",
                            transform: "rotate(90deg)",
                            whiteSpace: "nowrap",
                        }}
                    >
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                        &nbsp;&nbsp;&nbsp;<b>INSIDE / ENTRY SIDE</b>&nbsp;&nbsp;&nbsp;
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    };

    const exitLable = () => {
        if (params.enableFrame) {
            return (
                <div
                    style={{
                        color: "white",
                        backgroundColor: "#FF616D",
                        width: "35px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                    }}
                >
                    <p
                        style={{
                            fontSize: "12px",
                            textAlign: "center",
                            transform: "rotate(270deg)",
                            whiteSpace: "nowrap",
                        }}
                    >
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                        &nbsp;&nbsp;&nbsp;<b>EXIT SIDE / OUTSIDE</b>&nbsp;&nbsp;&nbsp;
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    };

    const renderFrame = (rows, columns, frame) => {
        return <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TempGrid
                width={params.width}
                rows={rows}
                columns={columns}
                hideKey={!params.showKeys}
                hideBorder={!params.showBorder}
                frameData={frame}
                verticalLines={params.verticalLines}
                horizontalLines={params.horizontalLines}
                orientation={floConfigData.orientationAngle}
                zones={zoneConfig}
            />
        </div>;
    };

    const renderClusterViewFrame = () => {
        if (params != null && params.enableFrame) {
            // if (true) {
            if (activity && activity.liveFrame) {
                // Check if activity object exists and has liveFrame property
                return (
                    <div >
                        <div style={{ margin: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            {/* {entryLabel()}
                            {renderFrame(rows, columns, activity.liveFrame)}
                            {exitLable()} */}
                        </div>
                    </div>
                );
            } else {
                // return <div>{dummyCard('#202153', 'Device live Frame off')}</div>;
            }
        } else {
            return null
        }
    };

    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }


    const getSnapShot = useCallback(async () => {
        // setloading(true)
        if (ref.current === null) {
            return
        }

        await toJpeg(ref.current)
            .then(async (dataUrl) => {
                const date = new Date();
                let fileName =
                    location.state.selectedFLOSpaceData.deviceApi?.assignedSpace?.locationName + "_" +
                    ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + date.getFullYear()
                    + "_" +
                    date.toLocaleTimeString([], {
                        hour12: false,
                    }) +
                    ".jpg"
                console.log("File Name : ", fileName);
                link_screenshot.push(fileName);
                let file = dataURLtoFile(dataUrl, fileName);
                // console.log("file : ", file);
                let isUploadDone = await AuthService.uploadFileTos3(file)
                // console.log("isUploadDone: ", isUploadDone);

                let dataTempRead = await AuthService.getConfigZone([assetId]);

                dataTempRead[0].floMetaDataConfigStatus = 3;
                let dataToWrite = [{
                    "assetId": assetId,
                    "data": { ...dataTempRead[0], "link_screenshot": link_screenshot },

                }]
                console.log('dataToWrite',dataToWrite)
                await AuthService.updateFLOConfig(dataToWrite)
                // frameOff();
                navigate(`/selectFLOfloor?floor=${floorId}&name=${floorName}`,
                    { state: { ...location.state, "final data": dataTempRead, } })

                // setShowSnapshotButton(false)
                // setloading(false)

            })
            .catch(err => console.log(err))

    }, [ref])

    const uploadScreenShot = async () => {
        getSnapShot().then(() => {
            console.log("took ScreenShot ............ ");
        })
    }

    const renderSPOTData = () => {
        if (currentEvent != null && params != null && params.enableFrame) {
            { loading && <Loader /> }
            return <div>
                <div style={{ display: 'flex', margin: '5px', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {card('#66DE93', 'ENTRY', dummyEvent.entriesToday)}
                    {card('#FF616D', 'EXIT', dummyEvent.exitsToday)}
                    {card('#748ADF', 'TOTAL', (dummyEvent.entriesToday - dummyEvent.exitsToday))}
                    {/* {card('#202153', `AVG PIR Value ${currentEvent.digitalPIRValueTime}`, currentEvent.digitalPIRValue)} */}
                    {card('#202153', 'ACCURACY', event.accuracy.toFixed(2))}
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                    <ResetBtnFLO
                        text="Reset"
                        borderRadius="7px"
                        width="25rem"
                        borderColor="#ff9170"
                        background="white"
                        color="#ff9170"
                        onClick={resetFunction}
                    />

                    {showSnapshotButton ? (
                        <ButtonViewTest
                            text={"Take SnapShot"}
                            borderRadius="7px"
                            borderColor="transparent"
                            background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)"
                            color="white"
                            width="55rem"
                            onClick={uploadScreenShot}
                        />
                    ) : (
                        <BottomFLOEventTest
                            title={title}
                            room={room}
                            buttonTextEventOk={`Event OK (${event.okEvent})`}
                            onClickOK={handleOnOK}
                            buttonTextEventNotOk={`Event Not OK (${event.notOKEvent})`}
                            onClickNotOK={handleOnNotOK}
                        />
                    )}

                </div>
                {renderClusterViewFrame()}
            </div>;
        }
        else {
            return <></>;
        }
    };

    useEffect(() => {
        if (counter + 1 > 9 && (dummyEvent.entriesToday >= 5 && dummyEvent.exitsToday >= 5)) {
            setShowSnapshotButton(true);
        }
    }, [counter]);

    const handleOnOK = () => {
        setEvent(prevState => ({
            ...prevState,
            okEvent: prevState.okEvent + 1,
            accuracy: ((prevState.okEvent + 1) / (prevState.okEvent + 1 + prevState.notOKEvent)) * 100
        }));
        setCounter(counter + 1)

    }

    const handleOnNotOK = () => {
        setEvent(prevState => ({
            ...prevState,
            notOKEvent: prevState.notOKEvent + 1,
            accuracy: (prevState.okEvent / (prevState.okEvent + prevState.notOKEvent + 1)) * 100
        }));
    }

    const resetFunction = () => {
        setEvent(prevState => ({
            okEvent: 0,
            notOKEvent: 0,
            accuracy: 0
        }));
        setCounter(0);
        setDummyEvent({
            "entriesToday": 0,
            "exitsToday": 0
        })
        setShowSnapshotButton(false);

    }

    // function frameOff() {
    //     if (params != null && params.enableFrame != null && agentId) {
    //         let myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");
    //         let raw = JSON.stringify({
    //             "frame_debug": false
    //         });
    //         let url = `https://agent.electricimp.com/${agentId}/frame_debug`;
    //         let requestOptions = {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: 'follow'
    //         };
    //         fetch(url, requestOptions)
    //             .then(response => response.text())
    //             .catch(error => console.log('error', error));
    //     }
    // }

    return (<>
        {/* {loading && <Loader />} */}
        <div ref={ref} className="container-fluid g-0">
            <div className="row justify-content-center g-0 mainDiv">
                <div className="grid col-sm-12 col-lg-8" >
                    <div className="row g-0">
                        {
                            <div className="col-12" style={{ background: "#F5F5F5", padding: '0 5px' }}>
                                <div className="row g-0">
                                    <div className="col-11 mx-auto" style={{ background: "#F5F5F5" }}>
                                        {/* <HeaderSecondary primaryText={room} secondaryText={title} goBack={!reset ? () => { resetAndDone(); } : () => { frameOff(); navigate(-1) }} /> */}
                                        <HeaderSecondary primaryText={room} secondaryText={title} goBack={!reset ? () => { resetAndDone(); } : () => {  navigate(-1) }} />

                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <></>
                    <InfoBox text={info} />
                    <br />

                    <div className={`col-1${reset ? 2 : 2} mx-auto`} >
                        {loading && <Loader />}
                        {renderSPOTData()}
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default LiveGridFLOTest