import { useEffect, useState } from "react";
import "./DownBar.css"
import TIM from "../../assets/images/tim.svg"
import closeIcon from "../../assets/images/closeIcon.svg"
import Button1 from "../Button1"

function DownBarBATTiM({ className, closeDownBar, deviceData, styleCustom, onClick }) {
    const [overlay, setOverlay] = useState(null);
    useEffect(() => {
        if (className === "downbar-card") setOverlay(true)
        else setOverlay(false)
    }, [className])

    function productTypeFromProductID(deviceId) {
        switch (deviceId) {
          case "6875f0bf-157d-332e-883f-70e58401dd14":
            return "TIM";
          case "62d3da73-debf-8660-5fb4-f5d6c646358f":
            return "TIM+";
          case "2d5ef6ed-051c-0bb9-70a4-718d31215e0e":
            return "FLO";
          case "cb1a34bf-e4ea-a74b-d496-37c1183ffa97":
            return "BAT-TIM";
          case "4b357a17-cc2d-5d63-116f-a539a78a2802":
            return "POE TIM+";
          case "6a36e934-426c-025f-a025-89bed058974d":
            return "POE TIM";
          case "85939db1-afba-b79e-b424-2e295ec2a9b5":
            return "POE FLO";
          default:
            return "--"; // Default value when deviceId doesn't match any case
        }
      }

    const style = { display: "flex", flexFlow: "row wrap", justifyContent: "space-around" }
    const zInd = { zIndex: 2 }
    function dateToDisplay(data) {
        let today = new Date(data);
        let time = today.getHours() + ":" + today.getMinutes() + " " + today.toDateString()
        return time
    }
    return (<>
        <div onClick={closeDownBar} className={overlay ? "downbar-card-overlay1" : "downbar-card-overlay1 hide"} />
        <div className={className} style={{ ...styleCustom, ...zInd }} >
            <div className="downbar-card-secondary-content1" >
                <div className="downbar-card-secondary-content1-icon">
                    <img src={TIM} alt="Tim icon for representational purpose"></img>
                    <button onClick={closeDownBar} className="downbar-card-secondary-content1-close-icon" aria-label="close icon" tabIndex={overlay ? 0 : -1}>
                        <img src={closeIcon} onClick={closeDownBar} alt="close icon"></img>
                    </button>
                </div>
                <div className="downbar-card-secondary-content1-item2">
                    <span className="downbar-card-secondary-content1-item2-floor-name">{deviceData?.assignedSpace?.name}</span>
                    <span className="downbar-card-secondary-content1-item2-time">Updated:&nbsp;{dateToDisplay(deviceData?.lastUpdated)}</span>
                </div>
            </div>
            <div className="downbar-card-body">
                <div style={style}><span className="downbar-card-body-fixwidth1">Device ID:</span> <span className="downbar-card-body-fixwidth2">{deviceData?.deviceID}</span></div>
                <div style={style}><span className="downbar-card-body-fixwidth1">Marker ID:</span> <span className="downbar-card-body-fixwidth2">{deviceData?.assignedSpace?.serial}</span> <span className="downbar-card-body-fixwidth1">Product Type:</span> <span className="downbar-card-body-fixwidth2">{productTypeFromProductID(deviceData?.assignedSpace?.device?.hardware?.productId)}</span></div>
                <div style={style}><span className="downbar-card-body-fixwidth1">Battery:</span> <span className="downbar-card-body-fixwidth2">{deviceData.batteryVoltage? deviceData.batteryVoltage : 0} V</span></div>
            </div>
            <div className="downbar-card-body">
                <div className="downbar-card-body-header">CALIBRATION SUMMARY</div>
                <div style={style}><span className="downbar-card-body-fixwidth1">Height:</span> <span className="downbar-card-body-fixwidth2">{deviceData?.spotData?.height_set ? deviceData?.spotData?.height_set : 0 } m</span></div>
                <div style={style}><span className="downbar-card-body-fixwidth1">Orientation:</span> <span className="downbar-card-body-fixwidth2">{deviceData?.spotData?.orientation_set ? deviceData.spotData.orientation_set : 0} {'\u00B0'}</span></div>
                <div style={style}><span className="downbar-card-body-fixwidth1">Coordinate(x,y):</span> <span className="downbar-card-body-fixwidth2">{(deviceData?.spotData?.x_value || deviceData?.spotData?.y_value) ? `${deviceData?.spotData?.x_value || 0}, ${deviceData?.spotData?.y_value || 0}` : "0,0"}</span></div>
                {deviceData?.spotData?.setup_status !== 0 ?
                    <div style={style}>
                        {console.log("@@@@@@@@@@@@@@",deviceData.spotData?.instalation_status)}
                        <span className="downbar-card-body-fixwidth1">Status:</span>
                        <span className="downbar-card-body-fixwidth2" style={{ fontWeight: "700", fontSize: "0.8rem", color: "white" }}>
                            {  deviceData?.spotData?.instalation_status === 1 ? (
                                <span style={{ background: "#29CF81", type: "span", borderRadius: "20px", padding: "0.2rem 0.5rem" }}>
                                    Test Passed
                                </span>
                            )
                            :
                            deviceData?.spotData?.instalation_status === 2 ? (
                                <span style={{ background: "#29CF81", type: "span", borderRadius: "20px", padding: "0.2rem 0.5rem" }}>
                                    Partial Passed
                                </span>
                            ):
                            deviceData?.spotData?.instalation_status === 3 ? (
                                <span style={{ background: "#F6516", type: "span", borderRadius: "20px", padding: "0.2rem 0.5rem" }}>
                                    Test Failed 
                                </span>
                            )
                            : deviceData?.spotData?.setup_status === 1 && deviceData?.spotData?.calibration_status === 3 ? (
                                <span style={{ background: "#29CF81", type: "span", borderRadius: "20px", padding: "0.2rem 0.5rem" }}>
                                    Calibration Done
                                </span>
                            )
                            :
                            deviceData?.spotData?.setup_status === 1 && deviceData?.spotData?.calibration_status === 1 ? (
                                <span style={{ background: "#BBBBBB", type: "span", borderRadius: "20px", padding: "0.2rem 0.5rem" }}>
                                    Tim Offline
                                </span>
                            )
                            :
                            deviceData?.spotData?.setup_status === 1 && deviceData?.spotData?.calibration_status === 2 ? (
                                <span style={{ background: "#F6516", type: "span", borderRadius: "20px", padding: "0.2rem 0.5rem" }}>
                                    I2C Error
                                </span>
                            )
                            :
                            deviceData?.spotData?.setup_status === 1 ? (
                                <span style={{ background: "#29CF81", type: "span", borderRadius: "20px", padding: "0.2rem 0.5rem" }}>
                                    Set-up done
                                </span>
                            ):
                             deviceData?.spotData?.calibration_status === 3 ? (
                                <span style={{ background: "#F65162", type: "span", borderRadius: "20px", padding: "0.2rem 0.5rem" }}>
                                    To be tested
                                </span>
                            )
                            :(
                                <span style={{ background: "#F65162", type: "span", borderRadius: "20px", padding: "0.2rem 0.5rem" }}>
                                    Admin Setup Pending
                                </span>
                            )}
                        </span>
                    </div> : null
                }
            </div>
            {
                true && deviceData?.spotData?.setup_status === 0 ?
                    <Button1
                        text={
                            deviceData?.spotData?.setup_status === 0
                                ? "Set-up"
                                : "Admin Set-up Pending"
                        }
                        style={{ marginBottom: "0", textColor: "white" }}
                        background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"} color={"#FFFFFF"}
                        onClick={onClick}
                        tabIndex={overlay ? 0 : -1}
                    /> : null
            }
        </div>
    </>);
}
export default DownBarBATTiM;