import React from "react";

function ButtonViewTest({ text, borderRadius, width, borderColor, background, color, onClick }) {

	const buttonStyle = {
		fontWeight: "700",
		fontSize: "13px",
		background: background,
		borderRadius: borderRadius,
		color: color,
		border: `1px solid ${borderColor || "red"}`,
		width: width || "100%",
		minHeight: "38px",
		padding: "10px"
	}
	return (
		<button style={buttonStyle} onClick={onClick ? onClick : (e) => e.preventDefault()}>
			{text}
		</button>
	);
}

export default ButtonViewTest;