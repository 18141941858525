import "./select-floor.css"
import headLogo from "../../assets/images/headLogo.svg"
import logoutIcon from "../../assets/images/logoutIcon.svg"
import AuthService from "../../Auth/auth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import InfoBox from "../../components/infoBox/InfoBox";
import Select1 from "../../components/Select1";
import SquareCard from "../../components/squareCard/squareCard";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader/Loader";
import Mod1 from "../../components/mod1/Mod1";
import freespaceLogo from "../../assets/images/Dot.svg"
import alertIcon from "../../assets/images/alert.svg"
import backArrow from "../../assets/images/backArrow.svg"

function SelectFloor({ image, productId }) {

    if (!image && !productId) {
        productId = window.localStorage.getItem('productId');
        //console.log("local", productId)
        image = window.localStorage.getItem('productSvg');
    }


    const navigate = useNavigate();
    const location = useLocation();
    // console.log(location.state)
    const [search, setSearch] = useSearchParams();

    const [loading, setloading] = useState(false);
    const [floor, setFloor] = useState(null);
    const [floorDetails, setFloorDetails] = useState();
    const [selectedFloor, setSelectedFloor] = useState(null || search.get("floor"));
    const [label, setLabel] = useState(null || search.get("name"));
    const [info, setInfo] = useState("Select the floor and the room to calibrate or test the device.");
    const [spaces, setSpaces] = useState(null);
    const [parentChildSpaces, setParentChildSpaces] = useState(null);
    const [rawData, setRawData] = useState({});
    const [authenticate, setAuthenticate] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalDetails, setmodalDetails] = useState({
        primaryText: "Are you sure?",
        secondaryText: "Reset will change the status of all the TiM devices to Set-up.",
        icon: alertIcon,
        confirmButtonText: "Confirm",
        confirmOnClick: null
    });


    const noSetup = "Set-up not done"
    const toCalibrate = "To be calibrated";
    const failCalibrate = "Calibration failed"
    const toTest = "To be tested"
    const err = "Error"
    const testComplete = "Testing Completed"


    // useEffect(() => {
    //     async function fetchFloor() {
    //         let key = window.localStorage.getItem('spaceio-key');
    //         let timFloors;
    //         if (key !== null) {

    //             try {
    //                 setloading(true)
    //                 timFloors = await AuthService.getTimFloors(key);
    //                 let opts = timFloors.map(id => ({ value: id, label: id }));
    //                 setAuthenticate(true)
    //                 setFloor(opts);
    //                 setloading(false)
    //             }
    //             catch (e) {
    //                 setloading(false);
    //                 setAuthenticate(false)
    //                 //console.log("----", e)
    //             }

    //         }
    //     }
    //     fetchFloor();
    // }, [])

    useEffect(() => {
        async function fetchRoom() {
            if (floor) {
                let a = floor.map((val, ind) => (val.value));
                let key = window.localStorage.getItem('spaceio-key');
                let myFloorName = null
                await floor.map((i, index) => {
                    if (i.value == selectedFloor) {
                        myFloorName = i.label
                    }
                })
                setSearch({ floor: selectedFloor, name: myFloorName })
                if (key !== null && a.includes(parseInt(selectedFloor))) {
                    let spaces;
                    setloading(true)
                    let productIds = [productId];
                    if (typeof productId === 'string') {
                        productId = [productId];
                        productId = productId[0].split(",");
                    }
                    // productId = ['6a36e934-426c-025f-a025-89bed058974d', '6875f0bf-157d-332e-883f-70e58401dd14']
                    //console.log("selectedFloor", productId, typeof productId);
                    let productIdForApi = null;
                    productIdForApi = typeof productId === "string" ? productId.includes(",") ? productId.split(",") : [productId] : productId;
                    let responseData = []

                    for (const id of productIdForApi) {
                        // console.log("productId", id)
                        // spaces = await AuthService.getTimRooms(key, selectedFloor, id);
                        spaces = await AuthService.getDeviceFromFloorAndProductID(key, selectedFloor, id);
                        // console.log("api data", spaces);
                        if (spaces !== null) {
                            responseData = [...spaces, ...responseData];
                        }
                    }
                    // const spaces = await AuthService.getTimRooms(key, selectedFloor);    //getFloorSpaces
                    getCard(responseData)
                    // setSearch({ floor: selectedFloor })
                }
            }
        }
        fetchRoom();
    }, [selectedFloor, floor])

    useEffect(() => {


        function removeDuplicates(arr, key) {
            return arr.reduce((uniqueArray, obj) => {
                const index = uniqueArray.findIndex(item => item[key] === obj[key]);
                if (index === -1) {
                    uniqueArray.push(obj);
                }
                return uniqueArray;
            }, []);
        }

        async function getFloorIdAndName() {
            let key = window.localStorage.getItem('spaceio-key');
            try {
                let productIdForApi = null;
                productIdForApi = typeof productId === "string" ? productId.includes(",") ? productId.split(",") : [productId] : productId;

                let apiResponses = [];
                let apiData = [];

                //console.log("productIdForApi", typeof productIdForApi);

                for (const id of productIdForApi) {
                    const apiData = await AuthService.getFloorIdAndNameUsingProductId(key, id);
                    // console.log("a[pi data", apiData);
                    if (apiData !== null) {
                        apiResponses = [...apiData, ...apiResponses];
                    }
                }

                apiData = removeDuplicates(apiResponses, 'id');

                // console.log(" ###################", apiData);

                let opts = apiData.map(floorData => ({ value: floorData.id, label: floorData.name }));
                //console.log("opts", opts);
                // setAuthenticate(true)
                // setFloor(opts);
                // setloading(false)



                // let getFloorIdName = await AuthService.getFloorIdAndNameUsingProductId(key, productId);
                // console.log(getFloorIdName);
                // let opts = getFloorIdName.map(floorData => ({ value: floorData.id, label: floorData.name }));
                // console.log(opts);
                setAuthenticate(true)
                setFloor(opts);
                setloading(false)
            }
            catch (e) {
                setloading(false);
                setAuthenticate(false)
            }
        }
        setloading(true);
        getFloorIdAndName();
    }, [])

    const onCardClick = (deviceId) => {
        navigate({ pathname: "/all-tim" },
            { state: parentChildSpaces[deviceId] ? { "parent": Array(spaces[deviceId]), "child": parentChildSpaces[deviceId], "floorDetails": floorDetails, "rawData": rawData } : { "parent": Array(spaces[deviceId]), "floorDetails": floorDetails, "rawData": rawData } }
        )
    }

    const onResetClick = async (deviceId) => {
        setloading(true)
        // const sleep = ms => new Promise(resolve => setTimeout(resolve,ms))
        // await sleep(1000)

        let utcTimestamp = new Date().getTime();
        const resetData = {
            "setup_status": 0,
            "cal_status": 0,
            "calibration_status": 0,
            "instalation_status": 0,
            "utcEpoch": utcTimestamp,
            "test_status": [
                0,
                0,
                0,
                0,
                0
            ],
            "people_count": [
                0,
                0,
                0,
                0
            ]
        }
        if (deviceId in parentChildSpaces) {
            //console.log("parent reset", noSetup)

            let spotWrite = parentChildSpaces[deviceId].map(obj => {
                //console.log(obj)
                let dataToWrite = [{
                    "assetId": obj["assetId"],
                    "data": {
                        ...obj["spotData"],
                        ...resetData
                    }
                }]
                // let apiCall = 
                return AuthService.writeDataToSpot(dataToWrite)
            })
            //console.log(spotWrite)

            let dataObject = spaces[deviceId]
            dataObject["status"] = { status: noSetup, background: "#B1B1B1" }
            let spacesDummy = { ...spaces }
            spacesDummy[deviceId] = dataObject
            setSpaces(spacesDummy)

            await Promise.all(spotWrite)
        }
        else {
            //console.log(spaces[deviceId])

            let dataToWrite = [{
                "assetId": spaces[deviceId]["assetId"],
                "data": {
                    ...spaces[deviceId]["spotData"],
                    ...resetData
                }
            }]

            let dataObject = spaces[deviceId]
            dataObject["status"] = { status: noSetup, background: "#B1B1B1" }
            let spacesDummy = { ...spaces }
            spacesDummy[deviceId] = dataObject
            setSpaces(spacesDummy)

            //console.log(dataToWrite)
            await AuthService.writeDataToSpot(dataToWrite)

        }
        setloading(false)
    }

    const getCard = async (space) => {
        let key = window.localStorage.getItem('spaceio-key');
        let spaces = {};
        let parentChildSpaces = {}
        let rawData = {}

        for (const [index, data] of space.entries()) {               //index is kept for debugging in future as some devices lack certain required properties like capacity, name
            let key = data.assignedSpace || {}
            key["batteryVoltage"] = data.batteryVoltage;
            key["lastUpdated"] = data.lastUpdated;
            key["markerId"] = ((data.assignedSpace || {}).category || {}).id

            for (const id of productId) {
                if (key.counter === "WITH_COORDINATE" && !key.parentId && (((key).device || {}).hardware || {}).productId === id) {
                    spaces[key.id] = {
                        status: "will be set later",
                        ...key
                    }
                }
                else if (key.counter === "WITH_COORDINATE" && key.parentId && (((key).device || {}).hardware || {}).productId === id) {
                    if (parentChildSpaces[key.parentId]) parentChildSpaces[key.parentId].push(key)
                    else {
                        parentChildSpaces[key.parentId] = Array(key)
                        // parentChildSpaces[key.parentId] = parentChildSpaces[key.parentId].push(key)
                    }
                }
            }
        }

        if (Object.keys(parentChildSpaces).length !== 0) {
            if (key !== null) {
                const spaceIds = Object.keys(parentChildSpaces); // Extract space IDs from the parentChildSpaces object
                const space = await AuthService.getFloorSpaces(key, selectedFloor, spaceIds);
                for (const item in parentChildSpaces) {
                    for (const key of space) {
                        if (key.id == item) {
                            spaces[key.id] = {
                                status: "will be set later",
                                ...key
                            };
                        }
                    }
                }
            }

        }

        if (Object.keys(spaces).length !== 0) {
            const spaceDetails = await AuthService.getFloorDetails(key, selectedFloor);
            setFloorDetails(spaceDetails)

            const rawDevice = await AuthService.getRawDevices(key, selectedFloor)
            rawDevice.forEach(val => {
                rawData[val.id] = val
            });
            setRawData(rawData)
        }

        if (Object.keys(spaces).length > 0) {
            let dataCall = (Object.keys(spaces)).map((val) => {
                if (val in parentChildSpaces) {
                    let getAssetIdWithSpaceID = []
                    for (let dataObj of parentChildSpaces[val])
                        getAssetIdWithSpaceID.push(AuthService.getAssetId(dataObj.id))
                    return getAssetIdWithSpaceID
                }
                else return AuthService.getAssetId(val)
            }
            )
            dataCall = [].concat(...dataCall)
            let result = await Promise.all(dataCall)

            let spotData = await AuthService.readDataSPOT(undefined, result.map(val => val.assetId))
            let spotDataObj = {}
            for (let i of spotData) {
                spotDataObj[i.id] = i
            }

            //adding assetId and SpotData to child and parent
            for (let val of result) {
                if (val.spaceId in spaces) {
                    spaces[val.spaceId]["assetId"] = val.assetId
                    spaces[val.spaceId]["spotData"] = spotDataObj[val.assetId]
                }
                else {
                    for (let dataObj in parentChildSpaces) {
                        for (let value of parentChildSpaces[dataObj]) {
                            if (val.spaceId === value.id) {
                                value["assetId"] = val.assetId
                                value["spotData"] = spotDataObj[val.assetId]
                            }
                        }

                    }
                }
            }
            // console.log("child",spaces,"parent",parentChildSpaces)

            //setting the status for squareCard


            for (let data in spaces) {
                data = spaces[data]
                if (data.id in parentChildSpaces) {
                    let deviceStatus = []
                    for (let childSpace of parentChildSpaces[data.id]) {
                        //console.log(childSpace)
                        let setUp = childSpace.spotData.setup_status
                        let instalationStatus = childSpace.spotData.instalation_status
                        let calSetUp = childSpace.spotData.cal_status
                        let calibrationStatus = childSpace.spotData.calibration_status

                        if (instalationStatus > 0) data.status = { status: testComplete, background: "#29CF81" }
                        else {
                            if (setUp === 0) deviceStatus.push(noSetup)
                            else if (setUp === 1 && calSetUp === 2 && calibrationStatus === 0) deviceStatus.push(toCalibrate)
                            else if (setUp === 1 && calSetUp === 2 && calibrationStatus === 2) deviceStatus.push(failCalibrate)
                            else if (setUp === 1 && calSetUp === 2 && calibrationStatus === 1) deviceStatus.push(failCalibrate)
                            else if (setUp === 1 && calSetUp === 3 && calibrationStatus === 3) deviceStatus.push(toTest)
                            else deviceStatus.push(err)
                        }
                    }
                    //console.log(deviceStatus)
                    if (deviceStatus.includes(noSetup)) data.status = { status: noSetup, background: "#B1B1B1" }
                    else if (deviceStatus.includes(toCalibrate)) data.status = { status: toCalibrate, background: "#F65162" }
                    else if (deviceStatus.includes(failCalibrate)) data.status = { status: failCalibrate, background: "#F65162" }
                    else if (deviceStatus.includes(toTest)) data.status = { status: toTest, background: "#F65162" }
                    else if (deviceStatus.every(s => s === testComplete)) data.status = { status: testComplete, background: "#29CF81" }
                    else if (deviceStatus.every(s => s === err)) data.status = { status: err, background: "#F65162" }
                    else data.status = { status: "wrong", background: "#F65162" }

                }
                else {
                    let setUp = data.spotData.setup_status
                    let instalationStatus = data.spotData.instalation_status
                    let calSetUp = data.spotData.cal_status
                    let calibrationStatus = data.spotData.calibration_status

                    if (instalationStatus > 0) data.status = { status: testComplete, background: "#29CF81" }
                    else {
                        if (setUp === 0) data.status = { status: noSetup, background: "#B1B1B1" }
                        else if (setUp === 1 && calSetUp === 2 && calibrationStatus === 0) data.status = { status: toCalibrate, background: "#F65162" }
                        else if (setUp === 1 && calSetUp === 2 && calibrationStatus === 2) data.status = { status: failCalibrate, background: "#F65162" }
                        else if (setUp === 1 && calSetUp === 2 && calibrationStatus === 1) data.status = { status: failCalibrate, background: "#F65162" }
                        else if (setUp === 1 && calSetUp === 3 && calibrationStatus === 3) data.status = { status: toTest, background: "#F65162" }
                        else data.status = { status: err, background: "#F65162" }
                    }
                }
            }
        }

        //console.log("at last", { spaces, parentChildSpaces })
        setSpaces(spaces)
        setParentChildSpaces(parentChildSpaces)
        setloading(false)
    }

    const goBack = () => {
        navigate({ pathname: "/selectProduct" })
    }


    return (

        <div className="container-fluid g-0">
            <div className="row justify-content-center g-0 mainDiv">
                <div className="col-lg-8" style={{ background: "#f5f5f5", display: "flex", flexDirection: "column" }}>

                    <div className="row g-0">
                        {loading && <Loader />}

                        <div className="col-11 mx-auto my-4 icon-logout-product">
                            <button style={{ border: "none", backgroundColor: "#f5f5f5" }} onClick={goBack} >
                                <img src={backArrow} alt="Back"></img>
                            </button>
                            <img src={image} alt="headIcon" height="33px" ></img>

                        </div>


                        <div className="col-11 my-4 mx-auto">
                            <InfoBox text={info} />
                            <label className="label-class mt-4">Select a floor</label>
                            {/* <Select1 defaultValue={selectedFloor === null ? "" : { label: selectedFloor, value: selectedFloor }} placeholder={'Select'} options={floor} onChange={setSelectedFloor} />  */}
                            <Select1
                                defaultValue={selectedFloor === null ? "" : { label: label, value: selectedFloor }}
                                // defaultValue={label}
                                placeholder={'Select'}
                                options={floor !== null ? [...floor].sort((a, b) => {
                                    const labelA = a.label.toLowerCase();
                                    const labelB = b.label.toLowerCase();

                                    const isANumber = /^\d+$/.test(labelA);
                                    const isBNumber = /^\d+$/.test(labelB);

                                    if (isANumber && isBNumber) {
                                        // If both labels are numbers
                                        return Number(labelA) - Number(labelB);
                                    } else if (!isANumber && !isBNumber) {
                                        // If both labels are not numbers
                                        const ordinalA = labelA.match(/(\d+)(st|nd|rd|th)/);
                                        const ordinalB = labelB.match(/(\d+)(st|nd|rd|th)/);

                                        if (ordinalA && ordinalB) {
                                            // If both labels represent ordinal numbers
                                            const numberA = Number(ordinalA[1]);
                                            const numberB = Number(ordinalB[1]);

                                            if (numberA === numberB) {
                                                // If the numbers are equal, compare the ordinal suffixes
                                                return ordinalA[2].localeCompare(ordinalB[2]);
                                            } else {
                                                // If the numbers are different, compare numerically
                                                return numberA - numberB;
                                            }
                                        } else if (ordinalA) {
                                            // If only labelA represents an ordinal number, it comes before labelB
                                            return -1;
                                        } else if (ordinalB) {
                                            // If only labelB represents an ordinal number, it comes after labelA
                                            return 1;
                                        } else {
                                            // If neither label represents an ordinal number, compare alphabetically
                                            return labelA.localeCompare(labelB);
                                        }
                                    } else if (isANumber) {
                                        // If only labelA is a number, it comes before labelB
                                        return -1;
                                    } else {
                                        // If only labelB is a number, it comes after labelA
                                        return 1;
                                    }
                                }) : []}
                                onChange={setSelectedFloor}
                            />
                        </div>

                        {spaces &&
                            <div className="col-11 mx-auto">
                                <label className="label-class">Select a room to proceed</label>
                                <div className="row g-0 select-floor-squareCardDiv">
                                    {Object.keys(spaces).map((deviceId, index) => {
                                        {/* console.log(spaces[deviceId]["status"]["status"] === noSetup, spaces[deviceId]["status"]["status"] === err) */ }
                                        let resetButton = spaces[deviceId]["status"]["status"] === noSetup || spaces[deviceId]["status"]["status"] === err ? false : true
                                        return (<SquareCard
                                            status={spaces[deviceId].status}
                                            name={spaces[deviceId].name}
                                            capacity={spaces[deviceId].capacity}
                                            key={index}
                                            onClick={() => { onCardClick(deviceId) }}
                                            onResetClick={resetButton ? () => { setmodalDetails({ ...modalDetails, confirmOnClick: deviceId }); setShowModal(true) } : null}
                                        />)
                                    })}
                                </div>
                            </div>
                        }

                        {showModal ?
                            <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                                <Mod1 text={{ primaryText: modalDetails.primaryText, secondaryText: modalDetails.secondaryText, icon: modalDetails.icon }}
                                    closeModal={() => setShowModal(false)}
                                    confirmButton={{ text: modalDetails.confirmButtonText, onClick: () => { onResetClick(modalDetails.confirmOnClick); setShowModal(false) } }}
                                />
                            </div>
                            : null
                        }

                        {!loading && !authenticate ?
                            <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                                <Mod1 text={{ primaryText: " It seems you do not have permission to access this tool", secondaryText: "Click below to retry", icon: modalDetails.icon }}
                                    closeModal=/*{() => setShowModal(false)}*/ {null}
                                    confirmButton={{ text: "OK", onClick: () => { navigate("/"); setShowModal(false) } }}
                                />
                            </div>
                            : null
                        }
                    </div>
                    <Footer style={{ marginTop: "auto" }} />
                </div>
            </div>
        </div>
    );

}

export default SelectFloor;