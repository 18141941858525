import { useEffect, useRef, useState } from "react"
import AuthService from "../../Auth/auth"
import HeaderSecondary from "../../components/headerSecondary/HeaderSecondary";
import Loader from "../../components/Loader/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import timIcon from "../../assets/images/timIcon.png"
import "./floor-plan-view.css";

function FloorPlanView() {
    const ref = useRef(null);
    const [imgUrl, setimgUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [imDim, setImDim] = useState({ width: 1, height: 1 });
    const location = useLocation();
    const [cheight, setHeight] = useState(0);
    const [cwidth, setWidth] = useState(0);

    const locationId = location.state.data[0].location.id;
    // location.state.data.map(elem => console.log('elem.srf=>', elem.srf));
    // console.log('location.state', location.state);

    const navigate = useNavigate();
    useEffect(() => {
        async function fetchFloor() {
            let key = window.localStorage.getItem('spaceio-key');
            if (key !== null) {
                const timFloors = await AuthService.getFloorDetails(key, locationId);
                // console.log('timFloors=>', timFloors);
                // console.log('timFloors[0].name=>', timFloors[0].name);

                setimgUrl(timFloors[0].image.url)
                setImDim({ width: timFloors[0].image.width, height: timFloors[0].image.height })
            }
        }
        fetchFloor()
    }, []);

    const handleImageLoaded = () => {
        //console.log('ref.current.offsetHeight- new', ref.current.offsetHeight);
        setHeight(ref.current.offsetHeight);
        setWidth(ref.current.offsetWidth);
        setLoading(false)
    }

    // useEffect(() => {
    //     // setTimeout(() => {
    //         setHeight(ref.current.offsetHeight);
    //         setWidth(ref.current.offsetWidth);
    //     // }, 400);
    // }, []);

    useEffect(() => {
        window.addEventListener("resize", () => {
            if (ref && ref.current != null) {
                setHeight(ref.current.offsetHeight);
                setWidth(ref.current.offsetWidth);
            }
        })
    }, []);



    return (
        <div className="container-fluid g-0">
            <div class="row justify-content-center g-0 mainDiv">
                <div className="col-lg-8" style={{ background: "#f5f5f5" }}>
                    <div className="col-11 mx-auto">
                        {loading && <Loader />}
                        <HeaderSecondary goBack={() => navigate(-1)}
                            primaryText={location.state.parent[0].name}
                            secondaryText={[location.state.floorDetails[0].name, "   ", location.state.floorDetails[0].anchorAddress]}
                        />
                        <div className="fp-comp">
                            <div ref={ref}>
                                <div className="position-relative">
                                    <img src={imgUrl}
                                        alt="floor plan view"
                                        // TODO: for small FP
                                        className="img-fluid"
                                        onLoad={handleImageLoaded}
                                        onError={handleImageLoaded}
                                    />

                                    <div className="hello">
                                    </div>
                                    <div>
                                        {location.state.data.map((elem, index) => {
                                            const coordinateX = elem.srf.x;
                                            const coordinateY = elem.srf.y;
                                            // console.log('within coordinateX=>', coordinateX);
                                            // console.log('cwidth=>', cwidth);
                                            // console.log('imDim.width=>', imDim.width);
                                            // console.log('within coordinateY=>', coordinateY);
                                            // console.log('left ==>', (coordinateX * (cwidth / imDim.width)));
                                            // console.log('top ==>', ((imDim.height - coordinateY) * (cheight / imDim.height)));
                                            // console.log('imDim.height=>', imDim.height);
                                            // console.log('coordinateY=>', coordinateY);
                                            // console.log('imDim.height - coordinateY==>>', imDim.height - coordinateY);
                                            // console.log('cheight=>', cheight);
                                            // console.log('imDim.height=>', imDim.height);
                                            // console.log('(cheight / imDim.height)=>', (cheight / imDim.height));
                                            // console.log('(imDim.height - coordinateY) * (cheight / imDim.height)=>', (imDim.height - coordinateY) * (cheight / imDim.height))
                                            if (cheight === 0 || cwidth === 0) {
                                                return null;
                                            }
                                            return <div className="circle" style={{
                                                left: (coordinateX * (cwidth / imDim.width)) - 12,
                                                top: ((imDim.height - coordinateY) * (cheight / imDim.height)) - 12
                                            }} key={index}>
                                                {/* return <div className="circle" style={{left: coordinateX, top: (imDim.height - coordinateY)}} key={index}> */}

                                                {/* <svg height="16" width="16">
                             <circle cx="8" cy="8" r="8" fill="green" />
                            </svg> */}

                                                <img src={timIcon} alt="TIM ICON" width="24" height="24" />
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div ref={ref}>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>


        </div>
    );
}

export default FloorPlanView;