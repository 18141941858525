import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { withStyles, makeStyles } from "@mui/styles";
import { useMediaQuery } from 'react-responsive';

const IOSSlider = withStyles({
	root: {
		color: '#3880ff',
		height: "6px !important",
		padding: '15px 0'
	},
	track: {
		height: "6px !important",
		borderRadius: 4,
		color: '#ff6f73'
	},
	thumb: {
		color: '#ff6f73'
		// color: "yellow",
	},

	rail: {
		color: '#ff6f73'
		// color: 'black'
	},
	mark: {
		height: "20px !important",
		color: '#ff6f73',
		// color: 'red'
	},
	markLabel: {
		// color: "red",
		fontSize: "0.675rem",
		letterSpacing:"0rem"
	},
	markActive: {
		opacity: '1 !important',
		backgroundColor: "currentColor",
	},
	valueLabel: {
		display:"none"
	}
	
})(Slider);
const MIOSSlider = withStyles({
	root: {
		color: '#3880ff',
		height: "6px !important",
		padding: '15px 0'
	},
	track: {
		height: "6px !important",
		borderRadius: 4,
		color: '#ff6f73'
	},
	thumb: {
		color: '#ff6f73'
		// color: "yellow",
	},

	rail: {
		color: '#ff6f73'
		// color: 'black'
	},
	mark: {
		height: "20px !important",
		color: '#ff6f73',
		// color: 'red'
	},
	markLabel: {
		// color: "red",
		// fontSize: "8px",
		fontSize: "0.675rem",
		letterSpacing:"0rem"
	},
	markActive: {
		opacity: '1 !important',
		backgroundColor: "currentColor",
	},
	valueLabel: {
		display:"none"
	}
})(Slider);


function valuetext(value) {
	return `${value}°C`;
}
function numFormatter(num, step, sFactor) {
	// step 1 = left right , step 5 = angle 
	if (step === 5 ) {
		return `${num}°`;
	} 
	else if(step === 1){
		return `${num}`;
	}
	else {
		return (num / sFactor).toFixed(2) + 'm'
	}
}

export default function DiscreteSliderLabel(props) {
	console.log('slider', props)
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-width: 1224px)'
	})
	return (
		<Box>
			{
				isDesktopOrLaptop ?
					<IOSSlider
						key="1angle"
						aria-label="Always visible"
						valueLabelFormat={(v) => numFormatter(v, props.step, props.scalingFactor)}
						value={props.value ? props.value : 0}
						getAriaValueText={valuetext}
						step={props.step}
						min={props.min}
						max={props.max}
						marks={props.marks}
						valueLabelDisplay="on"
						onChangeCommitted={props.onChange}
					/>
					:
					<MIOSSlider
						key="1angle"
						aria-label="Always visible"
						valueLabelFormat={(v) => numFormatter(v, props.step, props.scalingFactor)}
						value={props.value ? props.value : 0}
						getAriaValueText={valuetext}
						step={props.step}
						min={props.min}
						max={props.max}
						marks={props.marks}
						valueLabelDisplay="on"
						onChangeCommitted={props.onChange}
					/>
			}
		</Box>
	);
}
/**
 https://webdevassist.com/reactjs-materialui/material-ui-slider
 https://mui.com/material-ui/api/slider/
 https://mui.com/material-ui/react-slider/
 https://stackoverflow.com/questions/58327393/how-to-change-material-ui-slider-color
 https://github.com/mui/material-ui/issues/16547
 https://codesandbox.io/s/font-size-slider-mui-bcgfn?file=/demo.js
 */