import { ChakraProvider } from '@chakra-ui/react'
import {
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from '@chakra-ui/react'
function InputFieldNum({ width = "100%", placeHolder, reset, label, value = "", setValue, type, min, max = 9999, step = 1, disabled = false }) {
	function onChange(value) {
		let tempVal = value;
		if (tempVal > max) {
			tempVal = max;
		}
		setValue(tempVal);
	}
	return (
		<div style={{ background: reset ? '#F5F5F5' : "#FFFFFF", border: '1px solid #E5E5E5', borderRadius: '50px', width: width, padding: '10px', display: 'flex', alignItems: 'center' }}>
			<label className="set-up-grid-ht-orientation" style={{ display: 'inline-block', width: '170%' }}>{label}
			</label>
			 {/*<input placeholder={placeHolder} autoComplete="off" style={{ background: 'transparent', color: '#444444', borderRadius: "10px", marginLeft: "10px", border: "none" }} required value={value == null ? '' : value} onChange={onChange} type={type ? type : null} min={min} max={max} step={step} disabled={disabled} /> */}
			 <ChakraProvider>
				<NumberInput size='xs' defaultValue={value} min={min} max={max} step={step} onChange={onChange} disabled={disabled} value={value == null ? '' : value} placeholder={placeHolder} variant='unstyled' ml="10px">
					<NumberInputField fontSize='md' style={{ textAlign: 'right' }} />
					<NumberInputStepper>
						<NumberIncrementStepper />
						<NumberDecrementStepper />
					</NumberInputStepper>
				</NumberInput>
		</ChakraProvider> 
		</div>
	);
}
export default InputFieldNum;