import "./headerSecondary.css"
import backArrow from "../../assets/images/backArrow.svg"
function HeaderSecondary({goBack,buttonSecondary,primaryText,secondaryText,tabInd}) {
    return (  <div className="header-secondary" role="banner">
                    <div className="header-secondary-content1" > 
                            <button tabIndex={tabInd ? -1 : 0} className="header-secondary-content1-item1" onClick={goBack} aria-label="Back">
                            <img src={backArrow} alt="Back" crossOrigin="anonymous"></img>
                            </button>
                        
                            <div className="header-secondary-content1-item2">
                                <span className="header-secondary-content1-item2-floor-number">{secondaryText}</span>
                                <span className="header-secondary-content1-item2-floor-name">{primaryText}</span>
                            </div>
                    </div>    
                    {buttonSecondary ? 
                        buttonSecondary
                    : null}
                </div>
);
}

export default HeaderSecondary;