import { useEffect, useState } from "react";
import "./DownBar.css"
import TIM from "../../assets/images/tim.svg"
import closeIcon from "../../assets/images/closeIcon.svg"
import Button1 from "../Button1"

function DownBar({ className, styleCustom, closeDownBar, deviceData, onClick, buttonText }) {
  const [overlay, setOverlay] = useState(null);
    useEffect(() => {
        if (className === "downbar-card") setOverlay(true)
        else setOverlay(false)

    }, [className])

    function productTypeFromProductID(deviceId) {
        switch (deviceId) {
          case "6875f0bf-157d-332e-883f-70e58401dd14":
            return "TIM";
          case "62d3da73-debf-8660-5fb4-f5d6c646358f":
            return "TIM+";
          case "2d5ef6ed-051c-0bb9-70a4-718d31215e0e":
            return "FLO";
          case "cb1a34bf-e4ea-a74b-d496-37c1183ffa97":
            return "BAT-TIM";
          case "4b357a17-cc2d-5d63-116f-a539a78a2802":
            return "POE TIM+";
          case "6a36e934-426c-025f-a025-89bed058974d":
            return "POE TIM";
          case "85939db1-afba-b79e-b424-2e295ec2a9b5":
            return "POE FLO";
          default:
            return "--"; // Default value when deviceId doesn't match any case
        }
      }

    const style = { display: "flex", flexFlow: "row wrap", justifyContent: "space-around" }
    const zInd = { zIndex: 2 }
    // console.log(deviceData && (deviceData|| {}).calibration !== undefined )
    
    // console.log(deviceData);
    function dateToDisplay(data) {
        let today = new Date(data);
        let time = today.getHours() + ":" + today.getMinutes() + " " + today.toDateString()
        return time
    }

    return (<>
        <div onClick={closeDownBar} className={overlay ? "downbar-card-overlay1" : "downbar-card-overlay1 hide"} />
        <div className={className} style={{ ...styleCustom, ...zInd }} >
            <div className="downbar-card-secondary-content1" >
                <div className="downbar-card-secondary-content1-icon">
                    <img src={TIM} alt="Tim icon for representational purpose"></img>
                    <button onClick={closeDownBar} className="downbar-card-secondary-content1-close-icon" aria-label="close icon" tabIndex={overlay ? 0 : -1}>
                        <img src={closeIcon} onClick={closeDownBar} alt="close icon"></img>
                    </button>
                </div>
                <div className="downbar-card-secondary-content1-item2">
                    <span className="downbar-card-secondary-content1-item2-floor-name">{deviceData ? deviceData.spaceName : null}</span>
                    <span className="downbar-card-secondary-content1-item2-time">Updated:&nbsp;{deviceData ? dateToDisplay(deviceData.lastUpdated) : null}</span>
                </div>
            </div>
            <div className="downbar-card-body">
                <div style={style}><span className="downbar-card-body-fixwidth1">Device ID:</span> <span className="downbar-card-body-fixwidth2">{deviceData.blessId ? deviceData.blessId : null}</span></div>
                <div style={style}><span className="downbar-card-body-fixwidth1">Marker ID:</span> <span className="downbar-card-body-fixwidth2">{deviceData.markerId ? deviceData.markerId : null}</span> <span className="downbar-card-body-fixwidth1">Product Type:</span> <span className="downbar-card-body-fixwidth2">{productTypeFromProductID(deviceData.productId)}</span></div>
                <div style={style}><span className="downbar-card-body-fixwidth1">Battery:</span> <span className="downbar-card-body-fixwidth2">{deviceData.batteryVoltage ? deviceData.batteryVoltage : 0 } V</span></div>

            </div>

            {deviceData && (deviceData || {}).calibration !== undefined ?
                <div className="downbar-card-body">
                    <div className="downbar-card-body-header">CAlIBRATION SUMMARY</div>
                    <div style={style}><span className="downbar-card-body-fixwidth1">Height:</span> <span className="downbar-card-body-fixwidth2">{deviceData.calibration ? (deviceData.calibration|| {}).height : null} m</span></div>
                    <div style={style}><span className="downbar-card-body-fixwidth1">Width:</span> <span className="downbar-card-body-fixwidth2">{deviceData.calibration.width ? (deviceData.calibration || {}).width : 0} m</span></div>
                    <div style={style}><span className="downbar-card-body-fixwidth1">Coordinate(x,y):</span> <span className="downbar-card-body-fixwidth2">{(deviceData.x_value || deviceData.y_value) ? `${deviceData.x_value || 0}, ${deviceData.y_value || 0}` : "0,0"}</span></div>
                    <div style={style}><span className="downbar-card-body-fixwidth1">Orientation:</span> <span className="downbar-card-body-fixwidth2">{deviceData.calibration? (deviceData.calibration || {}).orientation: null} {'\u00B0'}</span></div>
                    <div style={style}><span className="downbar-card-body-fixwidth1">Status:</span>
                        <span className="downbar-card-body-fixwidth2">
                            {deviceData.calibration? (deviceData.calibration || {}).span: null}
                        </span>
                    </div>
                </div> : null}

            {deviceData && (deviceData || {}).calibration !== undefined && deviceData.instalationData ?
                <div className="downbar-card-body">
                    <div className="downbar-card-body-header">TEST SUMMARY</div>
                    <div style={style}><span className="downbar-card-body-fixwidth1">People Count:</span> <span className="downbar-card-body-fixwidth2">{deviceData.instalationData ? ((deviceData.instalationData || {}).peopleCount) + " Tested" : null}</span></div>


                </div> : null}

            {true ?
                <Button1
                    text={buttonText}
                    style={{ marginBottom: "0" }}
                    background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"} color={"#ffffff"}
                    onClick={onClick}
                    tabIndex={overlay ? 0 : -1}
                /> : null}
        </div> </>);
}

export default DownBar;