
function DropDownOrientation({
    width = '100%',
    reset,
    label,
    value = '',
    setValue,
    disabled = false }) {


    function onChange(value) {
        let tempVal = value
        setValue(tempVal)
    }

    return (
        <div
            style={{
                background: reset ? '#F5F5F5' : '#FFFFFF',
                border: '1px solid #E5E5E5',
                borderRadius: '10px',
                width: '100%',
                height: '25%',
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <label
                className='set-up-grid-ht-orientation'
                style={{
                    display: 'inline-block', marginRight: '15px'
                }}
            >
                {label}
            </label>
            <select
                value={value}
                onChange={onChange}
                disabled={disabled}
                style={{
                    background: reset ? '#F5F5F5' : '#FFFFFF',
                    outline: 'none',
                    border: 'none',
                    color: '#444444'
                }}
            >
                <option style={{ textAlign: 'center' }} value={0} className="dropDownSetupFLOPage">0</option>
                <option style={{ textAlign: 'center' }} value={180} className="dropDownSetupFLOPage">180</option>
                <option style={{ textAlign: 'center' }} value={90} className="dropDownSetupFLOPage">90</option>
                <option style={{ textAlign: 'center' }} value={270} className="dropDownSetupFLOPage">270</option>
            </select>
        </div >
    )
}

export default DropDownOrientation
