import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import HeaderGrid from "../../components/HeaderGrid";
import BottomGrid from "../../components/BottomGrid";
import BottomGrid2 from "../../components/BottomGrid2";
import InfoBox from "../../components/infoBox/InfoBox";

import InputFieldText from "../../components/InputFieldText";
import InputFieldNum from "../../components/InputFieldTextNum";
import AuthService from "../../Auth/auth";
import tim from "../../assets/images/timIcon.png";
import "./grid.css";
import HeaderSecondarycopy from "../../components/headerSecondary/HeaderSecondarycopy";
import HeaderSecondary from "../../components/headerSecondary/HeaderSecondary";

import Loader from "../../components/Loader/Loader";
import { useMediaQuery } from "react-responsive";

function GridBatTim() {
  const location = useLocation();
  const navigate = useNavigate();
  //console.log('=>', location);
  console.log("=======>", location.state);

  let floorId = location.state.dataForDownBar.locationId;

  let room = location.state.dataForDownBar.spaceName;
  let spaceId = location.state.dataForDownBar.spaceId;
  let assetId = location.state.dataForDownBar.assetId;
  let title = "Set-Up";
  let deviceId = location.state.dataForDownBar.deviceId;
  let markerId = location.state.dataForDownBar.markerId;

  let [heightInput, setHeightInput] = useState(null); // grid view change should be 0.1
  let [x, setX] = useState(0);
  let [y, setY] = useState(0);
  let [xRange, setXRange] = useState({ min: 0, max: 0 });
  let [yRange, setYRange] = useState({ min: 0, max: 0 });
  let [scalingF, setScalingFactor] = useState(1);
  let [angle, setAngle] = useState(null); // this is for orientation
  let [imgJson, setImgJson] = useState(null);
  let [data, setData] = useState(null);
  let [reset, setReset] = useState(true);
  let [error, setError] = useState(null);

  const [loading, setloading] = useState(true);
  const [resetCall, setResetCall] = useState(0);
  const [isEditing, setIsEditing] = useState(true);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  let desiredHeight = isDesktopOrLaptop ? 500 : window.innerWidth;
  //console.log('innerWidth: ' + window.innerWidth)
  let desiredWidth = window.innerWidth;
  async function setupDone() {
    //console.log("final data", data);
    let key = localStorage.getItem("spaceio-key");
    let utcTimestamp = new Date().getTime();
    let dataToWrite = [
      {
        assetId: assetId,
        data: {
          utcEpoch: utcTimestamp,
          setup_status: 1,
          height_set: heightInput,
          orientation_set: data.timResult.angle,
          x_value: x,
          y_value: y,
          cal_status: 2,
          calibration_status: 0,
          "device_id ": deviceId,
          "marker_id ": markerId,
          number_testers: 1,
          people_count: [0, 0, 0, 0],
          test_status: [0, 0, 0, 0, 0],
          instalation_status: 0,
          " link_screenshot ": ["test"],
        },
      },
    ];

    //console.log(dataToWrite)
    await AuthService.writeDataToSpot(dataToWrite);
    navigate("/AllBatTIMEasy", {
      state: { ...location.state, "final data": data },
    });
  }

  function calTimWidth(d) {
    // console.log({ d });
    return parseInt(0.05 * d.width);
  }
  function resetCallback() {
    setResetCall(resetCall + 1);
  }
  function resetAndDone() {
    setResetCall(resetCall + 1);
    setReset(!reset);
  }
  function changeX(v) {
    // console.log("spotData",v,scalingF,(v / scalingF).toFixed(4))
    // console.log("---", xRange.min, scalingF);
    setX(((xRange.min + v) / scalingF).toFixed(4));
  }
  function changeY(v) {
    // v = data.originalHeight - v
    // console.log("spotData",v,scalingF,(v / scalingF).toFixed(4))
    setY(((yRange.max - v) / scalingF).toFixed(4));
  }

  function handleEditClick () {
    setIsEditing(false);
    setReset(false)
};

  const handleInputChange = (e, key) => {
    const formattedValue = e
      .replace(/[^0-9.]/g, "")
      .replace(/(\..{2}).+/g, "$1");
    if (formattedValue <= 10.0 && formattedValue >= 0.0) {
      data.timResult.heightFromFloor = formattedValue;
      setHeightInput(formattedValue);
    }
    
  };

  useEffect(
    function () {
      async function fetchData() {
        let key = window.localStorage.getItem("spaceio-key");
        if (key !== null) {
          setloading(true);
          const floorDetails = await AuthService.getFloorDetails(key, floorId);
          //console.log({ floorDetails });
          let image = floorDetails[0].image;
          let { scalingFactor } = floorDetails[0];
          setScalingFactor(scalingFactor);
          setImgJson(floorDetails[0].image);

          //single space Id
          const floorSpace = await AuthService.getFloorSpaces(
            key,
            floorId,
            spaceId
          );

          let PolySpaces = floorSpace.filter((space) => space.id === spaceId);
          console.log("pl", PolySpaces);

          // let timdbdata = PolySpaces.map((spaces) => spaces.id).join(",");
          // console.log("timdb", timdbdata);
          console.log(spaceId);
          const timdbdataresult = await AuthService.getTimDBSpaces(
            key,
            spaceId
          );
          console.log("timdbdataresult", timdbdataresult);
          if (timdbdataresult.length === 0) {
            setError("Tim Marker is not available for this space");
            setloading(false);
            return;
          }
          // this should be check👇
          [PolySpaces[0]].forEach(function (o) {
            //console.log(o.id);
            let timResult = timdbdataresult[0];
            // console.log({ timResult })
            setHeightInput(timResult.heightFromFloor);
            setAngle(timResult.angle);

            // console.log(timResult);
            // console.log({ scalingFactor });
            let timO = null;
            let timResultCoords = timResult.coordinates;
            if (timResultCoords.length > 0) {
              let [timX, timY] = timResultCoords.split(",");
              // setX(timX);
              // setY(timY);
              // console.log("spotData timX timY",timX,timY)
              let timScaleX = timX * scalingFactor;
              let timScaleY = timY * scalingFactor;
              timO = {
                srf: { x: timScaleX, y: timScaleY },
              };
            }
            const regex = new RegExp('.*points="([^"]*)".*');
            //console.log('=>>>>', location.state);
            let tempMark = null;
            // if ('child' in location.state) {

            // 	tempMark = location.state.parent[0].deviceApi?.marker.data;
            // } else {
            // 	tempMark = o.marker.data;
            // }
            tempMark = o.marker.data;
            const result = regex.exec(tempMark);

            // console.log("--------------------------------", result);

            if (result && result.length === 2) {
              const coordinatesStr = result[1];
              let minX, minY, maxX, maxY;
              coordinatesStr
                .trim()
                .split(" ")
                .forEach((pairStr) => {
                  const values = pairStr.split(",");
                  const x = parseInt(values[0]);
                  const y = parseInt(values[1]);
                  if (minX === undefined || x < minX) {
                    minX = x;
                  }
                  if (minY === undefined || y < minY) {
                    minY = y;
                  }
                  if (maxX === undefined || x > maxX) {
                    maxX = x;
                  }
                  if (maxY === undefined || y > maxY) {
                    maxY = y;
                  }
                });
              //console.log("maxxxxx", minX, minY, maxX, maxY)
              // const overlayHeight = 450;
              // const overlayWidth = 450;
              const originalWidth = maxX - minX;
              const originalHeight = maxY - minY;
              // const xFactor = overlayWidth / originalWidth;
              // const yFactor = overlayHeight / originalHeight;
              // const factor = Math.max(xFactor, yFactor);
              // console.log(factor);
              const dimensions = {
                x: originalWidth,
                y: originalHeight,
              };
              const offset = {
                x: minX, // - (overlayWidth - factor * originalWidth) / 2,
                y: minY, // - (overlayHeight - factor * originalHeight) / 2,
              };
              // /*
              // console.log({ image });
              // console.log({ minX, minY, maxX, maxY });
              // console.log('this is o', o);
              // console.log('this is timO', timO);
              let actualHeight = dimensions.y;
              let scaleH = desiredHeight / actualHeight;

              let actualWidth = dimensions.x;
              let scaleW = desiredWidth / actualWidth;

              const style = {
                backgroundImage: `url(${image.url})`,
                width: dimensions.x + 2, // + 2 pixel for clearly
                height: dimensions.y + 2, //+ 2 pixel for clearly
                margin: "auto",
                backgroundSize: image.width + 2, // + 2 pixel for clearly
                backgroundPositionX: -1 * offset.x,
                backgroundPositionY: -1 * (image.height - maxY),
                // position: 'absolute',
                outline: "1px solid black",
                transform: isDesktopOrLaptop
                  ? `scale(${scaleH})`
                  : `scale(${scaleW})`,
                transformOrigin: isDesktopOrLaptop ? "50% 0" : "0 0",
              };

              timResult.timTempPosition = {
                top: maxY - timO.srf.y,
                left: timO.srf.x - minX,
              };
              // console.log("spotData data",timResult.timTempPosition.left,timResult.timTempPosition.top)
              setX(timResult.coordinates.split(",")[0].toString());

              setY(timResult.coordinates.split(",")[1].toString());

              let temp = {
                originalWidth,
                originalHeight,
                style,
                timResult,
                scalingFactor,
                offset,
              };
              setXRange({ min: minX, max: maxX });
              setYRange({ min: minY, max: maxY });
              setData(temp);

              // console.log("temp data", temp);
              setloading(false);
            } else {
              //console.log('polygon is not created for ', spaceId)
              setError("Polygon is not created for this space! " + spaceId);
              setloading(false);
            }
          });
        }
      }
      fetchData();
    },
    [resetCall]
  );

  return (
    <div className="container-fluid g-0">
      <div className="row justify-content-center g-0 mainDiv">
        <div className="grid col-sm-12 col-lg-8">
          <div className="row g-0">
            {loading && <Loader />}
            {
              <div
                className="col-12"
                style={{ background: "#F5F5F5", padding: "0 5px" }}
              >
                <div className="row g-0">
                  <div
                    className="col-11 mx-auto"
                    style={{ background: "#F5F5F5" }}
                  >
                    <HeaderSecondary
                      primaryText={room}
                      secondaryText={title}
                      goBack={!reset ? resetAndDone : () => navigate(-1)}
                    />
                  </div>
                </div>
              </div>
            }

            <div
              className={`col-1${reset ? 2 : 2} mx-auto`}
              style={{ background: reset ? "" : "#F5F5F5" }}
            >
              <div
                className="gridHeaderHO"
                style={{
                  display: "flex",
                  justifyContent: `space-${
                    isDesktopOrLaptop ? "around" : "between"
                  }`,
                  marginTop: "30px",
                  marginBottom: "30px",
                  gap: "2px",
                }}
              >
                {/* <InputFieldNum width={isDesktopOrLaptop ? "25%" : "50%"} placeHolder="Null" reset={reset} label="Height(m):" value={heightInput} setValue={(e) => { setHeightInput(e); data.timResult.heightFromFloor = e; }} type="number" min={0} max={10} step={0.01} /> */}
                <InputFieldNum
                  width={isDesktopOrLaptop ? "25%" : "50%"}
                  placeHolder="Null"
                  reset={reset}
                  label="Height(m):"
                  value={heightInput}
                  setValue={(e) => {
                    handleInputChange(e, "heightFromFloor");
                  }}
                  type="number"
                  min={0}
                  max={10}
                  step={0.01}
                  disabled={isEditing}
                />

                <InputFieldText
                  width={isDesktopOrLaptop ? "25%" : "50%"}
                  placeHolder="Null"
                  reset={reset}
                  label="Orientation:"
                  value={angle}
                  setValue={(e) => setAngle(e)}
                  type="number"
                  min={0}
                  max={360}
                  disabled={true}
                />
              </div>
            </div>
            <br />

            <div
              className="col-11.5 mx-auto"
              style={{
                marginTop: reset ? "" : "30px",
                position: "relative",
                height: desiredHeight,
              }}
            >
              {imgJson && data && (
                <div id="arena" style={data.style}>
                  {console.log("setup data", data)}
                  <div id="tim_marker">
                    <GridView
                      data={data}
                      userHeight={heightInput}
                      userAngle={angle}
                    />
                  </div>
                  <div
                    role="img"
                    aria-label="tim"
                    id="tim_marker_test"
                    style={{
                      position: "absolute",
                      top: `${data.timResult.timTempPosition.top}px`,
                      left: `${data.timResult.timTempPosition.left}px`,
                      transform: `rotate(-${angle}deg)`,
                      width: calTimWidth(data.style) || "20px",
                    }}
                  >
                    {/*this is tim marker */}
                    <img
                      src={tim}
                      alt="tim"
                      style={{
                        margin: 0,
                        position: "absolute",
                        top: "50 %",
                        left: "50 %",
                        transform: "translate(-50%, -50%)",
                        width: calTimWidth(data.style) || "20px",
                        height: calTimWidth(data.style) || "20px",
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="col-11 mx-auto">
              {error !== null ? (
                <div>
                  <InfoBox text={error} />
                </div>
              ) : (
                ""
              )}
            </div>
            <br />

            <div className={`col-1${reset ? 2 : 1} mx-auto`}>
              {imgJson &&
                data &&
                (reset ? (
                  <BottomGrid
                    title={title}
                    room={room}
                  changeEdit={() => { setReset(!reset); handleEditClick() }}
                    setupDone={setupDone}
                  />
                ) : (
                  <BottomGrid2
                    title={title}
                    room={room}
                    data={data}
                    range={data.timResult.angle}
                    rangeWidth={data.style.width}
                    changeEdit={() => { setReset(!reset); setIsEditing(true); }}
                    timChange={(val) => {
                      console.log("from child", val);
                      setData(val);
                    }}
                    changeOrt={setAngle}
                    changeX={changeX}
                    changeY={changeY}
                    callback={resetCallback}
                    isDesktopOrLaptop={isDesktopOrLaptop}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GridBatTim;
function GridValue(
  left,
  top,
  scalingFactorMeter,
  rotationAngleFromInput,
  heightFromFloor,
  minX,
  minY
) {
  //console.log(arguments);
  var XOfReact = parseFloat(left);
  var YOfReact = parseFloat(top);
  let height = heightFromFloor; // in meter
  let width = 1.534 * height; /* Area width*/
  let length = (2.856 * height) / 1.5; /*Area hieght*/
  var rxWidth = width * scalingFactorMeter; //10 * 24;//???
  var ryLength = length * scalingFactorMeter; //10 * 32;//???
  let gridWidth = rxWidth / 24; ////
  let gridLength = ryLength / 32;
  let gridpaint = [];
  let gridpaintArray = [];
  let i = 0;
  let j = 0;
  for (j = 0; j < 32; j++) {
    for (i = 0; i < 24; i++) {
      gridpaint.push(`
		<g transform="rotate(-${rotationAngleFromInput} ${XOfReact + 15} ${
        YOfReact + 15
      }) translate(-${ryLength / 2 - 15} , -${rxWidth / 2 - 15})" >
		<rect  x="${XOfReact + j * gridLength}" y="${
        YOfReact + i * gridWidth
      }" width="${gridLength}" height="${gridWidth}"  style="stroke-width:0.3;" />
		<text font-size="0.1em"  x="${XOfReact + j * gridLength}" y="${
        20 + YOfReact + i * gridWidth
      }" transform="rotate(${rotationAngleFromInput} ${
        XOfReact + j * gridLength + gridLength / 2
      },${YOfReact + i * gridWidth + gridWidth / 2})"  opacity=".1" >${
        i * 32 + (31 - j)
      }</text>
		</g>
		`);
      let tempData = {
        g: {
          transform: `rotate(-${rotationAngleFromInput} ${XOfReact} ${YOfReact}) translate(-${
            ryLength / 2
          } , -${rxWidth / 2})`,
        },
        rect: {
          x: `${XOfReact + j * gridLength}`,
          y: `${YOfReact + i * gridWidth}`,
          width: `${gridLength}`,
          height: `${gridWidth}`,
        },
        text: {
          x: `${XOfReact + j * gridLength}`,
          y: `${20 + YOfReact + i * gridWidth}`,
          transform: `rotate(${rotationAngleFromInput} ${
            XOfReact + j * gridLength + gridLength / 2
          },${YOfReact + i * gridWidth + gridWidth / 2})`,
          value: `${i * 32 + (31 - j)}`,
        },
      };
      gridpaintArray.push(tempData);
    }
  }
  // console.log({ gridpaint });
  return [gridpaint, gridpaintArray, rxWidth, ryLength, minX, minY];
  // $('#arena').append(`<svg id="temp-svg"><g id="gridGroup">${gridpaint}</g><svg>`);
}
const GridView = ({ data, userHeight, userAngle }) => {
  // console.log('userAngle', userAngle);
  // console.log('userHeight', userHeight);
  // console.log("GridView", data);
  let { scalingFactor } = data;
  let { left, top } = data.timResult.timTempPosition;
  let { angle } = data.timResult;
  let { heightFromFloor } = data.timResult;
  let { x, y } = data.offset;
  let [returngridpaint, returngridpaintArray, rxWidth, ryLength] = GridValue(
    left,
    top,
    scalingFactor,
    userAngle,
    userHeight,
    x,
    y
  );
  //console.log({ returngridpaintArray });
  return (
    <svg
      width={data.originalWidth}
      height={data.originalHeight}
      // style={{ transform: `translate(-${rxWidth / 2}px, -${ryLength / 2}px)` }}
      // viewBox={`${x} ${y} ${data.originalWidth} ${data.originalHeight}`}
      // viewBox="10 10 10 10"
    >
      {/* <rect width={rxWidth} height={ryLength} style={{ strokeWidth: 0.3, fill: 'transparent' }} /> */}
      {returngridpaintArray.map(({ g, rect, text }) => {
        return (
          <g transform={g.transform} key={text.value}>
            <rect
              x={rect.x}
              y={rect.y}
              width={rect.width}
              height={rect.height}
              style={{
                strokeWidth: 1,
                stroke: "rgba(255, 81, 72,0.1)",
                fill: "rgba(255, 81, 72,0.1)",
              }}
            />
            <text
              fontSize="0.1em"
              x={text.x}
              y={text.y}
              transform={text.transform}
              opacity="0.1"
            >
              {text.value}
            </text>
          </g>
        );
      })}
    </svg>
  );
};

// notes
/*
// + 2 pixel for clearly

// for grid view
https://medium.com/trbl/representing-dynamic-data-using-react-and-svg-part-one-84c8ed1737c7
https://fffuel.co/ooorganize/
*/
