import ButtonViewTest from "./ButtonViewTest"

function BottomGridEditFLO(props) {
	return (
		<div style={{ background: "#f5f5f5", display: "flex", alignItems: "center", gap: "15px", margin: '10px', width: '55rem' }}>
			<ButtonViewTest text={props.buttonTextEventOk} borderRadius="5px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white" onClick={() => props.onClickOK()} />
			<ButtonViewTest text={props.buttonTextEventNotOk} borderRadius="5px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white" onClick={() => props.onClickNotOK()} />
		</div>);
}

export default BottomGridEditFLO;