import "./select-TimP-floor.css"
import headLogo from "../../assets/images/headLogo.svg"
import logoutIcon from "../../assets/images/logoutIcon.svg"
import freespaceLogo from "../../assets/images/Dot.svg"
import AuthService from "../../Auth/auth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import InfoBox from "../../components/infoBox/InfoBox";
import Select1 from "../../components/Select1";

import SquareCardTimP from "../../components/squareCardTimp/squareCardTimP";
import RectangleCard from "../../components/rectangleCard/RectangleCard";
import HeaderSecondary from "../../components/headerSecondary/HeaderSecondary";

import DownBar from "../../components/downBar/DownBar";
import { useEffect, useState } from "react";

import Button1 from "../../components/Button1";
import infoIcon from "../../assets/images/infoIcon.svg"

import RectangleCardTimP from "../../components/rectangleCardTimP/RectangleCardTimP";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader/Loader";
import Mod1 from "../../components/mod1/Mod1";
import alertIcon from "../../assets/images/alert.svg"
import backArrow from "../../assets/images/backArrow.svg"

function SelectFloor({ image, productId }) {

    if (!image && !productId) {
        productId = window.localStorage.getItem('productId');
        image = window.localStorage.getItem('productSvg');
    }
    // console.log("@@@@@@@@@@@",productId)

    const maxValid = 4;
    const minValid = 1;

    // console.log(image, "    ", productId)
    const navigate = useNavigate();
    const location = useLocation();
    // console.log(location.state)
    const [search, setSearch] = useSearchParams();
    const [floorSelected, setFloorSelected] = useState(false)
    const [productIamge, setProductImage] = useState()
    const [loading, setloading] = useState(false);
    const [floor, setFloor] = useState(null);
    const [floorDetails, setFloorDetails] = useState();
    const [selectedFloor, setSelectedFloor] = useState(null || search.get("floor"));
        const [label, setLabel] = useState(null || search.get("name"));


    const [info, setInfo] = useState("Select the floor and the room to calibrate or test the device.");
    const [spaces, setSpaces] = useState(null);
    const [parentChildSpaces, setParentChildSpaces] = useState(null);
    const [rawData, setRawData] = useState({});
    //const [timdevices, setTimDevices] = useState(location.state.child ? location.state.child : location.state.parent)
    const [authenticate, setAuthenticate] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalDetails, setmodalDetails] = useState({
        primaryText: "Please ensure the room is vacant.",
        secondaryText: "Click below to calibrate TIM. Please ensure room is vacant during calibration.",
        icon: alertIcon,
        inputBox: false,
        confirmButtonText: "Confirm Vacant"
    });


    const noSetup = "Set-up not done"
    const toCalibrate = "To be calibrated";
    const failCalibrate = "Calibration failed"
    const toTest = "To be tested"
    const err = "Error"
    const testComplete = "Testing Completed"
    const [calibrateNowButtonClicked, setcalibrateNowButtonClicked] = useState({ showModal: false, modalValue: false, deviceId: null })

    const [testButtonClicked, setTestButtonClicked] = useState(false)
    const [modalInputBox, setModalInputBox] = useState(1);
    const [dataForTest, setDataForTest] = useState({})
    const [calibrate, setCalibrate] = useState(false);
    const [dataForDownBar, setDataForDownBar] = useState({});
    const [downBar, setDownBar] = useState(false);

    useEffect(() => {
        //console.log("RAW DATA", rawData)
        try {
            spaces && Object.keys(spaces).map(spaceid => { return [spaces[spaceid]['macId'] = rawData[spaces[spaceid].device.id].hardware.deviceId, spaces[spaceid]['device']['hardware']['agentId'] = rawData[spaces[spaceid].device.id].hardware.agentId] })
        } catch (e) {
            console.log(e)
        }
    }, [rawData, spaces])
    useEffect(() => {


        async function fetchRoom() {
            if (floor) {
                let a = floor.map((val, ind) => (val.value));
                let key = window.localStorage.getItem('spaceio-key');
                let myFloorName = null
                await floor.map((i, index) => {
                    if (i.value == selectedFloor) {
                        myFloorName = i.label
                    }
                })
                setSearch({ floor: selectedFloor, name: myFloorName })
                if (key !== null && a.includes(parseInt(selectedFloor))) {
                    let spaces;
                    // setloading(true)
                    // console.log("selectedFloor", typeof(productId))

                    // let productIds = [productId];
                    // if (typeof productId === 'string') {
                    //     productId = [productId];
                    //     productId = productId[0].split(",");
                    // }
                    // let responseData = []
                    // for (const id of productIds) {
                        
                    //     let productData = [id]
                    //     if(typeof productData === 'string'){
                    //         id = [id];
                    //         id = id[0].split(",")
                    //     }
                    //     console.log("productId ---->", productData)
                    //     spaces = await AuthService.getTIMPDevice(key, selectedFloor, id);
                    //     if (spaces !== null) {
                    //         responseData = [...spaces, ...responseData];
                    //     }
                    // }

                    setloading(true)
                    let productIdForApi = null;
                    productIdForApi = typeof productId === "string" ? productId.includes(",") ? productId.split(",") : [productId] : productId;
                    let apiResponses = [];

                    for (const id of productIdForApi) {
                        // console.log("productIdForApi@@@@@",id)
                        const spaces = await AuthService.getDeviceFromFloorAndProductID(key, selectedFloor, id);    
                        // console.log("------------>",spaces);
                        if (spaces !== null) {
                            apiResponses = [...spaces, ...apiResponses];
                        }
                        // console.log("@@@@@@",apiResponses);
                    }
                    //console.log("spaces", apiResponses);

                    getCard(apiResponses)

                    // getCard(apiResponses)
                    // setSearch({ floor: selectedFloor })

                }

            
            }
        }
        fetchRoom();
        //fetchAgentId();
    }, [floor, selectedFloor])


    useEffect(() => {
        setProductImage(image);
    }, [image])

    useEffect(() => {

        let key = window.localStorage.getItem('spaceio-key');
        //console.log("keyyy", { key, authenticate })
        setAuthenticate(key ? true : false)

        function removeDuplicates(arr, key) {
            return arr.reduce((uniqueArray, obj) => {
                const index = uniqueArray.findIndex(item => item[key] === obj[key]);
                if (index === -1) {
                    uniqueArray.push(obj);
                }
                return uniqueArray;
            }, []);
        }

        async function getFloorIdAndName() {
            try {

                let productIdForApi = null;
                productIdForApi = typeof productId === "string" ? productId.includes(",") ? productId.split(",") : [productId] : productId;

                let apiResponses = [];
                let apiData = [];

                // console.log("productIdForApi", productIdForApi);

                for (const id of productIdForApi) {
                    //console.log("id", id);
                    const apiData = await AuthService.getFloorIdAndNameUsingProductId(key, id);
                    // console.log("api data", apiData);
                    if (apiData !== null) {
                        apiResponses = [...apiData, ...apiResponses];
                    }
                }

                apiData = removeDuplicates(apiResponses, 'id');

                //console.log(" ###################", apiData);

                // let opts = apiData.map(floorData => ({ value: floorData.id, label: floorData.name }));
                let opts = apiData.map(floorData => ({ value: floorData.id, label: floorData.name }));

                //console.log("opts", opts);
                setFloor(opts);
                setloading(false)
            }
            catch (e) {
                //console.log("e", e)
                setloading(false);
                setAuthenticate(key ? true : false)
            }
        }
        setloading(true);
        getFloorIdAndName();
    }, [])




    const addDashes = (deviceId, dashPosition) => {
        deviceId = deviceId.toString()
        return deviceId.slice(0, dashPosition[0]) + "-" + deviceId.slice(dashPosition[0], dashPosition[0] + dashPosition[1]) + "-" + deviceId.slice(dashPosition[0] + dashPosition[1], dashPosition[0] + dashPosition[1] + dashPosition[2])
    }

    const onCardClick = (timData, setupClicked) => {
        // console.log("look", parentChildSpaces[timData], Array(spaces[timData]))
        // console.log("timData----------->",timData);
        let data = {}
        if (setupClicked) {
            data["batteryVoltage"] = timData.batteryVoltage;
            data["markerId"] = timData.serial;
            data["lastUpdated"] = timData.lastUpdated;
            data["deviceId"] = addDashes(timData.device.id, [4, 3, 3]);
            data["spaceName"] = timData.name;
            data["spaceId"] = timData.id;
            data["locationId"] = timData.locationId;
            data["status"] = timData.status;
            data["assetId"] = timData.assetId;
            data['locationName'] = timData.locationName
            

            //setDataForDownBar(data)
            // console.log("Card clicked", dataForDownBar.assetId);
        }
        //console.log(" Location state", location.state)
        navigate("/gridTimp",
            { state: { ...location.state, "dataForDownBar": setupClicked ? data : data, } })
        //navigate({ pathname: "/all-tim" },
        //    { state: parentChildSpaces[deviceId] ? { "parent": Array(spaces[deviceId]), "child": parentChildSpaces[deviceId], "floorDetails": floorDetails, "rawData": rawData } : { "parent": Array(spaces[deviceId]), "floorDetails": floorDetails, "rawData": rawData } }
        //)
    }

    const handleConfirmButtonClickedForTest = async () => {
        if (modalInputBox >= minValid && modalInputBox <= maxValid) {
            setloading(true)
            setDataForTest({ ...dataForTest, test: modalInputBox })
            //console.log("agentID", dataForTest.agentId)
            await AuthService.increaseFrameRate(dataForTest.agentId)
            navigate("/live-grid",
                { state: { ...location.state, dataForTest: { ...dataForTest, test: modalInputBox } } })
        }
    }
    const onResetClick = async (deviceId) => {
        setloading(true)
        //console.log("??????????")
        //setmodalDetails()
        //const sleep = ms => new Promise(resolve => setTimeout(resolve,ms))
        // await sleep(1000)

        let utcTimestamp = new Date().getTime();
        const resetData = {
            "setup_status": 0,
            "cal_status": 0,
            "calibration_status": 0,
            "instalation_status": 0,
            "utcEpoch": utcTimestamp,
            "test_status": [
                0,
                0,
                0,
                0,
                0
            ],
            "people_count": [
                0,
                0,
                0,
                0
            ]
        }

        const resetDataTimp = {
            "zone_setup_status": 1
        }
        let dataToWrite = []
        let dataToWriteTimp = []
        if (deviceId in parentChildSpaces) {
            //console.log("parent reset", noSetup)

            let spotWrite = parentChildSpaces[deviceId].map(obj => {
                //console.log(obj)
                dataToWrite = [{
                    "assetId": obj["assetId"],
                    "data": {
                        ...obj["spotData"],
                        ...resetData
                    }
                }]


                dataToWriteTimp = [{
                    "assetId": obj["assetId"],
                    "data": {
                        ...obj["spotDataTimp"],
                        ...resetDataTimp

                    }
                }]
                // let apiCall = 
                //console.log("DATA TO WRITE", dataToWrite)

                return [AuthService.writeDataToSpot(dataToWrite), AuthService.writeDataToTIMpSpot(dataToWriteTimp)]
            })
            //console.log(spotWrite)

            let dataObject = spaces[deviceId]
            dataObject["status"] = { status: noSetup, background: "#B1B1B1" }
            dataObject["spotData"] = dataToWrite[0]['data']
            dataObject["spotDataTimp"] = dataToWriteTimp[0]['data']

            let spacesDummy = { ...spaces }
            spacesDummy[deviceId] = dataObject
            setSpaces(spacesDummy)

            await Promise.all(spotWrite)
        }
        else {
            //console.log(spaces[deviceId])

            dataToWrite = [{
                "assetId": spaces[deviceId]["assetId"],
                "data": {
                    ...spaces[deviceId]["spotData"],
                    ...resetData
                }
            }]

            dataToWriteTimp = [{
                "assetId": spaces[deviceId]["assetId"],
                "data": {
                    ...spaces[deviceId]["spotDataTimp"],
                    ...resetDataTimp
                }
            }]
           // console.log("DATA TO WRITE", dataToWrite[0]['data'])
            //console.log("DATA TO WRITE-----", dataToWriteTimp)
            let dataObject = spaces[deviceId]
            dataObject["status"] = { status: noSetup, background: "#B1B1B1" }
            dataObject["spotData"] = dataToWrite[0]['data']
            dataObject["spotDataTimp"] = dataToWriteTimp[0]['data']
            let spacesDummy = { ...spaces }
            spacesDummy[deviceId] = dataObject
            setSpaces(spacesDummy)

            //console.log(dataToWrite)
            await AuthService.writeDataToSpot(dataToWrite)
            await AuthService.writeDataToTIMpSpot(dataToWriteTimp)

        }
                window.location.reload()

        setloading(false)
    }

    const getCard = async (space) => {
        // console.log("space", space)
        let key = window.localStorage.getItem('spaceio-key');
        let spaces = {};
        let parentChildSpaces = {}
        let rawData = {}

        for (const [index, data] of space.entries()) {               //index is kept for debugging in future as some devices lack certain required properties like capacity, name
            // console.log("data", data)

            let key = data.assignedSpace || {}
            key["batteryVoltage"] = data.batteryVoltage;
            key["lastUpdated"] = data.lastUpdated;
            key["markerId"] = ((data.assignedSpace || {}).category || {}).id

            let productIdApi = null;
            productIdApi = typeof productId === "string" ? productId.includes(",") ? productId.split(",") : [productId] : productId;


            for (const id of productIdApi) {
                if (key.counter === "WITH_COORDINATE" && !key.parentId && (((key).device || {}).hardware || {}).productId === id) {
                    spaces[key.id] = {
                        status: "will be set later",
                        ...key
                    }
                }
                else if (key.counter === "WITH_COORDINATE" && key.parentId && (((key).device || {}).hardware || {}).productId === id) {

                    if (parentChildSpaces[key.parentId]) parentChildSpaces[key.parentId].push(key)
                    else {

                        parentChildSpaces[key.parentId] = Array(key)
                        // parentChildSpaces[key.parentId] = parentChildSpaces[key.parentId].push(key)
                    }
                }
            }
        }

        // console.log("********************************************", spaces)

        if (Object.keys(parentChildSpaces).length !== 0) {
            if (key !== null) {
                const spaceIds = Object.keys(parentChildSpaces); // Extract space IDs from the parentChildSpaces object
                const space = await AuthService.getFloorSpaces(key, selectedFloor, spaceIds);
                // console.log("!!!!!!!!!!!!!",spaces);
                for (const item in parentChildSpaces) {
                    for (const key of space) {
                        if (key.id == item) {
                            spaces[key.id] = {
                                status: "will be set later",
                                ...key
                            };
                        }
                    }
                }
                
            }
        }

        // if (key !== null) {
        //     const space = await AuthService.getFloorSpaces(key, selectedFloor);
        //     //console.log(")))))", spaces)
        //     for (const item in parentChildSpaces) {
        //         for (const [index, key] of space.entries()) {
        //             if (key.id == item) {
        //                 spaces[key.id] = {
        //                     status: "will be set later",
        //                     ...key
        //                 }
        //             }
        //         }
        //     }
        // }

        if (Object.keys(spaces).length !== 0) {
            // console.log("------------***********",spaces);
            const spaceDetails = await AuthService.getFloorDetails(key, selectedFloor);
            // console.log("*******",spaceDetails);
            setFloorDetails(spaceDetails)

            const rawDevice = await AuthService.getRawDevices(key, selectedFloor)
            rawDevice.forEach(val => {
                rawData[val.id] = val
            });
            setRawData(rawData)
        }

        if (Object.keys(spaces).length > 0) {
            let dataCall = (Object.keys(spaces)).map((val) => {
                if (val in parentChildSpaces) {
                    let getAssetIdWithSpaceID = []
                    for (let dataObj of parentChildSpaces[val])
                        getAssetIdWithSpaceID.push(AuthService.getAssetId(dataObj.id))
                    return getAssetIdWithSpaceID
                }
                else return AuthService.getAssetId(val)
            }
            )
            dataCall = [].concat(...dataCall)
            let result = await Promise.all(dataCall)

            let spotData = await AuthService.readDataSPOT(undefined, result.map(val => val.assetId))
            let spotDataTimp = await AuthService.readTimPDataSPOT(undefined, result.map(val => val.assetId))
            // console.log("spot", spotData, spotDataTimp)
            let spotDataObj = {}
            let spotDataObjTimp = {}
            for (let i of spotData) {
                spotDataObj[i.id] = i
            }
            for (let i of spotDataTimp) {
                spotDataObjTimp[i.id] = i
            }

            //adding assetId and SpotData to child and parent
            for (let val of result) {
                if (val.spaceId in spaces) {
                    spaces[val.spaceId]["assetId"] = val.assetId
                    spaces[val.spaceId]["spotData"] = spotDataObj[val.assetId]
                    spaces[val.spaceId]["spotDataTimp"] = spotDataObjTimp[val.assetId]
                }
                else {
                    for (let dataObj in parentChildSpaces) {
                        for (let value of parentChildSpaces[dataObj]) {
                            if (val.spaceId === value.id) {
                                value["assetId"] = val.assetId
                                value["spotData"] = spotDataObj[val.assetId]
                                spaces[val.assetId]["spotDataTimp"] = spotDataObjTimp[val.assetId]
                            }
                        }
                    }
                }
            }
            // console.log("child",spaces,"parent",parentChildSpaces)

            //setting the status for squareCard


            for (let data in spaces) {
                data = spaces[data]
                if (data.id in parentChildSpaces) {
                    let deviceStatus = []
                    for (let childSpace of parentChildSpaces[data.id]) {
                        //console.log(childSpace)
                        let setUp = childSpace.spotData.setup_status
                        let instalationStatus = childSpace.spotData.instalation_status
                        let calSetUp = childSpace.spotData.cal_status
                        let calibrationStatus = childSpace.spotData.calibration_status

                        if (instalationStatus > 0) data.status = { status: testComplete, background: "#29CF81" }
                        else {
                            if (setUp === 0) deviceStatus.push(noSetup)
                            else if (setUp === 1 && calSetUp === 2 && calibrationStatus === 0) deviceStatus.push(toCalibrate)
                            else if (setUp === 1 && calSetUp === 2 && calibrationStatus === 2) deviceStatus.push(failCalibrate)
                            else if (setUp === 1 && calSetUp === 2 && calibrationStatus === 1) deviceStatus.push(failCalibrate)
                            else if (setUp === 1 && calSetUp === 3 && calibrationStatus === 3) deviceStatus.push(toTest)
                            else deviceStatus.push(err)
                        }
                    }
                    //console.log(deviceStatus)
                    if (deviceStatus.includes(noSetup)) data.status = { status: noSetup, background: "#B1B1B1" }
                    else if (deviceStatus.includes(toCalibrate)) data.status = { status: toCalibrate, background: "#F65162" }
                    else if (deviceStatus.includes(failCalibrate)) data.status = { status: failCalibrate, background: "#F65162" }
                    else if (deviceStatus.includes(toTest)) data.status = { status: toTest, background: "#F65162" }
                    else if (deviceStatus.every(s => s === testComplete)) data.status = { status: testComplete, background: "#29CF81" }
                    else if (deviceStatus.every(s => s === err)) data.status = { status: err, background: "#F65162" }
                    else data.status = { status: "wrong", background: "#F65162" }

                }
                else {
                    let setUp = data.spotData.setup_status
                    let instalationStatus = data.spotData.instalation_status
                    let calSetUp = data.spotData.cal_status
                    let calibrationStatus = data.spotData.calibration_status

                    if (instalationStatus > 0) data.status = { status: testComplete, background: "#29CF81" }
                    else {
                        if (setUp === 0) data.status = { status: noSetup, background: "#B1B1B1" }
                        else if (setUp === 1 && calSetUp === 2 && calibrationStatus === 0) data.status = { status: toCalibrate, background: "#F65162" }
                        else if (setUp === 1 && calSetUp === 2 && calibrationStatus === 2) data.status = { status: failCalibrate, background: "#F65162" }
                        else if (setUp === 1 && calSetUp === 2 && calibrationStatus === 1) data.status = { status: failCalibrate, background: "#F65162" }
                        else if (setUp === 1 && calSetUp === 3 && calibrationStatus === 3) data.status = { status: toTest, background: "#F65162" }
                        else data.status = { status: err, background: "#F65162" }
                    }
                }
            }
        }

    // console.log("^^^^^^^^^^^^",spaces);
        setSpaces(spaces)
        //console.log("-------------------------------", spaces)
        setParentChildSpaces(parentChildSpaces)
        setloading(false)
    }

    const goBack = () => {
        navigate({ pathname: "/selectProduct" })
    }

    const handleCalibrateButtonClicked = (deviceId) => {
        setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: true, deviceId: deviceId })
    }

    const handleCancelButtonClicked = () => {
        setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: false })
    }
    //console.log("aaaa")

    const handleConfirmButtonClickedForVacant = () => {
        setloading(true)
        //console.log("clicked!!", spaces, spaces[calibrateNowButtonClicked.deviceId])
        //console.log(rawData[spaces[calibrateNowButtonClicked.deviceId].device.id])

        setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: false, modalValue: true })

        //    const timDev = [...spaces]

        async function fetchAgentStatus() {

            //console.log("fetching agent status")
            //setloading(true)
            //         setProgressBar(true);

            let agentIdArr = []
            let dataToWrite = []
            let myresult = await AuthService.readTIMData(rawData[spaces[calibrateNowButtonClicked.deviceId].device.id].hardware.agentId)
        //    console.log("-----------", myresult)
            //        let myresult = await Promise.all((timdevices).map((i) => {
            agentIdArr.push({ agentId: rawData[spaces[calibrateNowButtonClicked.deviceId].device.id].hardware.agentId, assetId: spaces[calibrateNowButtonClicked.deviceId].assetId, ...spaces[calibrateNowButtonClicked.deviceId].spotData })
            //             return AuthService.readTIMData(i.device.hardware.agentId)
            //         }
            //         ))
            //console.log("--//--", agentIdArr)
            let utcTimestamp = new Date().getTime();
            let spotDataToWrite = {
                "utcEpoch": utcTimestamp,
                "height_set": agentIdArr[0]["height_set"],
                "orientation_set": agentIdArr[0]["orientation_set"],
                "x_value": agentIdArr[0]["x_value"],
                "y_value": agentIdArr[0]["y_value"],
                "device_id": agentIdArr[0]["device_id"],
                "marker_id": agentIdArr[0]["marker_id"],
                "number_testers": 1,
                "people_count": [
                    0,
                    0,
                    0,
                    0
                ],
                "test_status": [
                    0,
                    0,
                    0,
                    0
                ],
                "instalation_status": 0,
                "link_screenshot": [
                    "test"
                ]

            }
            //console.log("--//--", spotDataToWrite)
            // }
            const key = localStorage.getItem("spaceio-key")
            let nackFlag = false
            //         for (let i in myresult) {
            if (myresult.state === 'Online') {
                //console.log("device online", agentIdArr[0].id)
                let ackStatus = await AuthService.sendCalibrationCommand(agentIdArr[0].agentId)
                //console.log("ackStatus", ackStatus)

                if (ackStatus["calibartionStatus"] === 'ACK') {

                    nackFlag = false
                    //console.log(ackStatus["calibartionStatus"], agentIdArr[0]["assetId"])
                    let utcTimestamp = new Date().getTime();
                    dataToWrite = [{
                        "assetId": agentIdArr[0]["assetId"],
                        "data": {
                            "utcEpoch": utcTimestamp,
                            "height_set": agentIdArr[0]["height_set"],
                            "orientation_set": agentIdArr[0]["orientation_set"],
                            "x_value": agentIdArr[0]["x_value"],
                            "y_value": agentIdArr[0]["y_value"],
                            "device_id": agentIdArr[0]["device_id"],
                            "marker_id": agentIdArr[0]["marker_id"],
                            "number_testers": 1,
                            "people_count": [
                                0,
                                0,
                                0,
                                0
                            ],
                            "test_status": [
                                0,
                                0,
                                0,
                                0,
                                0
                            ],
                            "instalation_status": 0,
                            "link_screenshot": [
                                "test"
                            ],
                            "setup_status": 1,
                            "cal_status": 3,
                            "calibration_status": 3

                        }
                    }]
                    AuthService.writeDataToSpot(dataToWrite)
                    let dataObject = spaces[calibrateNowButtonClicked.deviceId]
                    dataObject['status'] = { status: toTest, background: "#F65162" }
                    let spacesDummy = { ...spaces }
                    spacesDummy[calibrateNowButtonClicked.deviceId] = dataObject
                    setSpaces(spacesDummy)
                    //setinfoBoxText({ text: "Click on respective test button" })


                }

                else if (ackStatus["calibartionStatus"] === 'NACK' || ackStatus["calibartionStatus"] === 'PIR_ACTIVE') {
                    nackFlag = true
                    //console.log(ackStatus["calibartionStatus"], agentIdArr[i]["assetId"])
                    let utcTimestamp = new Date().getTime();
                    dataToWrite = [{
                        "assetId": agentIdArr[0]["assetId"],
                        "data": {
                            "utcEpoch": utcTimestamp,
                            "height_set": agentIdArr[0]["height_set"],
                            "orientation_set": agentIdArr[0]["orientation_set"],
                            "x_value": agentIdArr[0]["x_value"],
                            "y_value": agentIdArr[0]["y_value"],
                            "device_id": agentIdArr[0]["device_id"],
                            "marker_id": agentIdArr[0]["marker_id"],
                            "number_testers": 1,
                            "people_count": [
                                0,
                                0,
                                0,
                                0
                            ],
                            "test_status": [
                                0,
                                0,
                                0,
                                0,
                                0
                            ],
                            "instalation_status": 0,
                            "link_screenshot": [
                                "test"
                            ],
                            "setup_status": 1,
                            "cal_status": 2,
                            "calibration_status": 2


                        }
                    }]
                    AuthService.writeDataToSpot(dataToWrite)
                    //setinfoBoxText({ text: "Click on respective test button" })
                    setCalibrate(true)

                    //check this
                    setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: false, modalValue: false })
                    let dataObject = spaces[calibrateNowButtonClicked.deviceId]
                    dataObject['status'] = { status: failCalibrate, background: "#F65162" }
                    let spacesDummy = { ...spaces }
                    spacesDummy[calibrateNowButtonClicked.deviceId] = dataObject
                    setSpaces(spacesDummy)

                }
                else {
                    //console.log("calibration status neither ACK nor NACK")
                }

            }
            else {
                //console.log("device offline", agentIdArr[i]["assetId"])
                nackFlag = true
                let utcTimestamp = new Date().getTime();
                dataToWrite = [{
                    "assetId": agentIdArr[0]["assetId"],
                    "data": {
                        "utcEpoch": utcTimestamp,
                        "height_set": agentIdArr[0]["height_set"],
                        "orientation_set": agentIdArr[0]["orientation_set"],
                        "x_value": agentIdArr[0]["x_value"],
                        "y_value": agentIdArr[0]["y_value"],
                        "device_id": agentIdArr[0]["device_id"],
                        "marker_id": agentIdArr[0]["marker_id"],
                        "number_testers": 1,
                        "people_count": [
                            0,
                            0,
                            0,
                            0
                        ],
                        "test_status": [
                            0,
                            0,
                            0,
                            0,
                            0
                        ],
                        "instalation_status": 0,
                        "link_screenshot": [
                            "test"
                        ],
                        "setup_status": 1,
                        "cal_status": 2,
                        "calibration_status": 1


                    }
                }]
                AuthService.writeDataToSpot(dataToWrite)
                setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: false, modalValue: false })
                let dataObject = spaces[calibrateNowButtonClicked.deviceId]
                dataObject['status'] = { status: failCalibrate, background: "#F65162" }
                let spacesDummy = { ...spaces }
                spacesDummy[calibrateNowButtonClicked.deviceId] = dataObject
                setSpaces(spacesDummy)
            }

            spaces[calibrateNowButtonClicked.deviceId]['spotData'] = dataToWrite[0]["data"]



            let dataObject = spaces[calibrateNowButtonClicked.deviceId]
            dataObject['spotData'] = dataToWrite[0]["data"]
            dataObject['macId'] = myresult.deviceType
            dataObject['device']['hardware']['agentId'] = agentIdArr[0].agentId

            let spacesDummy = { ...spaces }
            spacesDummy[calibrateNowButtonClicked.deviceId] = dataObject
            setSpaces(spacesDummy)
            //console.log("spaces", spaces)
            //setSpaces(spaces)
            //             for (let i = 0; i < timDev.length; i++) {
            //                 // console.log("------------------", dataToWrite[0])
            //                 if (timDev[i]['assetId'] === dataToWrite[0]["assetId"]) {
            //                     //console.log(dataToWrite[0]["assetId"], " | ", timDev[i], " | ", dataToWrite)
            //                     timDev[i]['spotData'] = dataToWrite[0]["data"]


            //                 }
            //             }
            //             setTimDevices(timDev)

            //             //check this
            //             // setinfoBoxText({ text: "test" })
            //         }

            //         //console.log("nack flag--------", nackFlag)
            //         if (nackFlag === true) setinfoBoxText({ text: "Device not responding. Please recalibrate." })
            //         setProgressBar(false);
            //         setloading(false)
            //     }
            //     fetchAgentStatus()
            // }


            // const handleConfirmButtonClickedForVacant = () => {
            //     console.log("clicked!!", spaces[calibrateNowButtonClicked.deviceId])
            //     console.log(rawData[spaces[calibrateNowButtonClicked.deviceId].device.id])
            setloading(false)
        }
        fetchAgentStatus()
    }

    const setModalDetailsAfterCheck = (value) => {

        let val = parseInt(value)
        //console.log(val)
        setModalInputBox(val)
        setTimeout(function () {
            if (val < minValid) setModalInputBox(1)
            else if (val > maxValid) setModalInputBox(maxValid)
        }
            , 1500)
    }
    const handleRectangleCardClickForSetUp = (timData) => {
        //console.log('alltim => ', timData)
        let data = {}
        data["batteryVoltage"] = timData.batteryVoltage;
        data["markerId"] = timData.serial;
        data["lastUpdated"] = timData.lastUpdated;
        data["deviceId"] = addDashes(timData.device.id, [4, 3, 3]);
        data['blessId'] = addDashes(timData.device.blessId, [4,3,3])
        data["spaceName"] = timData.name;
        data["spaceId"] = timData.id;
        data["locationId"] = timData.locationId;
        data["status"] = timData.status;
        data["assetId"] = timData.assetId;
        data['locationName'] = timData.locationName;
        data['productId'] = timData.device.hardware.productId;
        data['x_value'] = timData.spotData.x_value;
        data['y_value'] = timData.spotData.y_value;
        //console.log("data----->", data)

        let setUpStatus = (timData.spotData || {}).setup_status
        let calSetUp = (timData.spotData || {}).cal_status
        let calibrationStatus = (timData.spotData || {}).calibration_status
        let instalationStatus = (timData.spotData || {}).instalation_status
        let cal = {
            height: (timData.spotData || {})["height_set"],
            orientation: (timData.spotData || {})["orientation_set"]
        }

        let instalationData = { peopleCount: (timData.spotData || {})["people_count"] }
        if (instalationStatus > 0) {
            if (instalationStatus === 1) {
                data["calibration"] = {
                    span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#29CF81" }}>
                        Test Passed
                    </span>,
                    ...cal,
                }
                data["instalationData"] = { ...instalationData }
            }
            else if (instalationStatus === 2) {
                data["calibration"] = {
                    span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#29CF81" }}>
                        Partial Passed
                    </span>,
                    ...cal,
                }
                data["instalationData"] = { ...instalationData }
            }
            else if (instalationStatus === 3) {
                data["calibration"] = {
                    span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#F65162" }}>
                        Test Failed
                    </span>,
                    ...cal,
                }
                data["instalationData"] = { ...instalationData }
            }
            else {
                data["calibration"] = {
                    span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#F65162" }}>
                        Test Error
                    </span>,
                    ...cal,
                }
                data["instalationData"] = { ...instalationData }
            }
        }
        else {

            if (setUpStatus !== 0) {
                if (setUpStatus === 1 && calSetUp === 2 && calibrationStatus === 0) {
                    data["calibration"] = {
                        span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#29CF81" }}>
                            Set-up done
                        </span>,
                        ...cal
                    }

                }

                else if (setUpStatus === 1 && calSetUp === 2 && calibrationStatus === 1) {
                    data["calibration"] = {
                        span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#BBBBBB" }}>
                            Tim Offline
                        </span>,
                        ...cal
                    }
                }
                else if (setUpStatus === 1 && calSetUp === 2 && calibrationStatus === 2) {

                    data["calibration"] = {
                        span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#F65162" }}>
                            Not Detected
                        </span>,
                        ...cal
                    }


                }
                else if (setUpStatus === 1 && calSetUp === 3 && calibrationStatus === 3) {
                    data["calibration"] = {
                        span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#29CF81" }}>
                            Calibrated
                        </span>,
                        ...cal
                    }

                }
                else {
                    data["calibration"] = {
                        span: <span className="rectangle-card-body-top-content1-setUp-done" style={{ background: "#F65162" }}>
                            Error
                        </span>,
                        ...cal
                    }
                }
            }
        }
        //console.log("alltim =>", data)

        setDataForDownBar(data)
        setDownBar(!downBar)
    }
    const handleRectangleCardButtonClickForZone = async (timData) => {
        // console.log("hi", timData)
        let data = {};
        data["deviceId"] = timData.device.id;
        data["spaceName"] = timData.name;
        data["spaceId"] = timData.id;
        data["locationId"] = timData.locationId;
        data["status"] = timData.status;
        data["assetId"] = timData.assetId
        data["test"] = modalInputBox
        data["spotData"] = timData.spotData
        data["macId"] = timData.macId
        data["agentId"] = timData.device.hardware.agentId
        data["spaceName"] = timData.name;
        data["locationName"] = timData.locationName
        data['floorDetails'] = floorDetails;
        data['productId'] = timData.device.hardware.productId;
        data['x_value'] = timData.spotData.x_value;
        data['y_value'] = timData.spotData.y_value;

        setDataForTest(data)
        //console.log(data)

        //let result = await AuthService.increaseFrameRate(data.agentId)
        //console.log("hi ", location.state, data)
        await AuthService.increaseFrameRate(timData.device.hardware.agentId)
        navigate("/live-grid-Timp-zones",
            { state: { ...location.state, dataForTest: { data, test: modalInputBox } } })
    }

    const handleRectangleCardButtonClickForTest = async (timData) => {
       // console.log("hi", timData);
        let data = {};
        data["deviceId"] = timData.device.id;
        data["spaceName"] = timData.name;
        data["spaceId"] = timData.id;
        data["locationId"] = timData.locationId;
        data["status"] = timData.status;
        data["assetId"] = timData.assetId
        data["test"] = modalInputBox
        data["spotData"] = timData.spotData
        data["macId"] = timData.macId
        data["agentId"] = timData.device.hardware.agentId
        data["spaceName"] = timData.name;
        data["locationName"] = timData.locationName;
        data['floorDetails'] = floorDetails;
        data['productId'] = timData.device.hardware.productId;
        data['x_value'] = timData.spotData.x_value;
        data['y_value'] = timData.spotData.y_value;

        setDataForTest(data)
        //console.log(data)

        //let result = await AuthService.increaseFrameRate(data.agentId)
        //console.log("hi ", location.state, data)
        await AuthService.increaseFrameRate(timData.device.hardware.agentId)
        navigate("/live-grid-Timp-test",
            { state: { ...location.state, dataForTest: { data, test: modalInputBox } } })
    }


    return (

        <div className="container-fluid g-0">
            <div className="row justify-content-center g-0 mainDiv">
                <div className="col-lg-8" style={{ background: "#f5f5f5", display: "flex", flexDirection: "column" }}>

                    <div className="row g-0">
                        {loading && <Loader />}

                        <div className="col-11 mx-auto my-4 icon-logout-product">
                            <button style={{ border: "none", backgroundColor: "#f5f5f5" }} onClick={goBack} >
                                <img src={backArrow} alt="Back"></img>
                            </button>
                            <img src={productIamge} alt="headIcon" height="33px" ></img>
                        </div>
                        <div className="col-11 my-4 mx-auto">
                            <InfoBox text={info} />
                            <label className="label-class mt-4">Select a floor</label>
                            {/* <Select1 defaultValue={selectedFloor === null ? "" : { label: selectedFloor, value: selectedFloor }} 
                                placeholder={'Select'} options={floor} 
                                onChange={setSelectedFloor} />
                            {(selectedFloor != null)} */}
                            <Select1
                                defaultValue={selectedFloor === null ? "" : { label: label, value: selectedFloor }}
                                // defaultValue={label}
                                placeholder={'Select'}
                                options={floor !== null ? [...floor].sort((a, b) => {
                                    const labelA = a.label.toLowerCase();
                                    const labelB = b.label.toLowerCase();

                                    const isANumber = /^\d+$/.test(labelA);
                                    const isBNumber = /^\d+$/.test(labelB);

                                    if (isANumber && isBNumber) {
                                        // If both labels are numbers
                                        return Number(labelA) - Number(labelB);
                                    } else if (!isANumber && !isBNumber) {
                                        // If both labels are not numbers
                                        const ordinalA = labelA.match(/(\d+)(st|nd|rd|th)/);
                                        const ordinalB = labelB.match(/(\d+)(st|nd|rd|th)/);

                                        if (ordinalA && ordinalB) {
                                            // If both labels represent ordinal numbers
                                            const numberA = Number(ordinalA[1]);
                                            const numberB = Number(ordinalB[1]);

                                            if (numberA === numberB) {
                                                // If the numbers are equal, compare the ordinal suffixes
                                                return ordinalA[2].localeCompare(ordinalB[2]);
                                            } else {
                                                // If the numbers are different, compare numerically
                                                return numberA - numberB;
                                            }
                                        } else if (ordinalA) {
                                            // If only labelA represents an ordinal number, it comes before labelB
                                            return -1;
                                        } else if (ordinalB) {
                                            // If only labelB represents an ordinal number, it comes after labelA
                                            return 1;
                                        } else {
                                            // If neither label represents an ordinal number, compare alphabetically
                                            return labelA.localeCompare(labelB);
                                        }
                                    } else if (isANumber) {
                                        // If only labelA is a number, it comes before labelB
                                        return -1;
                                    } else {
                                        // If only labelB is a number, it comes after labelA
                                        return 1;
                                    }
                                }) : []}
                                onChange={setSelectedFloor}
                            />
                        </div>
                        {calibrateNowButtonClicked.showModal || testButtonClicked ?
                            <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                                <Mod1 text={{ primaryText: modalDetails.primaryText, secondaryText: modalDetails.secondaryText, icon: modalDetails.icon }}
                                    closeModal={testButtonClicked ? () => setTestButtonClicked(false) : handleCancelButtonClicked}
                                    confirmButton={{ text: modalDetails.confirmButtonText, onClick: testButtonClicked ? handleConfirmButtonClickedForTest : handleConfirmButtonClickedForVacant }}
                                    inputBox={modalDetails.inputBox}
                                    value={modalDetails.inputBox ? modalInputBox : null}
                                    setValue={modalDetails.inputBox ? setModalDetailsAfterCheck : null}
                                //tabInd={downBar}
                                />
                            </div>
                            : null}

                        {spaces &&
                            <div className="col-11 mx-auto">
                                <label className="label-class">Select a device to proceed</label>
                                <div className="row g-0 select-floor-squareCardDiv">
                                    {Object.keys(spaces).map((deviceId, index) => {
                                        {    //console.log(deviceId,index)
                                            /*console.log(spaces[deviceId]["status"]["status"] === noSetup, spaces[deviceId]["status"]["status"] === err) */
                                        }
                                        // {console.log("spaces--------------------",spaces[deviceId].device.blessId);}
                                        //console.log("SPACES", spaces[deviceId]["spotDataTimp"]["zone_setup_status"])
                                        let resetButton = spaces[deviceId]["status"]["status"] === noSetup || spaces[deviceId]["status"]["status"] === err ? false : true
                                        //resetButton = spaces[deviceId]["spotDataTimp"]["zone_setup_status"] === 1 ? true : false
                                        //console.log(resetButton)
                                        return (
                                            // <SquareCardTimP
                                            //     status={spaces[deviceId].status}
                                            //     name={spaces[deviceId].name}
                                            //     capacity={spaces[deviceId].capacity}
                                            //     key={index}
                                            //     onClick={() => { onCardClick(deviceId) }}
                                            //     onResetClick={resetButton ? () => { setmodalDetails({ ...modalDetails, confirmOnClick: deviceId }); setShowModal(true) } : null}
                                            // />

                                            <RectangleCardTimP
                                                tabInd="TIM+"
                                                key={index}
                                                markerId={spaces[deviceId].serial}
                                                productId = {spaces[deviceId].device.hardware.productId}
                                                deviceName={spaces[deviceId].name}
                                                deviceId={addDashes(spaces[deviceId].device.blessId,[4, 3, 3])}
                                                status={spaces[deviceId].status}
                                                onClick={() => handleRectangleCardClickForSetUp(spaces[deviceId])}
                                                setUpDevice={spaces[deviceId].status.status === "Set-up not done" ? (e) => { console.log("here"); e.stopPropagation(); onCardClick(spaces[deviceId], true) } : (e) => { console.log("there"); e.stopPropagation();/* handleRectangleCardButtonClickFortest(spaces[deviceId]) */ }}
                                                setUpStatus={spaces[deviceId].spotData.setup_status}
                                                calSetUp={spaces[deviceId].spotData.cal_status}
                                                calibrationStatus={spaces[deviceId].spotData.calibration_status}
                                                instalationStatus={spaces[deviceId].spotData.instalation_status}
                                                zoneSetUpStatus={spaces[deviceId].spotDataTimp.zone_setup_status}
                                                onResetClick={resetButton ? () => {
                                                    setmodalDetails({
                                                        ...modalDetails, primaryText: "Are you sure?",
                                                        secondaryText: "Reset will change the status of all the TiM devices to Set-up.",
                                                        icon: alertIcon,
                                                        confirmButtonText: "Confirm",
                                                        confirmOnClick: deviceId
                                                    }); setShowModal(true)
                                                } : null}
                                                handleCalibrateButtonClicked={() => { handleCalibrateButtonClicked(deviceId) }}
                                                capacity={spaces[deviceId].capacity}
                                                handleRectangleCardButtonClickForZone={(e) => { e.stopPropagation(); handleRectangleCardButtonClickForZone(spaces[deviceId]) }}
                                                handleRectangleCardButtonClickForTest={(e) => { handleRectangleCardButtonClickForTest(spaces[deviceId]) }}

                                            //retest={retest ? (e) => { e.stopPropagation(); handleRectangleCardButtonClickFortest(obj) } : null}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        }

                        {showModal ?
                            <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                                <Mod1 text={{ primaryText: modalDetails.primaryText, secondaryText: modalDetails.secondaryText, icon: modalDetails.icon }}
                                    closeModal={() => setShowModal(false)}
                                    confirmButton={{ text: modalDetails.confirmButtonText, onClick: () => { onResetClick(modalDetails.confirmOnClick); setShowModal(false) } }}
                                />
                            </div>
                            : null
                        }

                        {!loading && !authenticate ?
                            <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                                <Mod1 text={{ primaryText: " It seems you do not have permission to access this tool", secondaryText: "Click below to retry", icon: modalDetails.icon }}
                                    closeModal=/*{() => setShowModal(false)}*/ {null}
                                    confirmButton={{ text: "OK", onClick: () => { navigate("/"); setShowModal(false) } }}
                                />
                            </div>
                            : null
                        }
                        {<div className="col-12 mx-auto">
                            {/* {console.log("data ................", dataForDownBar)}
                            // {console.log("memememememe", dataForDownBar.spaceId)} */}
                            {/* {console.log("---------->",dataForDownBar.spaceId)} */}
                            {/* {console.log("
                        --->",spaces[dataForDownBar.spaceId])} */}
                            <DownBar
                                deviceData={dataForDownBar}
                                className={downBar ? "downbar-card" : "downbar-card hide"}
                                closeDownBar={() => { setDownBar(!downBar) }}
                                onClick={dataForDownBar.calibration ? () => setDownBar(!downBar) : () => onCardClick(spaces[dataForDownBar.spaceId], true)}
                                buttonText={dataForDownBar.calibration ? "Done" : "Set-Up TIM"}
                                // zoneSetUpStatus={dataForDownBar.spaceId ? spaces[dataForDownBar.spaceId].spotDataTimp.zone_setup_status : 0}
                            />
                        </div>
                        }
                    </div>
                    <Footer style={{ marginTop: "auto" }} />
                </div>
            </div>
        </div>


    );

}


export default SelectFloor;