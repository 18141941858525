import "./loader.css"
import timIcon from "../../assets/images/timIcon.png"
function Loader() {
  return (
    <div style={{
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.8)',
      zIndex: 10
    }}>
      <span
        style={{
          position: 'absolute',
          left: '50%',
          top: "50%",
          fontSize: 16,
          color: 'white',
          transform: 'translate(-50%, 10%)'
        }}
      ><img src={timIcon} className="loader" alt="Loading" /></span>
    </div>);
}

export default Loader;