
function DropDownPosition({
    width = "100%",
    reset,
    label,
    value = '',
    setValue,
    disabled = false }) {

    function onChange(value) {
        let tempVal = value;
        setValue(tempVal);
    }
    return (
        <div
            style={{
                background: reset ? '#F5F5F5' : '#FFFFFF',
                border: '1px solid #E5E5E5',
                borderRadius: '10px',
                width: '100%',
                height: '25%',
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <label className="set-up-grid-ht-orientation" style={{ display: 'inline-block', marginRight: '15px' }}>{label}
            </label>
            <select
                value={value}
                onChange={onChange}
                disabled={disabled}
                style={{
                    background: reset ? '#F5F5F5' : '#FFFFFF',
                    outline: 'none',
                    border: 'none',
                    color: '#444444'
                }}
            >
                <option style={{ textAlign: 'center' }} value={"inSide"}>Inside</option>
                <option style={{ textAlign: 'center' }} value={"outSide"}>Outside</option>
            </select>
        </div >
    );
}

export default DropDownPosition;