import { useLocation, useNavigate } from "react-router-dom"
import InfoBox from "../../components/infoBox/InfoBox";
import RectangleCardBatTim from "../../components/rectangleCardBatTim/RectangleCardBatTim";
import HeaderSecondary from "../../components/headerSecondary/HeaderSecondary";
import { useEffect, useState } from "react";
import AuthService from "../../Auth/auth";
import Loader from "../../components/Loader/Loader";
import Mod1 from "../../components/mod1/Mod1";
import infoIcon from "../../assets/images/infoIcon.svg"
import alertIcon from "../../assets/images/alert.svg"
import DownBar from "../../components/downBar/DownBarBATTiM";


function AllBatTimDeepak() {
    const location = useLocation();
    const navigate = useNavigate();
    let floorName = location.state.floorDetails[0].name

    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ", location.state)
    const [infoBoxText, setinfoBoxText] = useState({ text: "To Setup all the BAT TIM devices click  on individual device Setup " })
    const [timdevices, setTimDevices] = useState(location.state.parent[0])
    const [timer, setTimer] = useState(90);

    const [showModal, setShowModal] = useState(false)
    const [showTimerMod, setShowTimerMod] = useState(false)

    // const [vacantModalVisible, setVacantModalVisible] = useState(false);
    const [showTimerModonVacant, setShowTimerModonVacant] = useState(false);

    const [cardTimerInfo, setCardTimerInfo] = useState({
        showTimer: false,
        time: "05:00"
    })

    const maxValid = 4;
    const minValid = 1;

    const [downBar, setDownBar] = useState(false);
    const [dataForDownBar, setDataForDownBar] = useState({});

    const [loading, setloading] = useState(false);



    const [calibrateNowButtonClicked, setcalibrateNowButtonClicked] = useState({
        showModal: false,
        powerCycle: false,
        modalValue: false,
        deviceId: null
    })

    const [modalDetails, setmodalDetails] = useState({
        primaryText: "Please ensure the room is vacant.",
        secondaryText: "Click below to calibrate BAT-TIM. Please ensure room is vacant during calibration.",
        icon: alertIcon,
        inputBox: false,
        confirmButtonText: "Confirm Vacant"
    });
    const [powerCycleModal, setPowerCycleModal] = useState({
        primaryText: "Device Power Cycle",
        secondaryText: "Power cycle the device by moving the button on the device.",
        icon: infoIcon,
        inputBox: false,
        confirmButtonText: "Confirm"
    });


    const [modalInputBox, setModalInputBox] = useState(1);
    const [testButtonClicked, setTestButtonClicked] = useState(false)
    const [dataForTest, setDataForTest] = useState({})

    useEffect(() => {
        const updatedTimdevices = async () => {
            setloading(true);
            const key = localStorage.getItem("spaceio-key")
            for (const deviceApiInfo of timdevices.arrayOfDeviceInfo) {
                // console.log(deviceApiInfo);
                let spotData = await AuthService.readDataSPOTNew(key, deviceApiInfo.assignedSpace?.assetId)
                let batTimDeviceStatus = await AuthService.readBTIMStatus(deviceApiInfo.assignedSpace?.device?.hardware?.agentId);

                // if (batTimDeviceStatus.isDeviceDebugModeOn) {
                //     const updateSpotData = {
                //         cal_status: 2,
                //         calibration_status: 0
                //     }
                //     const dataToWrite = [{
                //         "assetId": deviceApiInfo.assignedSpace?.assetId,
                //         "data": {
                //             ...spotData,
                //             ...updateSpotData
                //         }
                //     }]
                //     console.log(dataToWrite);
                //     await AuthService.writeDataToSpot(dataToWrite)
                // }

                if (spotData.setup_status === 1) {

                    if (batTimDeviceStatus.deviceDebugmodeOnTime) {
                        let currentUtcTime = new Date().getTime() / 1000;
                        let givenUtcTime = new Date(batTimDeviceStatus.deviceDebugmodeOnTime).getTime();
                        let timeDifferenceInMinutes = (currentUtcTime - givenUtcTime);
                        console.log(timeDifferenceInMinutes);
                        if (timeDifferenceInMinutes < 300 && (spotData.calibration_status !== 1 || spotData.calibration_status !== 2)) {
                            setTimer(60);
                            console.log("Given UTC time is within 5 minutes from now.");
                            setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: false, powerCycle: true, modalValue: false })
                            // show Vcant mod
                        }
                        else {
                            console.log("Given UTC time is older than 5 minutes from now.");
                            // show pc mod
                        }
                    }
                }
                deviceApiInfo.batTimDeviceStatus = batTimDeviceStatus;
                deviceApiInfo.spotData = spotData;
            }
            setTimDevices({
                ...timdevices,
            })
            setloading(false);
        }

        if (showTimerMod === false) {
            updatedTimdevices()
        }


    }, [showTimerMod])

    // useEffect(() => {
    //     console.log("=====================>", timdevices);
    // }, [timdevices])

    // useEffect(() => {
    //     console.log("=====================>", calibrateNowButtonClicked);
    // }, [calibrateNowButtonClicked])



    //-------------------------------- Need Fuction -------------------------------

    const handleRectangleCardButtonClickFortest = (timData) => {
        console.log("timData", timData)

        setmodalDetails({
            primaryText: "Number of Testers",
            secondaryText: `Please share with how many people you will be running this test.(max ${maxValid})`,
            icon: infoIcon,
            inputBox: true,
            confirmButtonText: "Done"
        })

        setTestButtonClicked(true)
        // console.log("AgentId",timData.assignedSpace?.device?.hardware?.agentId)
        let data = {};
        data["deviceId"] = timData.id;
        data["spaceName"] = timData.assignedSpace?.name;
        data["spaceId"] = timData.assignedSpace?.id;
        data["locationId"] = timData.assignedSpace?.locationId;;
        data["assetId"] = timData.assignedSpace?.assetId;
        data["test"] = modalInputBox
        data["spotData"] = timData.spotData
        data["macId"] = timData.assignedSpace?.device?.hardware?.mac
        data["agentId"] = timData.assignedSpace?.device?.hardware?.agentId
        data["productId"] = timData.assignedSpace?.device?.hardware?.productId;
        data["x_value"] = timData.spotData.x_value;
        data["y_value"] = timData.spotData.y_value;
        // data["status"] = timData.status;
        setDataForTest(data);
    }

    const handleConfirmButtonClickedForTest = async () => {
        if (modalInputBox >= minValid && modalInputBox <= maxValid) {
            setloading(true)
            console.log("dataForTest", dataForTest)
            setDataForTest({ ...dataForTest, test: modalInputBox })
            let agentId = dataForTest.agentId;
            // console.log("agentID", dataForTest.agentId)
            // console.log("modalInputBox", modalInputBox)
            await AuthService.increaseBatTimFrameRate(true, agentId)
            navigate("/live-grid-Bat-Tim",
                { state: { ...location.state, dataForTest: { ...dataForTest, test: modalInputBox } } })
        }
    }

    const handleConfirmButtonClickedForPowerCycle = async () => {

        setShowTimerMod(true);
        setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: false, powerCycle: true, modalValue: false })
        handleRunTimer();
    };

    const sendDebug = async (flage, deviceInfo) => {
        console.log(deviceInfo)
        // let selctedDeviceInfo = calibrateNowButtonClicked.deviceId
        let agentId = deviceInfo.assignedSpace?.device?.hardware?.agentId
        if (!calibrateNowButtonClicked.powerCycle) {
            const isDebugModeSent = await AuthService.sendDebugStatusToBatTim(flage, agentId)
            if (isDebugModeSent === 200) {
                console.log("isDebugModeSent : ", isDebugModeSent);
            }
        }
    }

    const sendCalibration = async (flage, agentId) => {
        console.log(agentId);
        if (calibrateNowButtonClicked.powerCycle) {
            const isForceCalibartionRequestSent = await AuthService.sendCalibration(flage, agentId)
            if (isForceCalibartionRequestSent === 200) {
                console.log("isForceCalibartionRequestSent : ", isForceCalibartionRequestSent);
            }
        }
    }

    const handleConfirmButtonClickedForVacant = (flage) => {
        setShowTimerModonVacant(true);
        let selctedDeviceInfo = calibrateNowButtonClicked.deviceId
        let agentId = selctedDeviceInfo.assignedSpace?.device?.hardware?.agentId
        sendCalibration(true, agentId);
        setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: false, modalValue: false })
        handleRunTimerOnVacantDone();
    }

    const handleRunTimer = () => {
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 0) {
                    clearInterval(countdown);
                    // sendDebug(false, calibrateNowButtonClicked.deviceId);

                    // let selctedDeviceInfo = calibrateNowButtonClicked.deviceId
                    // let agentId = selctedDeviceInfo.assignedSpace?.device?.hardware?.agentId
                    // sendCalibration(false, agentId);
                    getDeviceStatus()
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
    }

    const handleRunTimerOnVacantDone = () => {
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 0) {
                    clearInterval(countdown);
                    getDeviceCalibrationStatus()
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
    }

    const getDeviceStatus = async () => {

        let selctedDeviceInfo = calibrateNowButtonClicked.deviceId
        let selctedDeviceInfoSpotData = selctedDeviceInfo.spotData
        let agentId = selctedDeviceInfo.assignedSpace?.device?.hardware?.agentId
        let batTimStatus = await AuthService.readBTIMStatus(agentId);
        // console.log("batTimStatus", batTimStatus);

        setTimer(60);
        setShowTimerMod(false);
        if (batTimStatus.isDeviceDebugModeOn) { // DEBUG MODE TRUE
            if (batTimStatus.i2cError) {  // I2C ERROR TRUE // DEVICE IN I2C ERROR 
                const updateSpotData = {
                    cal_status: 2,
                    calibration_status: 2
                }
                const dataToWrite = [{
                    "assetId": selctedDeviceInfo.assignedSpace?.assetId,
                    "data": {
                        ...selctedDeviceInfoSpotData,
                        ...updateSpotData
                    }
                }]
                await AuthService.writeDataToSpot(dataToWrite);
                window.location.reload();
            }
            else {

                // DEVICE IN DEBUG MODE WITH NO I2C ERROR AND WATING TO BE CALIBRATED 
                const updateSpotData = {
                    cal_status: 2,
                    calibration_status: 0
                }
                const dataToWrite = [{
                    "assetId": selctedDeviceInfo.assignedSpace?.assetId,
                    "data": {
                        ...selctedDeviceInfoSpotData,
                        ...updateSpotData
                    }
                }]
                console.log(dataToWrite);
                await AuthService.writeDataToSpot(dataToWrite)

                // setVacantModalVisible(true);
            }
        }
        else { // DEVICE MAY BE IN OFFICE LINE MODE 
            const updateSpotData = {
                cal_status: 2,
                calibration_status: 1
            }
            const dataToWrite = [{
                "assetId": selctedDeviceInfo.assignedSpace?.assetId,
                "data": {
                    ...selctedDeviceInfoSpotData,
                    ...updateSpotData
                }
            }]
            console.log(dataToWrite);
            await AuthService.writeDataToSpot(dataToWrite)

        }
        window.location.reload();

    }

    const getDeviceCalibrationStatus = async () => {
        let selctedDeviceInfo = calibrateNowButtonClicked.deviceId
        let selctedDeviceInfoSpotData = selctedDeviceInfo.spotData
        let agentId = selctedDeviceInfo.assignedSpace?.device?.hardware?.agentId
        let batTimStatus = await AuthService.readBTIMStatus(agentId);

        let PIRStaus = batTimStatus.pirStatus



        console.log("batTimStatus", batTimStatus);

        setTimer(90);
        setShowTimerModonVacant(false);

        if (PIRStaus) {
            setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: false, modalValue: true, deviceId: calibrateNowButtonClicked.deviceId })
            return
        }


        if (!batTimStatus.isDeviceDebugModeOn) {
            // DEBUG MODE IS NOT TRUE
            if (batTimStatus.i2cError === false) {
                //  I2C ERROR FALSE
                if (batTimStatus.isDeviceForceCalibrationFlag) {
                    // FORCECALIBRATION TRUE 
                    // FORCECALIBRATION DONE
                    const updateSpotData = {
                        cal_status: 3,
                        calibration_status: 3
                    }
                    const dataToWrite = [{
                        "assetId": selctedDeviceInfo.assignedSpace?.assetId,
                        "data": {
                            ...selctedDeviceInfoSpotData,
                            ...updateSpotData
                        }
                    }]
                    console.log(dataToWrite);
                    await AuthService.writeDataToSpot(dataToWrite);
                    window.location.reload();
                }
            }
            else { // I2C ERROR TRUE
                console.log("// I2C ERROR TRUE // DEVICE IN I2C ERROR ")
                const updateSpotData = {
                    cal_status: 2,
                    calibration_status: 2
                }
                const dataToWrite = [{
                    "assetId": selctedDeviceInfo.assignedSpace?.assetId,
                    "data": {
                        ...selctedDeviceInfoSpotData,
                        ...updateSpotData
                    }
                }]
                await AuthService.writeDataToSpot(dataToWrite);
                window.location.reload();
            }
        }
        else {
            // DEVICE MAY BE IN OFFLINE LINE MODE 
            const updateSpotData = {
                cal_status: 2,
                calibration_status: 1
            }
            const dataToWrite = [{
                "assetId": selctedDeviceInfo.assignedSpace?.assetId,
                "data": {
                    ...selctedDeviceInfoSpotData,
                    ...updateSpotData
                }
            }]
            console.log(dataToWrite);
            await AuthService.writeDataToSpot(dataToWrite)
            window.location.reload();
        }
    }

    const setPowerCycleModalAfterCheck = (value) => {

        let val = parseInt(value)
        //console.log(val)
        setModalInputBox(val)
        setTimeout(function () {
            if (val < minValid) setModalInputBox(1)
            else if (val > maxValid) setModalInputBox(maxValid)
        }, 3000)
    }

    const handleCancelButtonClicked = () => {
        setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: false, modalValue: false })
    }

    const onSetupButtonClick = (timData) => {
        console.log(timData);
        let data = {}
        data["batteryVoltage"] = timData.batteryVoltage;
        data["markerId"] = timData.assignedSpace?.category?.id;
        data["lastUpdated"] = timData.lastUpdated;
        data["deviceId"] = addDashes(timData.assignedSpace?.device.id, [4, 3, 3])
        data["spaceName"] = timData.assignedSpace?.name;
        data["spaceId"] = timData.assignedSpace?.id;
        data["locationId"] = timData.assignedSpace?.locationId;
        data["assetId"] = timData.assignedSpace?.assetId;
        data["productId"] = timData.assignedSpace?.device?.hardware?.productId;
        data["x_value"] = timData.spotData.x_value;
        data["y_value"] = timData.spotData.y_value;
        // data["status"] = timData.status;
        console.log(data);

        navigate("/gridBatTim",
            { state: { ...location.state, "dataForDownBar": data, } })
        // setDataForDownBar(data)
        // console.log("Card clicked", dataForDownBar.assetId);
    }

    const handleCalibrateButtonClicked = (deviceId) => {
        if (calibrateNowButtonClicked.powerCycle) {
            setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: false, modalValue: true, deviceId: deviceId })
        } else {
            setcalibrateNowButtonClicked({ ...calibrateNowButtonClicked, showModal: true, modalValue: false, deviceId: deviceId })
        }
    }

    const onCardClick = (deviceId) => {
        if (deviceId) {
            deviceId['deviceID'] = addDashes(deviceId.assignedSpace.device.blessId, [4, 3, 3]);
            setDataForDownBar(deviceId)
            setDownBar(!downBar)
        }
    }

    // const handleConfirmButtonClickedForTest = async () => {
    //     if (modalInputBox >= minValid && modalInputBox <= maxValid) {
    //         setloading(true)
    //         setDataForTest({ ...dataForTest, test: modalInputBox })
    //         //console.log("agentID", dataForTest.agentId)
    //         await AuthService.increaseFrameRate(dataForTest.agentId)
    //         navigate("/live-grid",
    //             { state: { ...location.state, dataForTest: { ...dataForTest, test: modalInputBox } } })
    //     }
    // }

    const onResetClick = async (deviceId) => {
        console.log(deviceId);
        setloading(true)
        setmodalDetails()
        let utcTimestamp = new Date().getTime()
        const resetData = {
            "setup_status": 0,
            "cal_status": 0,
            "calibration_status": 0,
            "instalation_status": 0,
            "utcEpoch": utcTimestamp,
            "test_status": [
                0,
                0,
                0,
                0,
                0
            ],
            "people_count": [
                0,
                0,
                0,
                0
            ]
        }

        let dataToWrite = [{
            "assetId": deviceId.assignedSpace?.assetId,
            "data": {
                ...deviceId.assignedSpace?.spotData,
                ...resetData
            }
        }]

        console.log('DATA TO WRITE', dataToWrite[0])

        // let dataObject = timdevices[deviceId]
        // dataObject["status"] = { status: noSetup, background: "#B1B1B1" }
        // let spacesDummy = { ...timdevices }
        // spacesDummy[deviceId] = dataObject
        // setSpaces(spacesDummy)

        await AuthService.writeDataToSpot(dataToWrite)
        setloading(false)
        window.location.reload();
        // setloading(false);
    }

    const addDashes = (deviceId, dashPosition) => {
        deviceId = deviceId.toString()
        return deviceId.slice(0, dashPosition[0]) + "-" + deviceId.slice(dashPosition[0], dashPosition[0] + dashPosition[1]) + "-" + deviceId.slice(dashPosition[0] + dashPosition[1], dashPosition[0] + dashPosition[1] + dashPosition[2])
    }

    const goBack = () => navigate(
        {
            pathname: "/selectBatTimfloor",
            search: `?floor=${location.state.floorDetails[0].id}&name=${floorName}`,
            state: { ...location.state }
        }
    )

    return (
        <div className="container-fluid g-0">
            <div className="row justify-content-center g-0 mainDiv">
                <div className="col-sm-12 col-lg-8 position-relative" style={{ background: "#F5F5F5" }}>
                    <div className="row g-0" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                        {loading && <Loader />}
                        <div className="col-11 mx-auto">
                            <HeaderSecondary tabInd={downBar || testButtonClicked}
                                goBack={goBack}
                                primaryText={location.state.parent[0].name}
                                secondaryText={[location.state.floorDetails[0].name, "   ", location.state.floorDetails[0].anchorAddress]}

                            />
                        </div>
                        <div className="col-11 mx-auto my-4">
                            {/* <InfoBox text={calibrate ? "Set-up for all the TiMs in this room is done now. To calibrate ensure that the room is vacant." : "Set-up all the TIM devices in each room to start calibration."} /> */}
                            <InfoBox text={infoBoxText.text} />
                        </div>

                        <div className="col-11 mx-auto">
                            {
                                timdevices.arrayOfDeviceInfo.map((deviceInfo, index) => (
                                    <>
                                        {console.log("deviceInfo", timdevices)}
                                        {deviceInfo.spotData ?
                                            <RectangleCardBatTim
                                                key={index}
                                                deviceName={deviceInfo.assignedSpace?.name}
                                                deviceId={addDashes(deviceInfo.assignedSpace?.device.blessId, [4, 3, 3])}
                                                markerId={deviceInfo.assignedSpace?.serial}
                                                productId={deviceInfo.assignedSpace?.device?.hardware?.productId}
                                                capacity={deviceInfo.assignedSpace?.capacity ? deviceInfo.assignedSpace?.capacity : "000"}

                                                setUpStatus={deviceInfo.spotData.setup_status}
                                                calSetUp={deviceInfo.spotData.cal_status}
                                                calibrationStatus={deviceInfo.spotData.calibration_status}
                                                instalationStatus={deviceInfo.spotData.instalation_status}

                                                timerInfo={deviceInfo.batTimDeviceStatus}
                                                showTimerMod={showTimerMod}
                                                calibrationTextforButton={calibrateNowButtonClicked.powerCycle ? "Calibrate" : "Reboot"}

                                                onClickCard={() => {
                                                    console.log("Card Click")
                                                    onCardClick(deviceInfo, true)
                                                }}
                                                onSetupButtonClick={() => {
                                                    console.log("onSetupButtonClick")
                                                    onSetupButtonClick(deviceInfo);
                                                }}
                                                onCalibrateButtonClick={() => {
                                                    // console.log("onCalibrateButtonClick")
                                                    sendDebug(true, deviceInfo)
                                                    handleCalibrateButtonClicked(deviceInfo);
                                                }}
                                                onTestButtonClick={() => {
                                                    console.log("onTestButtonClick")
                                                    handleRectangleCardButtonClickFortest(deviceInfo);
                                                }}
                                                onResetButtonClick={() => {
                                                    console.log("onResetButtonClick")
                                                    setmodalDetails({
                                                        ...modalDetails, primaryText: "Are you sure?",
                                                        secondaryText: "Reset will change the status of all the TiM devices to Set-up.",
                                                        icon: alertIcon,
                                                        confirmButtonText: "Confirm",
                                                        confirmOnClick: deviceInfo
                                                    }); setShowModal(true)
                                                }}
                                            />
                                            : null
                                        }
                                    </>
                                ))
                            }
                        </div>

                        {showModal ? (
                            <div className='col-10 col-sm-6 col-lg-6 mx-auto '>
                                <Mod1
                                    text={{
                                        primaryText: modalDetails.primaryText,
                                        secondaryText: modalDetails.secondaryText,
                                        icon: modalDetails.icon
                                    }}
                                    closeModal={() => setShowModal(false)}
                                    confirmButton={{
                                        text: modalDetails.confirmButtonText,
                                        onClick: () => {
                                            onResetClick(modalDetails.confirmOnClick)
                                            setShowModal(false)
                                        }
                                    }}
                                />
                            </div>
                        ) : null
                        }
                        {calibrateNowButtonClicked.showModal || calibrateNowButtonClicked.modalValue ? (
                            <>
                                {calibrateNowButtonClicked.showModal ? (
                                    <Mod1
                                        text={{
                                            primaryText: powerCycleModal.primaryText,
                                            secondaryText: powerCycleModal.secondaryText,
                                            icon: powerCycleModal.icon
                                        }}
                                        closeModal={testButtonClicked ? () => setTestButtonClicked(false) : handleCancelButtonClicked}
                                        confirmButton={{
                                            text: powerCycleModal.confirmButtonText,
                                            onClick: handleConfirmButtonClickedForPowerCycle
                                        }}
                                        inputBox={powerCycleModal.inputBox}
                                        value={powerCycleModal.inputBox ? modalInputBox : null}
                                        setValue={powerCycleModal.inputBox ? setPowerCycleModalAfterCheck : null}
                                        tabInd={downBar}
                                    />
                                ) : null
                                }
                                {calibrateNowButtonClicked.modalValue ? (
                                    <Mod1
                                        text={{
                                            primaryText: modalDetails.primaryText,
                                            secondaryText: modalDetails.secondaryText,
                                            icon: modalDetails.icon
                                        }}
                                        closeModal={testButtonClicked ? () => setTestButtonClicked(false) : handleCancelButtonClicked}
                                        confirmButton={{
                                            text: modalDetails.confirmButtonText,
                                            onClick: handleConfirmButtonClickedForVacant
                                        }}
                                        inputBox={modalDetails.inputBox}
                                        value={modalDetails.inputBox ? modalInputBox : null}
                                        setValue={modalDetails.inputBox ? setPowerCycleModalAfterCheck : null}
                                        tabInd={downBar}
                                    />
                                ) : null
                                }
                            </>
                        ) : null
                        }

                        {showTimerMod ?
                            <Mod1
                                text={{
                                    primaryText: "Device now will switch to debug mode.",
                                    secondaryText: `Please wait for ${timer} seconds... `,
                                    icon: infoIcon
                                }}
                            />
                            : null
                        }
                        {showTimerModonVacant ?
                            <Mod1
                                text={{
                                    primaryText: "Device is doing force calibration.",
                                    secondaryText: `Please wait for ${timer} seconds... `,
                                    icon: infoIcon
                                }}
                            />
                            : null
                        }

                        {testButtonClicked ?
                            <>
                                <Mod1
                                    text={{
                                        primaryText: modalDetails.primaryText,
                                        secondaryText: modalDetails.secondaryText,
                                        icon: modalDetails.icon
                                    }}
                                    closeModal={testButtonClicked ? () => setTestButtonClicked(false) : handleCancelButtonClicked}
                                    confirmButton={{
                                        text: modalDetails.confirmButtonText,
                                        onClick: handleConfirmButtonClickedForTest
                                    }}
                                    inputBox={modalDetails.inputBox}
                                    value={modalDetails.inputBox ? modalInputBox : null}
                                    setValue={modalDetails.inputBox ? setPowerCycleModalAfterCheck : null}
                                    tabInd={downBar}
                                />
                            </>
                            :
                            <>
                            </>
                        }
                        <div className="col-12 mx-auto">
                            <DownBar
                                deviceData={dataForDownBar}
                                className={downBar ? "downbar-card" : "downbar-card hide"}
                                closeDownBar={() => { setDownBar(!downBar) }}
                                onClick={() => onSetupButtonClick(dataForDownBar)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllBatTimDeepak;
