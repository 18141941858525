import { useState } from 'react';
import ButtonViewTest from "./ButtonViewTest"
import iangle from "../assets/images/angle.svg"
import ileftright from "../assets/images/leftright.svg"
import itopbottom from "../assets/images/topbottom.svg"
import Slider from "../components/slider/Slider";
import { useLocation, useNavigate } from "react-router-dom"



function BottomGrid2(props) {
	//console.log('Bottom2', props);
	let { isDesktopOrLaptop } = props;
	let [topBottom, setTopBottom] = useState("");
	let [leftRight, setLeftRight] = useState("");
	let [angle, setAngle] = useState("angle");
	let [range, setRange] = useState(props.data.timResult.angle);
	let [left, setLeft] = useState(parseFloat(props.data.timResult.timTempPosition.left).toFixed(2));
	let [top, setTop] = useState(parseFloat(props.data.timResult.timTempPosition.top).toFixed(2));

	let [_range,] = useState(props.data.timResult.angle);
	let [_left,] = useState(parseFloat(props.data.timResult.timTempPosition.left));
	let [_top,] = useState(parseFloat(props.data.timResult.timTempPosition.top));

	const location = useLocation();
	const navigate = useNavigate();
	function whichOne() {
		return (angle || topBottom || leftRight)
	}
	function leftRightMarks(max) {
		let temp = [];
		let stepp = +Math.ceil(parseInt(max * 0.05));
		stepp = parseInt(stepp / 10) * 10;
		let step = isDesktopOrLaptop ? stepp : (stepp * 2);

		for (let i = 0; i <= max; i += step) {
			let tempData = {
				value: i,
				label: (i / props.data.scalingFactor).toFixed(2)
			}
			temp.push(tempData);
		}
		// temp.push({
		// 	value: max,
		// 	label: max
		// });
		//console.log(temp)
		return temp;
	}
	function angleMarks() {
		let temp = [];
		let step = isDesktopOrLaptop ? 20 : 30;
		for (let i = 0; i <= 360; i += step) {
			let tempData = {
				value: i,
				label: i + '°'
			}
			temp.push(tempData);
		}
		return temp;
	}
	function reset() {
		setRange(_range);
		setLeft(parseFloat(_left));
		setTop(parseFloat(_top));
		props.callback();
	}

	return (
		<div style={{ textAlign: 'center' }}>
			<div style={{ marginBottom: '2%', display: 'flex', justifyContent: 'center' }}>
				<span onClick={() => { setAngle(""); setLeftRight("leftright"); setTopBottom("") }}><img alt="" src={ileftright} className={(angle || topBottom || leftRight) === "leftright" ? 'rolar' : ''} style={{}} /></span>
				<span onClick={() => { setAngle("angle"); setLeftRight(""); setTopBottom("") }}><img alt="" src={iangle} className={(angle || topBottom || leftRight) === "angle" ? 'rolar' : ''} style={{ margin: "0px 25px" }} /></span>
				<span onClick={() => { setAngle(""); setLeftRight(""); setTopBottom("topbottom") }}><img alt="" src={itopbottom} className={(angle || topBottom || leftRight) === "topbottom" ? 'rolar' : ''} style={{}} /></span>
			</div>
			<br />
			<div>
				{
					whichOne() === "angle" &&
					<Slider marks={angleMarks()} min={0} max={360} value={range} step={5} onChange={(event, value) => { props.data.timResult.angle = +value; setRange(+value); props.changeOrt(value); props.timChange(props.data) }} scalingFactor={props.data.scalingFactor} style={{ width: props.data.style.width }} />
					// <input type="range" id="volume" min="0" max="360" value={range} step="1" onChange={(event) => { props.data.timResult.angle = +event.target.value; setRange(+event.target.value); props.changeOrt(+event.target.value); props.timChange(props.data) }} style={{ width: props.data.style.width }} />
				}
				{

					whichOne() === "leftright" &&
					<Slider scalingFactor={props.data.scalingFactor} marks={leftRightMarks(props.data.originalWidth)} min={0} max={props.data.originalWidth} value={left} step={1} onChange={(event, value) => { props.data.timResult.timTempPosition.left = value + ''; setLeft(+value); props.changeX(value); props.timChange(props.data) }} style={{ width: props.data.style.width, accentColor: 'green' }} />
				}
				{
					whichOne() === "topbottom" &&
					<Slider scalingFactor={props.data.scalingFactor} marks={leftRightMarks(props.data.originalHeight)} min={0} max={props.data.originalHeight} value={top} step={1} onChange={(event, value) => { props.data.timResult.timTempPosition.top = +value + ''; setTop(+value); props.changeY(value); props.timChange(props.data) }} style={{ width: props.data.style.width, accentColor: 'red' }} />
				}
				<p style={{fontSize:"10px", color:"#757575"}}>Scale in meters</p>  
				<div style={{ marginBottom: '10px' }}>
				</div>
			</div>
			<div style={{ background: "transparent", display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '30px' }}>
				<ButtonViewTest text="Reset" borderRadius="7px" width="100px" borderColor="#ff9170" background="white" color="#ff9170" onClick={reset} />
				<ButtonViewTest text="Done" borderRadius="7px" width="100px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white" onClick={() => props.changeEdit()} />
			</div>
		</div>
	);
}

export default BottomGrid2;