import "./selectBatTim-floor.css"
import AuthService from "../../Auth/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import InfoBox from "../../components/infoBox/InfoBox";
import Select1 from "../../components/Select1";
import SquareCard from "../../components/squareCard/squareCard";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader/Loader";
import backArrow from "../../assets/images/backArrow.svg"

function SelectBatTimFloor({ image, productId }) {

    if (!image && !productId) {
        productId = window.localStorage.getItem('productId');
        image = window.localStorage.getItem('productSvg');
    }

    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();
    const [loading, setloading] = useState(false);
    const [floor, setFloor] = useState(null);
    const [floorDetails, setFloorDetails] = useState();
    const [selectedFloor, setSelectedFloor] = useState(null || search.get("floor"));
    const [label, setLabel] = useState(null || search.get("name"));

    const [info, setInfo] = useState("Select the floor  for setting up the BAT TIM devices.");
    const [spaces, setSpaces] = useState(null);
    const [rawData, setRawData] = useState({});
    const [parentChildSpaces, setParentChildSpaces] = useState(null);
    const [authenticate, setAuthenticate] = useState(false);

    useEffect(() => {
        async function fetchRoom() {
            if (floor) {
                let a = floor.map((val, ind) => (val.value));
                let key = window.localStorage.getItem('spaceio-key');
                let myFloorName = null
                await floor.map((i, index) => {
                    if (i.value == selectedFloor) {
                        myFloorName = i.label
                    }
                })
                setSearch({ floor: selectedFloor, name: myFloorName })

                if (key !== null && a.includes(parseInt(selectedFloor))) {
                    setloading(true)
                    // const spaces = await AuthService.getBatTimPlusRooms(key, selectedFloor);   
                    const spaces = await AuthService.getDeviceFromFloorAndProductID(key, selectedFloor, productId); 
                    // console.log("spaces ===> ", spaces);

                    getCard(spaces)
                    setSearch({ floor: selectedFloor })
                }
            }
        }
        fetchRoom();
    }, [selectedFloor, floor])

    useEffect(() => {
        async function getFloorIdAndName() {
            let key = window.localStorage.getItem('spaceio-key');
            try {
                let getFloorIdName = await AuthService.getFloorIdAndNameUsingProductId(key, productId);
                // console.log("getFloorIdName", getFloorIdName);
                let opts = getFloorIdName.map(floorData => ({ value: floorData.id, label: floorData.name }));
                // console.log("opts", opts);
                setAuthenticate(true);
                setFloor(opts);
                setloading(false);
            }
            catch (e) {
                setloading(false);
                setAuthenticate(false)
            }
        }
        setloading(true);
        getFloorIdAndName();
    }, [])

    useEffect(() => {
        if (selectedFloor) {
            setInfo("Select the room to setup the devices.")
        }
    }, [selectedFloor])

    const onCardClick = (deviceId) => {
        // console.log("ARRAY LIST", spaces[deviceId], floorDetails)
        navigate({ pathname: "/AllBatTIMEasy" },
            { state: parentChildSpaces[deviceId] ? { "parent": Array(spaces[deviceId]), "child": parentChildSpaces[deviceId], "floorDetails": floorDetails, "rawData": rawData } : { "parent": Array(spaces[deviceId]), "floorDetails": floorDetails, "rawData": rawData } }
            // { state: { "parent": Array(spaces[deviceId]), "floorDetails": floorDetails, "rawData": rawData } }
        )
    }

    const getCard = async (space) => {
        let key = window.localStorage.getItem('spaceio-key');
        let BTIMSpace = {}
        let BTIMparentChildSpaces = {}


        const rawDevice = await AuthService.getRawDevices(key, selectedFloor)
        // console.log("rawDevice ========== > ", rawDevice);

        const spaceDetails = await AuthService.getFloorDetails(key, selectedFloor);
        setFloorDetails(spaceDetails)

        for (const [index, data] of space.entries()) {
            let key = data.assignedSpace || {}


            let myDevice = key.device.id
            let rawDeviceHardware = null;
            for (let i = 0; i < rawDevice.length; i++) {
                if (rawDevice[i].id == myDevice) {
                    rawDeviceHardware = rawDevice[i].hardware
                }
            }

            if (rawDeviceHardware) {
                key.device.hardware.mac = rawDeviceHardware.deviceId;
                key.device.hardware.agentId = rawDeviceHardware.agentId;
            }


            if (key.parentId) {
                if (BTIMparentChildSpaces[key.parentId]) {
                    BTIMparentChildSpaces[key.parentId].push(data)
                }
                else {
                    BTIMparentChildSpaces[key.parentId] = Array(data)
                }
            }
            else {
                BTIMSpace[key.id] = {
                    status: "will be set later",
                    deviceApi: data,
                    assetId: key.assetId,
                    name: key.name,
                    arrayOfDeviceInfo: [data],
                }
            }
        }
        // console.log("BTIMparentChildSpaces-------",BTIMparentChildSpaces);

        if (Object.keys(BTIMparentChildSpaces).length !== 0) {
            // console.log("@@@@@@@",key);
            if (key !== null) {
                const spaceIds = Object.keys(BTIMparentChildSpaces); // Extract space IDs from the parentChildSpaces object

                const spaceApi = await AuthService.getFloorSpaces(key, selectedFloor,spaceIds);
                // console.log("@@@@@@@@@", spaceApi);
                for (const item in BTIMparentChildSpaces) {
                    // console.log("-------------------------------", BTIMparentChildSpaces)
                    let listOFchildAssetId = [];
                    for (const [index, key] of spaceApi.entries()) {
                        // do not chnage with === as it is not working for parent devices 
                        // keep == only 
                        // eslint-disable-next-line eqeqeq
                        if (key.id == item) {
                            for (const child of BTIMparentChildSpaces[item]) {
                                listOFchildAssetId.push(child);
                            }
                            // console.log(listOFchildAssetId);
                            BTIMSpace[key.id] = {
                                status: "will be set later",
                                deviceApi: key,
                                assetId: key.assetId,
                                name: key.name,
                                arrayOfDeviceInfo: listOFchildAssetId,
                            }
                        }
                    }
                }
            }
        }

        // console.log("BTIMSpace ==> ", BTIMSpace)
        // console.log("BTIMparentChildSpaces ==> ", BTIMparentChildSpaces)
        setSpaces(BTIMSpace)
        setParentChildSpaces(BTIMparentChildSpaces)
        setloading(false)
    }

    const goBack = () => {
        navigate({ pathname: "/selectProduct" })
    }

    return (
        <div className="container-fluid g-0">
            <div className="row justify-content-center g-0 mainDiv">
                <div className="col-lg-8" style={{ background: "#f5f5f5", display: "flex", flexDirection: "column" }}>
                    <div className="row g-0">
                        {loading && <Loader />}
                        <div className="col-11 mx-auto my-4 icon-logout-product">
                            <button style={{ border: "none", backgroundColor: "#f5f5f5" }} onClick={goBack} >
                                <img src={backArrow} alt="Back"></img>
                            </button>
                            <img src={image} alt="headIcon" height="33px" ></img>

                        </div>
                        <div className="col-11 my-4 mx-auto">
                            <InfoBox text={info} />
                            <label className="label-class mt-4">Select a floor</label>
                            <Select1
                                defaultValue={selectedFloor === null ? "" : { label: label, value: selectedFloor }}
                                placeholder={'Select'}
                                options={floor !== null ? [...floor].sort((a, b) => {
                                    const labelA = a.label.toLowerCase();
                                    const labelB = b.label.toLowerCase();

                                    const isANumber = /^\d+$/.test(labelA);
                                    const isBNumber = /^\d+$/.test(labelB);

                                    if (isANumber && isBNumber) {
                                        // If both labels are numbers
                                        return Number(labelA) - Number(labelB);
                                    } else if (!isANumber && !isBNumber) {
                                        // If both labels are not numbers
                                        const ordinalA = labelA.match(/(\d+)(st|nd|rd|th)/);
                                        const ordinalB = labelB.match(/(\d+)(st|nd|rd|th)/);

                                        if (ordinalA && ordinalB) {
                                            // If both labels represent ordinal numbers
                                            const numberA = Number(ordinalA[1]);
                                            const numberB = Number(ordinalB[1]);

                                            if (numberA === numberB) {
                                                // If the numbers are equal, compare the ordinal suffixes
                                                return ordinalA[2].localeCompare(ordinalB[2]);
                                            } else {
                                                // If the numbers are different, compare numerically
                                                return numberA - numberB;
                                            }
                                        } else if (ordinalA) {
                                            // If only labelA represents an ordinal number, it comes before labelB
                                            return -1;
                                        } else if (ordinalB) {
                                            // If only labelB represents an ordinal number, it comes after labelA
                                            return 1;
                                        } else {
                                            // If neither label represents an ordinal number, compare alphabetically
                                            return labelA.localeCompare(labelB);
                                        }
                                    } else if (isANumber) {
                                        // If only labelA is a number, it comes before labelB
                                        return -1;
                                    } else {
                                        // If only labelB is a number, it comes after labelA
                                        return 1;
                                    }
                                }) : []}
                                onChange={setSelectedFloor} />
                        </div>
                        {spaces &&
                            <div className="col-11 mx-auto">
                                <label className="label-class">Select a room to proceed</label>
                                <div className=" select-floor-squareCardDiv">
                                    {Object.keys(spaces).map((deviceId, index) => {
                                        return (
                                            <SquareCard
                                                key={index}
                                                name={spaces[deviceId].name}
                                                capacity={
                                                    spaces[deviceId].deviceApi.assignedSpace ?
                                                        spaces[deviceId].deviceApi.assignedSpace?.capacity :
                                                        spaces[deviceId].deviceApi.capacity
                                                }
                                                onClick={() => { onCardClick(deviceId) }}
                                            />)
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                    <Footer style={{ marginTop: "auto" }} />
                </div>
            </div>
        </div>
    );

}

export default SelectBatTimFloor;