import React, { useEffect, useState } from 'react';
import './countDown.css';

function CountDown({ startTime, keepTheTimerOnFor, callBackOnTimerEnd }) {
    const [showTimer, setShowTimer] = useState(false);
    const [countdown, setCountdown] = useState(null);

    const [isCallBackFunctionTrigger, setisCallBackFunctionTrigger] = useState(true)
    // console.log(startTime, keepTheTimerOnFor, callBackOnTimerEnd)

    useEffect(() => {
        const currentUtcTime = Math.floor(new Date().getTime() / 1000);
        const givenUtcTime = Math.floor(new Date(startTime).getTime());
        const timeDifferenceInSeconds = currentUtcTime - givenUtcTime;
        console.log(timeDifferenceInSeconds);
        if (timeDifferenceInSeconds < keepTheTimerOnFor) {
            setShowTimer(true);
            const remainingTime = keepTheTimerOnFor - timeDifferenceInSeconds;
            setCountdown(formatTime(remainingTime));
            handleRunTimer(remainingTime);
        }
        else {

        }
        
    }, [startTime]);

    const handleRunTimer = (remainingTime) => {
        const countdownInterval = setInterval(() => {
            remainingTime -= 1;

            if (remainingTime <= 0) {
                clearInterval(countdownInterval);
                setCountdown('00:00');
                setShowTimer(false);
            } else {
                if (callBackOnTimerEnd && typeof callBackOnTimerEnd === 'function') {
                    if (remainingTime <= 180 && isCallBackFunctionTrigger) {
                        // Remaining time is 3 minutes
                        callBackOnTimerEnd()
                        //console.log("remainingTime",remainingTime)
                        setisCallBackFunctionTrigger(false)
                        //console.log("remainingTime",isCallBackFunctionTrigger)
                    }
                }
                setCountdown(formatTime(remainingTime));

            }
        }, 1000);
    };

    const formatTime = (timeInSeconds) => { // 00:00
        const minutes = Math.floor(timeInSeconds / 60)
            .toString()
            .padStart(2, '0');
        const seconds = (timeInSeconds % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    return (
        <>
            {showTimer ? (
                <span className="count-down" style={{ background: '#808080' }}>
                    {countdown}
                </span>
            ) : null}
        </>
    );
}

export default CountDown;