import { useState, useEffect, useRef } from "react";
import "./rectangleCardBatTim.css"
import capacityIcon from "../../assets/images/capacity.svg"
import CountDown from "../countDown/countDown"


function RectangleCardBatTim({
    deviceName,
    deviceId,
    markerId = "000",
    capacity = "000",
    productId,

    setUpStatus,
    calSetUp,
    calibrationStatus,
    instalationStatus,

    timerInfo,
    showTimerMod,
    calibrationTextforButton,

    onClickCard,
    onSetupButtonClick,
    onCalibrateButtonClick,
    onTestButtonClick,
    onResetButtonClick,
    tabInd,
}) {

    // console.log("SPOT STATUS", [setUpStatus, calSetUp, calibrationStatus, instalationStatus,])

    const [calibrationStatusText, setcalibrationStatusText] = useState({});

    const ref = useRef();


    useEffect(() => {

        function checkStatus() {

            if (instalationStatus > 0) {
                if (instalationStatus === 1) {
                    setcalibrationStatusText({ text: "Test Passed", background: "#29CF81", type: "span" })
                }
                else if (instalationStatus === 2) {
                    setcalibrationStatusText({ text: "Partial Passed", background: "#29CF81", type: "span" })
                }
                else if (instalationStatus === 3) {
                    setcalibrationStatusText({ text: "Test Failed", background: "#F65162", type: "span" })
                }
                else {
                    setcalibrationStatusText({ text: "Test Error", background: "#F65162", type: "span" })
                }
            }
            else {
                if (setUpStatus === 0) {
                    setcalibrationStatusText({ text: "Set-up", background: "transparent", type: "button" })
                }
                else if (setUpStatus === 1 && calSetUp === 2 && calibrationStatus === 0) {
                    setcalibrationStatusText({ text: "Set-up done", background: "#29CF81", type: "span" })
                }

                else if (setUpStatus === 1 && calSetUp === 2 && calibrationStatus === 1) {
                    setcalibrationStatusText({ text: "Tim Offline", background: "#BBBBBB", type: "span" })
                }
                else if (setUpStatus === 1 && calSetUp === 2 && calibrationStatus === 2) {
                    setcalibrationStatusText({ text: "I2C Error", background: "#F65162", type: "span" })
                }
                else if (setUpStatus === 1 && calSetUp === 3 && calibrationStatus === 3) {
                    setcalibrationStatusText({ text: "Calibration Done", background: "#29CF81", type: "span" })
                }
                else setcalibrationStatusText({ text: "Error", background: "#F65162", type: "span" })
            }

            // console.log(" SPOT useEffect  ===== > ", [setUpStatus, calSetUp, calibrationStatus, instalationStatus, showTimerMod])
        }
        checkStatus()
    }, [setUpStatus, calSetUp, calibrationStatus, instalationStatus, showTimerMod])


    useEffect(() => {
        if (ref.current && tabInd) ref.current.blur();
    }, [tabInd])

    function productTypeFromProductID(productId) {
        switch (productId) {
          case "6875f0bf-157d-332e-883f-70e58401dd14":
            return "TIM";
          case "62d3da73-debf-8660-5fb4-f5d6c646358f":
            return "TIM+";
          case "2d5ef6ed-051c-0bb9-70a4-718d31215e0e":
            return "FLO";
          case "cb1a34bf-e4ea-a74b-d496-37c1183ffa97":
            return "BAT-TIM";
          case "4b357a17-cc2d-5d63-116f-a539a78a2802":
            return "POE TIM+";
          case "6a36e934-426c-025f-a025-89bed058974d":
            return "POE TIM";
          case "85939db1-afba-b79e-b424-2e295ec2a9b5":
            return "POE FLO";
          default:
            return "--"; // Default value when deviceId doesn't match any case
        }
      }

    // useEffect(() => {

    // }, [timerInfo.deviceDebugmodeOnTime])

    // useEffect(() => {

    //     // console.log(calibrationStatusText.text !== "Test Passed")
    // }, [])


    return (
        <>
            {/* {console.log(calibrationStatusText.text)} */}
            <div className="rectangle-card-timP-body p-3 my-3" onClick={onClickCard} style={{ cursor: "pointer" }}>
                <div className="rectangle-card-timP-body-top">
                    <span className="rectangle-card-timP-body-top-content1">{deviceName}</span>
                    <div>
                        {calibrationStatusText.type === "button"
                            ?
                            <button ref={ref} tabIndex={tabInd ? -1 : 0} className="rectangle-card-timP-body-top-content2 buttonStandard2" onClick={onSetupButtonClick}>
                                {calibrationStatusText.text}
                            </button>
                            :
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
                                {calibrationStatusText.text === "Set-up done" ?
                                    <>
                                        {/* {console.log(calibrationStatusText.text)} */}
                                        <CountDown
                                            startTime={timerInfo.deviceDebugmodeOnTime}
                                            keepTheTimerOnFor={300}
                                        />
                                    </>
                                    : null
                                }
                                <span className="rectangle-card-timP-body-top-content1-setUp-done" style={{ background: calibrationStatusText.background }}>
                                    <>
                                        {calibrationStatusText.text}
                                    </>
                                </span>
                            </div>
                        }
                        {/* {retest ? <button onClick={retest} ref={ref} tabIndex={tabInd ? -1 : 0} className="rectangle-card-status-for-space-reset-button" style={{ marginLeft: "0.5rem" }}>Retest</button> : null} */}
                    </div>
                </div>
                <div className="rectangle-card-timP-body-bottom">
                    <span style={{fontWeight:"700"}}>Product Type: {productTypeFromProductID(productId)}</span>
                    <span>Device ID:   {deviceId}</span>
                    <span>Marker ID:   {markerId}</span>
                </div>
                <div >
                    {calibrationStatusText.text === "Test Passed"
                        ? null
                        : calibrationStatusText.text === "Calibration Done"
                            ? <button className="rectangle-card-timp-body-top-content2-dark" onClick={(e) => { e.stopPropagation(); onTestButtonClick(); }}>Test</button>
                            : calibrationStatusText.text === "Set-up done" || calibrationStatusText.text === "Tim Offline" || calibrationStatusText.text === "Error" || calibrationStatusText.text === "I2C Error"
                                ? <button className="rectangle-card-timp-body-top-content2-dark" onClick={(e) => { e.stopPropagation(); onCalibrateButtonClick() }}>{calibrationTextforButton}</button>
                                : calibrationStatusText.text === "Set-up"
                                    ? ""
                                    : null
                    }
                    {calibrationStatusText.text === "Calibration Done" || calibrationStatusText.text === "Set-up done" || calibrationStatusText.text === "Tim Offline" || calibrationStatusText.text === "Error" || calibrationStatusText.text === "I2C Error"
                        ? <button
                            onClick={(e) => { e.stopPropagation(); onResetButtonClick() }}
                            onKeyDown={(e) => { if (e.key === "Enter" || e.key === ' ') { e.stopPropagation(); onResetButtonClick() } }}
                            className="rectangle-card-timp-body-top-content2"
                            style={{ marginTop: "0.5rem" }}>
                            Reset
                        </button>
                        : null
                    }
                    <span className="rectangle-card-space-capacity">
                        <img alt="capacityIcon" src={capacityIcon} style={{ margin: "0 2px 2px 2px" }}></img>
                        <span className="rectangle-card-timp-capacity">{capacity} capacity</span>
                    </span>
                </div>

            </div >
        </>
    );
}

export default RectangleCardBatTim;