import "./productCard.css"

function ProductCard({ image, label, onClick, onResetClick }) {
    return (<>
        <div className="border rounded-lg sqaure-card-body-product" onClick={onClick} tabIndex={0} onKeyDown={(e) => {/*console.log(e.key);*/ if (e.key === 'Enter' || e.key === ' ') onClick() }} >
            <div className="sqaure-card-body-contentDiv-product">
                <img src={image} alt='Freespace product' style={{ margin: "2px 2px 2px 2px", alignItems: 'center', height: 'auto', maxWidth: '90%', padding: '15px 0px 0px 0px' }}></img>
                <h6 style={{ color: "grey", padding: "5px" }}>Freespace {label}</h6>
            </div>

        </div>
    </>);
}

export default ProductCard;