import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useCallback, useRef } from 'react';
import { useMediaQuery } from 'react-responsive'
import AuthService from "../../Auth/auth";
import HeaderSecondary from "../../components/headerSecondary/HeaderSecondary";
import InfoBox from "../../components/infoBox/InfoBox";
import CautionIcon from "../../assets/images/caution.svg"
import carbonUserInfo from "../../assets/images/carbonUserInfo.svg"
import { SpotWSS } from 'wf-utils/spot/spot-wss';
import { toJpeg } from 'html-to-image';
// import * as htmlToImage from 'html-to-image';

import "./live-grid.css"
import cameraIcon from "../../assets/images/camera.svg";
import tim from "../../assets/images/timIcon.png";
import infoIcon from "../../assets/images/infoIcon.svg"
import Button1 from "../../components/Button1";
import Loader from "../../components/Loader/Loader";
import ButtonViewTest from "../../components/ButtonViewTest";
import Mod1 from "../../components/mod1/Mod1";

function LiveGrid() {

    const navigate = useNavigate()
    const location = useLocation()
    // console.log("***********",location.state);
    let floorId = location.state.dataForTest.locationId;
    let room = location.state.dataForTest.spaceName;
    let spaceId = location.state.dataForTest.spaceId;
    let assetId = location.state.dataForTest.assetId;
    // console.log("assetId----------->",assetId);
    let title = 'Test';
    let spotData = location.state.dataForTest.spotData;
    let macId = location.state.dataForTest.macId;
    let deviceId = location.state.dataForTest.deviceId;
    let agentId = location.state.dataForTest.agentId;
    const maxValid = 4;
    const minValid = 1;
    //let people_count = []   //count of people we get from server
    // console.log("spotDta",spotData);

    const [imgJson, setImgJson] = useState(null);
    let [angle, setAngle] = useState(null);// this is for orientation 
    let [heightInput, setHeightInput] = useState(null); // grid view change should be 0.1
    let [data, setData] = useState(null);
    let [reset, setReset] = useState(true);
    const [loading, setloading] = useState(true);
    const [isthermalImage, setIsthermalImage] = useState(false);
    const [infoText, setInfoText] = useState({});
    const [dataForGrid, setDataForGrid] = useState([])
    const [minMax, setMinmax] = useState({})
    const [gridLoad, setGridLoad] = useState(1);
    const [testingData, settestingData] = useState({ current: location.state.dataForTest.test, totalTested: 0 });
    const [snapshot, setSnapShot] = useState(false)
    const [show, setShow] = useState(true);
    const [getNumberToTest, setGetNumberToTest] = useState(false);
    const [userInput, setUserInput] = useState(maxValid - location.state.dataForTest.test)
    const [countFromSpot, setCountFromSpot] = useState(false)

    //using state as global variable and updating them in different functions without setState as dont need this data to show on UI. 
    let [people_count] = useState([]);
    let [number_testers] = useState([]);
    let [test_status] = useState([]);
    let [instalation_status] = useState(0);
    let [link_screenshot] = useState([]);

    const ref = useRef();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    // console.log("->",isDesktopOrLaptop);
    let desiredHeight = isDesktopOrLaptop ? 500 : (window.innerWidth);
    let desiredWidth = (window.innerWidth);

    function calTimWidth(d) {
        // console.log({ d });
        return parseInt(0.05 * d.width);
    }
    const modalDetails = {
        primaryText: "Number of Testers",
        secondaryText: `Please share with how many people you will be running this test.(max ${maxValid - testingData.totalTested})`,
        icon: infoIcon,
        inputBox: true,
        confirmButtonText: "Done"
    }


    useEffect(() => {
        !isthermalImage ? setInfoText({ text: "Please notify if you are able to see the thermal image.", icon: CautionIcon, background: "#ff5e5e1a" }) : setInfoText({ text: infoTextString(testingData.current, testingData.totalTested), icon: carbonUserInfo, background: "rgba(56, 214, 139, 0.1)" })

    }, [isthermalImage])


    useEffect(
        function () {
            async function fetchData() {
                let key = window.localStorage.getItem('spaceio-key');
                // console.log("key", key);
                if (key !== null) {
                    const floorDetails = location.state.floorDetails
                    // console.log(floorDetails);
                    let image = floorDetails[0].image;
                    let { scalingFactor } = floorDetails[0];
                    setImgJson(floorDetails[0].image);

                    const floorSpace = await AuthService.getFloorSpaces(key, floorId, spaceId);
                    let PolySpaces = floorSpace.filter((space) => space.id === spaceId);

                    const timdbdataresult = await AuthService.getTimDBSpaces(key, spaceId);
                    //console.log("========>>>>", timdbdataresult)
                    if (timdbdataresult.length === 0) {
                        setInfoText({ ...infoText, text: "Tim Marker is not available for this space" });
                        return;
                    }


                    [PolySpaces[0]].forEach(function (o) {
                        //console.log("ooooo", o);
                        let timResult = { ...timdbdataresult[0] };
                        //console.log(timResult);
                        setHeightInput(timResult.heightFromFloor);
                        setAngle(timResult.angle);

                        let timO = null;
                        let timResultCoords = timResult.coordinates;

                        const regex = new RegExp('.*points="([^"]*)".*');

                        let tempMark = null;
                        // if ('child' in location.state) {
                        //     tempMark = location.state.parent[0].marker.data;
                        // } else {
                        //     tempMark = o.marker.data;
                        // }
                        tempMark = o.marker.data;
                        const result = regex.exec(tempMark);
                        //console.log("result", result);

                        if (result && result.length === 2) {
                            const coordinatesStr = result[1];
                            let minX, minY, maxX, maxY;
                            coordinatesStr.trim().split(" ").forEach(pairStr => {
                                const values = pairStr.split(",");
                                const x = parseInt(values[0]);
                                const y = parseInt(values[1]);

                                // const x = 1.4900 * scalingFactor
                                // const y = 1.4863 * scalingFactor
                                if (minX === undefined || x < minX) {
                                    minX = x;
                                }
                                if (minY === undefined || y < minY) {
                                    minY = y;
                                }
                                if (maxX === undefined || x > maxX) {
                                    maxX = x;
                                }
                                if (maxY === undefined || y > maxY) {
                                    maxY = y;
                                }
                            });
                            // console.log("maxxxxx",minX,minY,maxX,maxY)

                            const originalWidth = maxX - minX;
                            const originalHeight = maxY - minY;

                            const dimensions = {
                                x: originalWidth,
                                y: originalHeight
                            };
                            const offset = {
                                x: minX,// - (overlayWidth - factor * originalWidth) / 2,
                                y: minY // - (overlayHeight - factor * originalHeight) / 2,
                            };

                            // console.log({ image });
                            // console.log({ minX, minY, maxX, maxY });

                            let actualHeight = dimensions.y;
                            let scaleH = desiredHeight / actualHeight;

                            let actualWidth = dimensions.x;
                            let scaleW = desiredWidth / actualWidth;

                            const style = {
                                backgroundImage: `url(${image.url})`,
                                width: dimensions.x + 2, // + 2 pixel for clearly
                                height: dimensions.y + 2, //+ 2 pixel for clearly
                                margin: 'auto',
                                backgroundSize: image.width + 2,// + 2 pixel for clearly
                                backgroundPositionX: -1 * offset.x,
                                backgroundPositionY: -1 * (image.height - maxY),
                                // position: 'absolute',
                                outline: '1px solid black',
                                transform: isDesktopOrLaptop ? `scale(${scaleH})` : `scale(${scaleW})`,
                                transformOrigin: isDesktopOrLaptop ? '50% 0' : '0 0'
                            };

                            // timResult.timTempPosition = {
                            //     top: maxY - timO.srf.y,
                            //     left: timO.srf.x - minX
                            // };
                            if (timResultCoords.length > 0) {
                                // let [timX, timY] = timResultCoords.split(',');
                                let [timX, timY] = [spotData.x_value, spotData.y_value]
                                console.log("bbb", spotData.x_value, spotData.y_value)
                                let timScaleX = (timX * scalingFactor - minX);
                                let timScaleY = (maxY - timY * scalingFactor);
                                timO = {
                                    srf: { x: timScaleX, y: timScaleY }
                                }
                            }
                            timResult.timTempPosition = {
                                top: timO.srf.y,
                                left: timO.srf.x
                            };
                            let temp = {
                                originalWidth,
                                originalHeight,
                                style,
                                timResult,
                                scalingFactor,
                                offset

                            }
                            setData(temp);
                            const img = new Image()
                            img.src = image.url
                            img.onload = () => setloading(false);



                        }
                        else {
                            //console.log('polygon is not created for ', spaceId)
                            setInfoText({ ...infoText, text: 'Polygon is not created for this space! ' + spaceId });
                            setloading(false);
                        }
                    })
                }
            }
            fetchData();

        }, [])

    useEffect(() => {
        // let parentAssetId = location.state.parent[0]["assetId"];
        // let unsubscribe ;
        let unsubscribePromise;

        // async function fetchParentAssetId() {
            // const sleep = ms => new Promise(resolve => setTimeout(resolve,ms))
            // await sleep(10000)

            // parentAssetId = await AuthService.getAssetId(location.state.parent[0].id);
            // parentAssetId = parentAssetId.assetId;
            // location.state.parent[0]["assetId"] = parentAssetId;
           let parentAssetId = assetId
            // console.log("parentAssetId", assetId)
            return subscribeSpot(handleSpotData, parentAssetId, 23);

        // }

        // if (!parentAssetId) {
        //     unsubscribePromise = fetchParentAssetId()
        // }
        // else {
        //     unsubscribePromise = Promise.resolve(subscribeSpot(handleSpotData, parentAssetId, 23))
        // }

        // return async () => {
        //     const unsubscribe = await unsubscribePromise
        //     unsubscribe();
        //     await AuthService.defaultFrameRate(agentId)
        // };
    }, [])



    const subscribeSpot = (onData, assetId, templateId = 23) => {
        // console.log('------------------ :', assetId);
        const subscriptions = [{ assetId: assetId, templateId: templateId }];
        let key = window.localStorage.getItem('spaceio-key');

        const spotWs = new SpotWSS({
            subscriptions,
            wsUrl: config.config,
            token: key,
            onData,
        });

        return () => {
            spotWs.disposeConnection();
        };
    };

    const handleSpotData = ({ data }) => {
        // console.log("########", data)
        // console.log(gridLoad)
        // if(gridLoad === 1) setloading(true)
        // if (data.deviceId === deviceId) {

            let pixelData = JSON.parse(data.pixel_data)
            pixelData = [].concat(...pixelData)

            let calibrationData = JSON.parse(data.calibration_data)
            calibrationData = [].concat(...calibrationData)

            let arr = []
            //console.log(pixelData, calibrationData)
            for (let i = 0; i < pixelData.length; i++) {
                // console.log((pixelData[i] || 0) , (calibrationData[i] || 0),"  " ,(pixelData[i] || 0) - (calibrationData[i]))
                const el = ((pixelData[i] || 0) - (calibrationData[i] || 0));
                arr[i] = el;
            };

            let minMax = {}
            minMax["min"] = (Math.min(...arr))
            minMax["max"] = (Math.max(...arr))
            // console.log("************", arr)
            setMinmax(minMax)
            setDataForGrid(arr)
            // setGridLoad(gridLoad + 1)
            // setloading(false)
        // }

    };

    const infoTextString = (curr, total) => {
        let word1 = curr === 1 ? "person" : "people"
        let word2 = total === 1 ? "person" : "people"
        return `Please occupy the spaces you want to run the test on. Testing for ${curr} ${word1}, total tested for ${total} ${word2}`
    }
    const getCountFromSpot = async () => {
        setloading(true)
        let parentAssetId = location.state.parent[0]["assetId"];

        if (!parentAssetId) {
            parentAssetId = await AuthService.getAssetId(location.state.parent[0].id);
            parentAssetId = parentAssetId.assetId;
            location.state.parent[0]["assetId"] = parentAssetId;
        }
        let assetIdForCount = parentAssetId ? parentAssetId : assetId
        const peopleCount = await AuthService.getCountFromSpot([assetIdForCount])

        // console.log("peopleCount", peopleCount[0]["count"], assetIdForCount)
        // console.log(testingData.current + testingData.totalTested)

        settestingData((prev) => ({
            current: prev.current,
            totalTested: prev.totalTested + prev.current
        }))
        people_count.push(peopleCount[0]["count"]);
        number_testers.push(testingData.current)

        //console.log(people_count)
        if (testingData.current === peopleCount[0]["count"]) {
            setInfoText({ text: `People counted ${peopleCount[0]["count"]}. Test Passed!`, icon: carbonUserInfo, background: "rgba(56, 214, 139, 0.1)" })
            test_status.push(1)
        }
        else {
            setInfoText({ text: `People counted ${peopleCount[0]["count"]}. Test Failed!`, icon: CautionIcon, background: "#ff5e5e1a" })
            test_status.push(0)
        }

        setCountFromSpot(true)
        setSnapShot(true)
        setloading(false)
    }

    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const getSnapShot = useCallback(async () => {
        setloading(true)
        if (ref.current === null) {
            return
        }
        await toJpeg(ref.current)
            .then(async (dataUrl) => {
                // **download file to verify the snapshot       
                // const link = document.createElement('a')
                // link.download = 'my-image-name.jpg'
                // link.href = dataUrl
                // link.click()
                const date = new Date();

                let fileName =
                    location.state.floorDetails[0].name + "_" +
                    ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + date.getFullYear()
                    + "_" +
                    date.toLocaleTimeString([], {
                        hour12: false,
                    }) +
                    ".jpg"
                //console.log(fileName)
                link_screenshot.push(fileName);
                var file = dataURLtoFile(dataUrl, fileName);

                await AuthService.uploadFileTos3(file)

                setShow(false)
                setloading(false)
            })
            .catch(err => console.log(err))

    }, [ref])

    async function writingInSpot() {
        setloading(true)
        let count = 0;
        for (let i of test_status) {
            if (i === 1) count++
        }
        if (count / test_status.length === 1) instalation_status = 1
        else if (count / test_status.length >= 0.5) instalation_status = 2
        else instalation_status = 3

        let dataToWrite = [{
            "assetId": assetId,
            "data": {
                ...spotData,
                "people_count": people_count,
                "number_testers": number_testers[0],
                "test_status": [...test_status, (spotData.test_status[spotData.test_status.length - 1] + 1)],
                "instalation_status": instalation_status,
                " link_screenshot ": link_screenshot
            }
        }]
        //console.log("write to spot", dataToWrite)
        await AuthService.writeDataToSpot(dataToWrite)
        setloading(false)
        navigate("/all-tim",
            { state: { ...location.state }, replace: true })

    }

    const uploadSnapAndNextTest = async () => {

        getSnapShot().then(() => {

            if (testingData.totalTested < maxValid) {
                setGetNumberToTest(true)
                setInfoText({ text: infoTextString(testingData.current, testingData.totalTested), icon: carbonUserInfo, background: "rgba(56, 214, 139, 0.1)" })
                setCountFromSpot(false)
            }
            else {
                writingInSpot()
            }
        })

    }

    // const testNextSpace = async () => { setloading(true)
    //     let parentAssetId = location.state.parent[0]["assetId"];

    //     if (!parentAssetId) {
    //         parentAssetId = await AuthService.getAssetId(location.state.parent[0].id);
    //         parentAssetId = parentAssetId.assetId;
    //         location.state.parent[0]["assetId"] = parentAssetId;
    //     }    
    //     let assetIdForCount = parentAssetId ? parentAssetId : assetId
    //     const peopleCount = await AuthService.getCountFromSpot([assetIdForCount])

    //     console.log("peopleCount",peopleCount[0]["count"],assetIdForCount)
    //     console.log(testingData.current+testingData.totalTested)

    //     settestingData((prev)=>({
    //         current:prev.current,
    //         totalTested:prev.totalTested + prev.current
    //     }))
    //     people_count.push(peopleCount[0]["count"]);
    //     number_testers.push(testingData.current)

    //     console.log(people_count)
    //     if(testingData.current === peopleCount[0]["count"]){
    //         setInfoText({text:`People counted ${peopleCount[0]["count"]}. Test Passed!`,icon:carbonUserInfo,background:"rgba(56, 214, 139, 0.1)"}) 
    //         test_status.push(1)
    //     }
    //     else{
    //         setInfoText({text:`People counted ${peopleCount[0]["count"]}. Test Failed!`,icon:CautionIcon, background:"#ff5e5e1a"})
    //         test_status.push(0)
    //     }

    //     setCountFromSpot(true)
    //     setSnapShot(true)
    //     setloading(false)

    // }
    const setModalDetailsAfterCheck = () => {
        let val = userInput
        if (userInput >= minValid && userInput <= maxValid - testingData.totalTested) {
            settestingData({ ...testingData, current: val })
            setGetNumberToTest(false)
            setInfoText({ ...infoText, text: infoTextString(val, testingData.totalTested) })
            setUserInput(minValid)
        }
    }

    const checkValueForTest = (value) => {
        let val = parseInt(value)
        setUserInput(val)
        setTimeout(function () {
            if (val < minValid) setUserInput(minValid)
            else if (val > maxValid - testingData.totalTested) setUserInput(maxValid - testingData.totalTested)
            // if(value >= minValid && value <= maxValid-testingData.totalTested) setUserInput(parseInt(value))
        }, 1500);
    }

    return (<>
        {loading && <Loader />}
        <div className="container-fluid g-0">
            <div class="row justify-content-center g-0 mainDiv">
                <div ref={ref} className="col-sm-12 col-lg-8 position-relative" style={{ background: "#ffffff" }}>


                    <div className="row g-0 snapShotClass" style={{ height: "100%", display: "flex", flexDirection: "column" }}>

                        {getNumberToTest ? <Mod1 text={{ primaryText: modalDetails.primaryText, secondaryText: modalDetails.secondaryText, icon: modalDetails.icon }}
                            // closeModal={() => setGetNumberToTest(false) }
                            confirmButton={{ text: modalDetails.confirmButtonText, onClick: setModalDetailsAfterCheck }}
                            inputBox={modalDetails.inputBox}
                            value={modalDetails.inputBox ? userInput : null}
                            setValue={modalDetails.inputBox ?
                                checkValueForTest
                                : null}
                        /> : null}

                        <div className="col-12" style={{ background: "#F5F5F5", padding: '0 5px' }}>
                            <div className="row g-0">
                                <div className="col-11 mx-auto" style={{ background: "#F5F5F5" }}>
                                    <HeaderSecondary
                                        secondaryText={title}
                                        primaryText={room}
                                        goBack={() => navigate(-1)}
                                        tabInd={0}
                                        buttonSecondary={countFromSpot && (isthermalImage && show || snapshot) ? <button style={{ border: "none", background: "none" }} onClick={() => { uploadSnapAndNextTest() }}><img src={cameraIcon} alt="snapshot" crossOrigin="anonymous" /></button> : null}
                                    />
                                </div>
                            </div>
                        </div>
                        <></>
                        <div className="col-11 mx-auto my-4">
                            <InfoBox text={infoText.text} image={infoText.icon} background={infoText.background} confirm={!isthermalImage ? () => setIsthermalImage(!isthermalImage) : null} cancel={() => navigate(-1)} />
                        </div>

                        <div className="col-11.5 mx-auto" style={{ marginTop: reset ? '' : '30px', position: 'relative', height: desiredHeight }}>
                            {imgJson && data && <div id="arena" style={data.style}>
                                {/* <img src={imgJson.url} crossOrigin="anonymous"/> */}
                                <div id="tim_marker">
                                    <GridView data={data} userHeight={spotData.height_set} userAngle={spotData.orientation_set} dataForGrid={dataForGrid} minMax={minMax} />
                                </div>
                                <div role="img" aria-label="tim" id="tim_marker_test" style={{
                                    position: 'absolute',
                                    top: `${data.timResult.timTempPosition.top}px`,
                                    left: `${data.timResult.timTempPosition.left}px`,
                                    transform: `rotate(-${angle}deg)`,
                                    height: '0px',
                                    width: '0px'
                                }}>
                                    {/*this is tim marker */}
                                    <img src={tim} alt="tim" style={{
                                        margin: 0,
                                        position: 'absolute',
                                        top: '50 %',
                                        left: '50 %',
                                        transform: 'translate(-50%, -50%)',
                                        width: calTimWidth(data.style) || '20px',
                                        height: calTimWidth(data.style) || '20px'
                                    }} />
                                </div>

                            </div>
                            }
                        </div>
                        <br />
                        {
                            isthermalImage && !snapshot ? <Button1
                                text={"Start Testing"}
                                style={{ margin: "auto 0 0rem", minHeight: "42px" }}
                                background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"} color={"#ffffff"}
                                onClick={getCountFromSpot}
                            /> : isthermalImage && snapshot ?

                                <div style={{ background: "#f5f5f5", display: "flex", alignItems: "center", margin: "auto 0 0rem", minHeight: "42px" }}>
                                    {show && <ButtonViewTest text="Rerun Test" borderRadius="0px" borderColor="#ff9170" background="white" color="#ff9170" onClick={() => navigate(-1)} />}
                                    <ButtonViewTest text={countFromSpot ? "Take SnapShot" : "Test Current Space"} borderRadius="0px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white"
                                        onClick={countFromSpot ? () => uploadSnapAndNextTest() : () => getCountFromSpot()} />

                                </div> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    </>);
}
{/* <div style={{ background: "#f5f5f5", display: "flex", alignItems: "center",margin: "auto 0 0rem",minHeight:"42px"}}>
                        {show && <ButtonViewTest text="Rerun Test" borderRadius="0px" borderColor="#ff9170" background="white" color="#ff9170" onClick={() => navigate(-1)} />}
                        {!countFromSpot ? <ButtonViewTest text={show ? "Test Next Space" : "Test Current Space"} borderRadius="0px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white" 
                        onClick={show ? () => uploadSnapAndNextTest() : () => testNextSpace() } /> :
                        <ButtonViewTest text={"Testing Nxt"} borderRadius="0px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white" 
                        onClick={ () => {uploadSnapAndNextTest()} } />
                        } 

                    </div> :null */}
export default LiveGrid;

function GridValue(left, top, scalingFactorMeter, rotationAngleFromInput, heightFromFloor, minX, minY) {
    // console.log(arguments);
    var XOfReact = parseFloat(left);
    var YOfReact = parseFloat(top);
    let height = heightFromFloor; // in meter
    let width = 1.534 * height; /* Area width*/
    let length = 2.856 * height / 1.5;/*Area hieght*/
    var rxWidth = width * scalingFactorMeter;//10 * 24;//???
    var ryLength = length * scalingFactorMeter;//10 * 32;//???
    let gridWidth = rxWidth / 24; ////
    let gridLength = ryLength / 32;
    let gridpaint = [];
    let gridpaintArray = [];
    let i = 0;
    let j = 0;
    for (j = 0; j < 32; j++) {

        for (i = 0; i < 24; i++) {
            gridpaint.push(`
		<g transform="rotate(-${rotationAngleFromInput} ${XOfReact + 15} ${YOfReact + 15}) translate(-${ryLength / 2 - 15} , -${rxWidth / 2 - 15})" >
		<rect  x="${XOfReact + (j * gridLength)}" y="${YOfReact + (i * gridWidth)}" width="${gridLength}" height="${gridWidth}"  style="stroke-width:0.3;" />
		<text font-size="0.1em"  x="${XOfReact + (j * gridLength)}" y="${20 + YOfReact + (i * gridWidth)}" transform="rotate(${rotationAngleFromInput} ${XOfReact + (j * gridLength) + (gridLength / 2)},${YOfReact + (i * gridWidth) + (gridWidth / 2)})"  opacity=".1" >${(i * 32) + (31 - j)}</text>
		</g>
		`)
            let tempData = {
                g: {
                    transform: `rotate(-${rotationAngleFromInput} ${XOfReact} ${YOfReact}) translate(-${ryLength / 2} , -${rxWidth / 2})`
                },
                rect: {
                    x: `${XOfReact + (j * gridLength)}`,
                    y: `${YOfReact + (i * gridWidth)}`,
                    width: `${gridLength}`,
                    height: `${gridWidth}`
                },
                text: {
                    x: `${5 + XOfReact + (j * gridLength)}`,
                    y: `${18 + YOfReact + (i * gridWidth)}`,
                    transform: `rotate(${rotationAngleFromInput} ${XOfReact + (j * gridLength) + (gridLength / 2)},${YOfReact + (i * gridWidth) + (gridWidth / 2)})`,
                    value: `${(i * 32) + (31 - j)}`
                }
            }
            gridpaintArray.push(tempData);
            ;
        }
    }
    // console.log("gridpaintArray",gridpaintArray)
    return [gridpaint, gridpaintArray, rxWidth, ryLength, minX, minY];

}

const GridView = ({ data, userHeight, userAngle, dataForGrid, minMax }) => {
    // let arr = []
    // for(let i = 0;i<(24*32);i++){
    //     arr.push(undefined)
    // }
    // console.log("dataForGrid------------->",dataForGrid);

    function fillOpacity(value) {
        let opacity;
        // let opacity = (value == null ? 0 : (0.3 + Math.abs(value) * 0.7 / 100));
        if (minMax["max"] - minMax["min"] !== 0) {
            opacity = value == null ? 0 : ((((value - minMax["min"]) * (1 - 0)) / (minMax["max"] - minMax["min"])) + 0)
            return value > 0 ? `rgba(255, 0, 0, ${opacity})` : `rgba(0,128, 0, ${opacity})`;
        }
        else return "rgba(0, 128 , 0, 0)"
        // console.log(value,opacity);
        // return 'rgba(255, 0, 0, 0.5)'

    }
    // console.log('userAngle', userAngle);
    // console.log('userHeight', userHeight);
    // console.log('GridView', data);
    let { scalingFactor } = data;
    let { left, top } = data.timResult.timTempPosition
    let { angle } = data.timResult;
    let { heightFromFloor } = data.timResult;
    let { x, y } = data.offset;
    let [returngridpaint, returngridpaintArray, rxWidth, ryLength] = GridValue(left, top, scalingFactor, userAngle, userHeight, x, y);
    return (
        <svg
            width={data.originalWidth} height={data.originalHeight}
        // style={{ transform: `translate(-${rxWidth / 2}px, -${ryLength / 2}px)` }}
        // viewBox={`${x} ${y} ${data.originalWidth} ${data.originalHeight}`}
        // viewBox="10 10 10 10"
        >
            {/* <rect width={rxWidth} height={ryLength} style={{ strokeWidth: 0.3, fill: 'transparent' }} /> */}
            {returngridpaintArray.map(({ g, rect, text }) => {
                return (
                    < g transform={g.transform} key={text.value}>
                        <rect x={rect.x} y={rect.y} width={rect.width} height={rect.height} style={{ strokeWidth: 1, stroke: 'rgba(255, 81, 72,0.1)', fill: fillOpacity(dataForGrid[text.value]) }} />
                        <text fontSize="0.3em" x={text.x - 5} y={text.y - 10} transform={text.transform} opacity="1" >{dataForGrid[text.value]}</text>
                    </g>
                )
            })}
        </svg >


    )
}