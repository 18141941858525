import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useCallback, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import AuthService from "../../Auth/auth";
import HeaderSecondary from "../../components/headerSecondary/HeaderSecondary";
import InfoBox from "../../components/infoBox/InfoBox";
import CautionIcon from "../../assets/images/caution.svg";
import carbonUserInfo from "../../assets/images/carbonUserInfo.svg";
import { SpotWSS } from "wf-utils/spot/spot-wss";
import { toJpeg } from "html-to-image";
import config from "../../config";
// import * as htmlToImage from 'html-to-image';

import "./live-grid.css";
import cameraIcon from "../../assets/images/camera.svg";
import tim from "../../assets/images/timIcon.png";
import infoIcon from "../../assets/images/infoIcon.svg";
import BottomGrid from "../../components/BottomGrid";
import BottomGrid2 from "../../components/BottomGrid2";
import BottomGrid3 from "../../components/BottomGrid3";
import Button1 from "../../components/Button1";

// import BottomGrid2 from "../../components/BottomGrid2";
// import BottomGrid3 from "../../components/BottomGrid3";

import Loader from "../../components/Loader/Loader";
import ButtonViewTest from "../../components/ButtonViewTest";
import Mod1 from "../../components/mod1/Mod1";

function LiveGridTimp() {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("location state", location.state)
  let floorId = location.state.dataForTest.data.locationId;
  let floorName = location.state.dataForTest.data.locationName;
  let room = location.state.dataForTest.data.spaceName;
  let spaceId = location.state.dataForTest.data.spaceId;
  let assetId = location.state.dataForTest.data.assetId;
  let title = "Test";
  let spotData = location.state.dataForTest.data.spotData;
  let macId = location.state.dataForTest.data.macId;
  let deviceId = location.state.dataForTest.data.deviceId;
  let agentId = location.state.dataForTest.data.agentId;
  let OrientationAngle = spotData.orientation_set;

  let markerId = location.state.dataForTest.data.markerId || null;

  const maxValid = 4;
  const minValid = 1;
  //let people_count = []   //count of people we get from server
  // console.log("spotDta",spotData);

  const [imgJson, setImgJson] = useState(null);
  let [angle, setAngle] = useState(null); // this is for orientation
  let [heightInput, setHeightInput] = useState(null); // grid view change should be 0.1
  let [data, setData] = useState(null);
  let [reset, setReset] = useState(true);

  let [x, setX] = useState(0);
  let [y, setY] = useState(0);
  let [scalingF, setScalingFactor] = useState(1);

  const [loading, setloading] = useState(true);
  const [isthermalImage, setIsthermalImage] = useState(false);
  const [infoText, setInfoText] = useState({});
  const [dataForGrid, setDataForGrid] = useState([]);
  const [minMax, setMinmax] = useState({});
  const [gridLoad, setGridLoad] = useState(1);
  const [testingData, settestingData] = useState({
    current: location.state.dataForTest.test,
    totalTested: 0,
  });
  const [snapshot, setSnapShot] = useState(false);
  const [show, setShow] = useState(true);
  const [getNumberToTest, setGetNumberToTest] = useState(false);
  const [userInput, setUserInput] = useState(
    maxValid - location.state.dataForTest.test
  );
  const [countFromSpot, setCountFromSpot] = useState(false);
  const [showInfo, setShowInfo] = useState(true);
  const [imgSetState, setImgSetState] = useState(true);

  let [pixelslist, setPixelslist] = useState([]);
  let [pixelslist_assetid, setPixelslist_assetid] = useState({});
  let [assetlist_pixels, setAssetlist_pixels] = useState({});
  let [actual_assetlist_pixels, setActualAssetlist_pixels] = useState({});
  let [assetlist_slider_val, setAssetlist_slider_val] = useState({});
  let [selectedChild, setSelectedChild] = useState(null);
  let [isSelcted, setIsSelcted] = useState(false);
  let [selectedChildPixels, setSelectedChildPixels] = useState([]);
  let [editPositionClicked, setEditPositionClicked] = useState(0);
  let [timplusspotdata, setTimplusspotdata] = useState(null);
  let [defaultAssetSliderValue, setDefaultAssetSliderValue] = useState([0, 0]);
  let [defaultSelectedChildPixels, setDefaultSelectedChildPixels] = useState(
    []
  );

  //using state as global variable and updating them in different functions without setState as dont need this data to show on UI.
  let [people_count] = useState([]);
  let [number_testers] = useState([]);
  let [test_status] = useState([]);
  let [instalation_status] = useState(0);
  let [link_screenshot] = useState([]);

  const [resetCall, setResetCall] = useState(0);

  const ref = useRef();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  // console.log("->",isDesktopOrLaptop);
  let desiredHeight = isDesktopOrLaptop ? 500 : window.innerWidth;
  let desiredWidth = window.innerWidth;

  function calTimWidth(d) {
    // console.log({ d });
    return parseInt(0.05 * d.width);
  }

  async function setupDone() {
    console.log("setUp Done");
    //console.log("final data", data);
    let key = localStorage.getItem("spaceio-key");
    let utcTimestamp = new Date().getTime();

    let dataToWrite = null;
    Object.keys(assetlist_pixels).map((asset_id) => {
      console.log("asset_id", asset_id);
      console.log("assetlist_pixels", assetlist_pixels); // updated pixels => assetID:pixels
      console.log("spot data", timplusspotdata);
    });
    timplusspotdata[0].test_zone.map(
      (device) => (device.pixels = assetlist_pixels[device.asset_id])
    );
    // Added this line for PWC
    timplusspotdata[0].final_zone.map(
      (device) => (device.pixels = assetlist_pixels[device.asset_id])
    );

    timplusspotdata[0].zone_setup_status = 2;
    utcTimestamp = new Date().getTime();
    dataToWrite = [
      {
        assetId: timplusspotdata[0].id,
        data: timplusspotdata[0],
      },
    ];
    console.log("dataToWrite", dataToWrite);
    await AuthService.writeDataToTIMpSpot(dataToWrite);

    // Added this line for PWC
    let defaultFrameRate = await AuthService.defaultFrameRate(agentId);
    console.log("defaultFrameRate", defaultFrameRate);

    navigate(`/SelectTimPfloor?floor=${floorId}&name=${floorName}`, {
      state: { ...location.state, "final data": data },
    });
  }

  const modalDetails = {
    primaryText: "Number of Testers",
    secondaryText: `Please share with how many people you will be running this test.(max ${
      maxValid - testingData.totalTested
    })`,
    icon: infoIcon,
    inputBox: true,
    confirmButtonText: "Done",
  };

  function changeX(v) {
    // console.log("spotData",v,scalingF,(v / scalingF).toFixed(4))
    setX((v / scalingF).toFixed(4));
  }

  function changeY(v) {
    // v = data.originalHeight - v
    // console.log("spotData",v,scalingF,(v / scalingF).toFixed(4))
    setY((v / scalingF).toFixed(4));
  }

  function resetCallback() {
    setResetCall(resetCall + 1);
  }

  useEffect(() => {
    !isthermalImage
      ? setInfoText({
          text: "Please notify if you are able to see the thermal image.",
          icon: CautionIcon,
          background: "#ff5e5e1a",
        })
      : setInfoText({
          text: infoTextString(testingData.current, testingData.totalTested),
          icon: carbonUserInfo,
          background: "rgba(56, 214, 139, 0.1)",
        });
  }, [isthermalImage]);

  useEffect(() => {
    console.log("pixelslist---", pixelslist);
  }, [pixelslist]);
  useEffect(() => {
    console.log("pixelslist_assetid---", pixelslist_assetid);
  }, [pixelslist_assetid]);
  useEffect(() => {
    console.log("assetlist_pixels---", assetlist_pixels);
  }, [assetlist_pixels]);
  useEffect(() => {
    console.log("actual_assetlist_pixels---", actual_assetlist_pixels);
  }, [actual_assetlist_pixels]);
  useEffect(() => {
    console.log("selectedChildPixels---", selectedChildPixels);
  }, [selectedChildPixels]);

  useEffect(() => {
    console.log("selectedChild---", selectedChild);
    if (selectedChild) {
      setSelectedChildPixels(assetlist_pixels[selectedChild]);
      setDefaultAssetSliderValue([0, 0]);
      setDefaultSelectedChildPixels(assetlist_pixels[selectedChild]);
    }
  }, [selectedChild]);

  useEffect(() => {
    console.log("timplusspotdata---", timplusspotdata);
  }, [timplusspotdata]);

  useEffect(function () {
    async function fetchData() {
      let key = window.localStorage.getItem("spaceio-key");
      if (key !== null) {
        const floorDetails = location.state.dataForTest.data["floorDetails"];
        console.log("here", floorDetails);
        let image = floorDetails[0].image;
        let { scalingFactor } = floorDetails[0];
        setImgJson(floorDetails[0].image);

        const floorSpace = await AuthService.getFloorSpaces(
          key,
          floorId,
          spaceId
        );
        let PolySpaces = floorSpace.filter((space) => space.id === spaceId);

        const timdbdataresult = await AuthService.getTimDBSpaces(key, spaceId);
        //console.log("========>>>>", timdbdataresult)
        if (timdbdataresult.length === 0) {
          setInfoText({
            ...infoText,
            text: "Tim Marker is not available for this space",
          });
          return;
        }

        [PolySpaces[0]].forEach(function (o) {
          //console.log("ooooo", o);
          let timResult = { ...timdbdataresult[0] };
          //console.log(timResult);
          setHeightInput(timResult.heightFromFloor);
          setAngle(timResult.angle);

          let timO = null;
          let timResultCoords = timResult.coordinates;

          const regex = new RegExp('.*points="([^"]*)".*');

          let tempMark = null;
          // if ('child' in location.state) {
          //     tempMark = location.state.parent[0].marker.data;
          // } else {
          //     tempMark = o.marker.data;
          // }
          tempMark = o.marker.data;
          const result = regex.exec(tempMark);
          //console.log("result", result);

          if (result && result.length === 2) {
            const coordinatesStr = result[1];
            let minX, minY, maxX, maxY;
            coordinatesStr
              .trim()
              .split(" ")
              .forEach((pairStr) => {
                const values = pairStr.split(",");
                const x = parseInt(values[0]);
                const y = parseInt(values[1]);

                // const x = 1.4900 * scalingFactor
                // const y = 1.4863 * scalingFactor
                if (minX === undefined || x < minX) {
                  minX = x;
                }
                if (minY === undefined || y < minY) {
                  minY = y;
                }
                if (maxX === undefined || x > maxX) {
                  maxX = x;
                }
                if (maxY === undefined || y > maxY) {
                  maxY = y;
                }
              });
            // console.log("maxxxxx",minX,minY,maxX,maxY)

            const originalWidth = maxX - minX;
            const originalHeight = maxY - minY;

            const dimensions = {
              x: originalWidth,
              y: originalHeight,
            };
            const offset = {
              x: minX, // - (overlayWidth - factor * originalWidth) / 2,
              y: minY, // - (overlayHeight - factor * originalHeight) / 2,
            };

            // console.log({ image });
            // console.log({ minX, minY, maxX, maxY });

            let actualHeight = dimensions.y;
            let scaleH = desiredHeight / actualHeight;

            let actualWidth = dimensions.x;
            let scaleW = desiredWidth / actualWidth;

            const style = {
              backgroundImage: `url(${image.url})`,
              width: dimensions.x + 2, // + 2 pixel for clearly
              height: dimensions.y + 2, //+ 2 pixel for clearly
              margin: "auto",
              backgroundSize: image.width + 2, // + 2 pixel for clearly
              backgroundPositionX: -1 * offset.x,
              backgroundPositionY: -1 * (image.height - maxY),
              // position: 'absolute',
              outline: "1px solid black",
              transform: isDesktopOrLaptop
                ? `scale(${scaleH})`
                : `scale(${scaleW})`,
              transformOrigin: isDesktopOrLaptop ? "50% 0" : "0 0",
            };

            // timResult.timTempPosition = {
            //     top: maxY - timO.srf.y,
            //     left: timO.srf.x - minX
            // };
            if (timResultCoords.length > 0) {
              // let [timX, timY] = timResultCoords.split(',');
              let [timX, timY] = [spotData.x_value, spotData.y_value];
              console.log("bbb", spotData.x_value, spotData.y_value);
              let timScaleX = timX * scalingFactor - minX;
              let timScaleY = maxY - timY * scalingFactor;
              timO = {
                srf: { x: timScaleX, y: timScaleY },
              };
            }
            timResult.timTempPosition = {
              top: timO.srf.y,
              left: timO.srf.x,
            };
            let temp = {
              originalWidth,
              originalHeight,
              style,
              timResult,
              scalingFactor,
              offset,
            };
            setData(temp);
            const img = new Image();
            img.src = image.url;
            img.onload = () => setloading(false);
          } else {
            //console.log('polygon is not created for ', spaceId)
            setInfoText({
              ...infoText,
              text: "Polygon is not created for this space! " + spaceId,
            });
            setloading(false);
          }
        });

        // let parentAssetIdArray = ["b94c8e14-d8d2-4236-a23d-32f6ae22a9f5"] //assetId
        let parentAssetIdArray = [assetId]; //assetId
        const timplusspotdatat = await AuthService.readTimPDataSPOT(
          key,
          parentAssetIdArray
        );
        setTimplusspotdata(timplusspotdatat);
        let timplusspotdata_dict = {};
        timplusspotdatat.map(
          (item) => (timplusspotdata_dict[item["id"]] = item)
        );
        console.log("timplusspotdata_dict", timplusspotdata_dict);

        var test_zone = timplusspotdata_dict[assetId].test_zone; //assetId
        console.log("test_zone", test_zone);
        var pixels_arr = [];
        var allPixels = [];
        var allPixels_assetID = {};
        var allassetID_pixels = {};
        var actual_allassetID_pixels = {};

        test_zone.map((child) => {
          return [
            pixels_arr.push(child.pixels), // 2d array
            (allassetID_pixels[child.asset_id] = child.pixels), //assetID:pixels
            (actual_allassetID_pixels[child.asset_id] = child.pixels), //assetID:pixels
            (assetlist_slider_val[child.asset_id] = [0, 0]), // slider value setting 0
            child.pixels.map((pixel) => {
              return [
                allPixels.push(pixel), //1d array
                (allPixels_assetID[pixel] = child.asset_id), // pixel:assetID
              ];
            }),
          ];
        });

        console.log("pixels_arr,", pixels_arr); // 2d array
        console.log("allPixels,", allPixels); //1d array
        console.log("allPixels_assetID,", allPixels_assetID); // pixel:assetID
        console.log("allassetID_pixels,", allassetID_pixels);
        setPixelslist(allPixels); //1d array =>global
        setPixelslist_assetid(allPixels_assetID); // pixel:assetID => global
        setAssetlist_pixels(allassetID_pixels); ///assetID:pixels => global
        setActualAssetlist_pixels(actual_allassetID_pixels); ///assetID:pixels => global for reset
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    let parentAssetId = location.state.dataForTest.data["assetId"];
    // let unsubscribe ;
    let unsubscribePromise;

    async function fetchParentAssetId() {
      // const sleep = ms => new Promise(resolve => setTimeout(resolve,ms))
      // await sleep(10000)

      parentAssetId = await AuthService.getAssetId(
        location.state.dataForTest.data["assetId"].spaceId
      );
      parentAssetId = parentAssetId.assetId;
      location.state.parent[0]["assetId"] = parentAssetId;
      //console.log("parentAssetId", parentAssetId)
      return subscribeSpot(handleSpotData, parentAssetId, 23);
    }

    if (!parentAssetId) {
      unsubscribePromise = fetchParentAssetId();
    } else {
      unsubscribePromise = Promise.resolve(
        subscribeSpot(handleSpotData, parentAssetId, 23)
      );
    }

    return async () => {
      const unsubscribe = await unsubscribePromise;
      unsubscribe();
      await AuthService.defaultFrameRate(agentId);
    };
  }, []);

  const subscribeSpot = (onData, assetId, templateId = 23) => {
    const subscriptions = [{ assetId: assetId, templateId: templateId }];
    let key = window.localStorage.getItem("spaceio-key");

    const spotWs = new SpotWSS({
      subscriptions,
      wsUrl: config.WEB_SOCKET_URL,
      token: key,
      onData,
    });

    return () => {
      spotWs.disposeConnection();
    };
  };

  const handleSpotData = ({ data }) => {
    // console.log("dataForGrid", data.device_id, macId)
    // console.log(gridLoad)
    // if(gridLoad === 1) setloading(true)
    // console.log("handleSpotData data =>",data)
    // console.log(location.state.dataForTest.data)
    if (data.deviceId === deviceId) {
      let pixelData = JSON.parse(data.pixel_data);
      pixelData = [].concat(...pixelData);

      let calibrationData = JSON.parse(data.calibration_data);
      calibrationData = [].concat(...calibrationData);

      let arr = [];
      //console.log(pixelData, calibrationData)
      for (let i = 0; i < pixelData.length; i++) {
        // console.log((pixelData[i] || 0) , (calibrationData[i] || 0),"  " ,(pixelData[i] || 0) - (calibrationData[i]))
        const el = (pixelData[i] || 0) - (calibrationData[i] || 0);
        arr[i] = el;
      }

      let minMax = {};
      minMax["min"] = Math.min(...arr);
      minMax["max"] = Math.max(...arr);
      //console.log("dataForGrid", minMax)
      setMinmax(minMax);
      setDataForGrid(arr);
      // setGridLoad(gridLoad + 1)
      // setloading(false)
    }
  };

  const infoTextString = (curr, total) => {
    let word1 = curr === 1 ? "person" : "people";
    let word2 = total === 1 ? "person" : "people";
    return "To shift the zone click on Edit Position";
  };

  const getCountFromSpot = async () => {
    setloading(true);
    let parentAssetId = location.state.parent[0]["assetId"];

    if (!parentAssetId) {
      parentAssetId = await AuthService.getAssetId(location.state.parent[0].id);
      parentAssetId = parentAssetId.assetId;
      location.state.parent[0]["assetId"] = parentAssetId;
    }
    let assetIdForCount = parentAssetId ? parentAssetId : assetId;
    const peopleCount = await AuthService.getCountFromSpot([assetIdForCount]);

    // console.log("peopleCount", peopleCount[0]["count"], assetIdForCount)
    // console.log(testingData.current + testingData.totalTested)

    settestingData((prev) => ({
      current: prev.current,
      totalTested: prev.totalTested + prev.current,
    }));
    people_count.push(peopleCount[0]["count"]);
    number_testers.push(testingData.current);

    //console.log(people_count)
    if (testingData.current === peopleCount[0]["count"]) {
      setInfoText({
        text: `People counted ${peopleCount[0]["count"]}. Test Passed!`,
        icon: carbonUserInfo,
        background: "rgba(56, 214, 139, 0.1)",
      });
      test_status.push(1);
    } else {
      setInfoText({
        text: `People counted ${peopleCount[0]["count"]}. Test Failed!`,
        icon: CautionIcon,
        background: "#ff5e5e1a",
      });
      test_status.push(0);
    }

    setCountFromSpot(true);
    setSnapShot(true);
    setloading(false);
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const getSnapShot = useCallback(async () => {
    setloading(true);
    if (ref.current === null) {
      return;
    }
    await toJpeg(ref.current)
      .then(async (dataUrl) => {
        // **download file to verify the snapshot
        // const link = document.createElement('a')
        // link.download = 'my-image-name.jpg'
        // link.href = dataUrl
        // link.click()
        const date = new Date();

        let fileName =
          location.state.floorDetails[0].name +
          "_" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "-" +
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "-" +
          date.getFullYear() +
          "_" +
          date.toLocaleTimeString([], {
            hour12: false,
          }) +
          ".jpg";
        //console.log(fileName)
        link_screenshot.push(fileName);
        var file = dataURLtoFile(dataUrl, fileName);

        await AuthService.uploadFileTos3(file);

        setShow(false);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, [ref]);

  async function writingInSpot() {
    setloading(true);
    let count = 0;
    for (let i of test_status) {
      if (i === 1) count++;
    }
    if (count / test_status.length === 1) instalation_status = 1;
    else if (count / test_status.length >= 0.5) instalation_status = 2;
    else instalation_status = 3;

    let dataToWrite = [
      {
        assetId: assetId,
        data: {
          ...spotData,
          people_count: people_count,
          number_testers: number_testers[0],
          test_status: [
            ...test_status,
            spotData.test_status[spotData.test_status.length - 1] + 1,
          ],
          instalation_status: instalation_status,
          " link_screenshot ": link_screenshot,
        },
      },
    ];
    //console.log("write to spot", dataToWrite)
    await AuthService.writeDataToSpot(dataToWrite);
    setloading(false);
    navigate("/all-tim", { state: { ...location.state }, replace: true });
  }

  const uploadSnapAndNextTest = async () => {
    getSnapShot().then(() => {
      if (testingData.totalTested < maxValid) {
        setGetNumberToTest(true);
        setInfoText({
          text: infoTextString(testingData.current, testingData.totalTested),
          icon: carbonUserInfo,
          background: "rgba(56, 214, 139, 0.1)",
        });
        setCountFromSpot(false);
      } else {
        writingInSpot();
      }
    });
  };

  // const testNextSpace = async () => { setloading(true)
  //     let parentAssetId = location.state.parent[0]["assetId"];

  //     if (!parentAssetId) {
  //         parentAssetId = await AuthService.getAssetId(location.state.parent[0].id);
  //         parentAssetId = parentAssetId.assetId;
  //         location.state.parent[0]["assetId"] = parentAssetId;
  //     }
  //     let assetIdForCount = parentAssetId ? parentAssetId : assetId
  //     const peopleCount = await AuthService.getCountFromSpot([assetIdForCount])

  //     console.log("peopleCount",peopleCount[0]["count"],assetIdForCount)
  //     console.log(testingData.current+testingData.totalTested)

  //     settestingData((prev)=>({
  //         current:prev.current,
  //         totalTested:prev.totalTested + prev.current
  //     }))
  //     people_count.push(peopleCount[0]["count"]);
  //     number_testers.push(testingData.current)

  //     console.log(people_count)
  //     if(testingData.current === peopleCount[0]["count"]){
  //         setInfoText({text:`People counted ${peopleCount[0]["count"]}. Test Passed!`,icon:carbonUserInfo,background:"rgba(56, 214, 139, 0.1)"})
  //         test_status.push(1)
  //     }
  //     else{
  //         setInfoText({text:`People counted ${peopleCount[0]["count"]}. Test Failed!`,icon:CautionIcon, background:"#ff5e5e1a"})
  //         test_status.push(0)
  //     }

  //     setCountFromSpot(true)
  //     setSnapShot(true)
  //     setloading(false)

  // }
  const setModalDetailsAfterCheck = () => {
    let val = userInput;
    if (
      userInput >= minValid &&
      userInput <= maxValid - testingData.totalTested
    ) {
      settestingData({ ...testingData, current: val });
      setGetNumberToTest(false);
      setInfoText({
        ...infoText,
        text: infoTextString(val, testingData.totalTested),
      });
      setUserInput(minValid);
    }
  };

  const checkValueForTest = (value) => {
    let val = parseInt(value);
    setUserInput(val);
    setTimeout(function () {
      if (val < minValid) setUserInput(minValid);
      else if (val > maxValid - testingData.totalTested)
        setUserInput(maxValid - testingData.totalTested);
      // if(value >= minValid && value <= maxValid-testingData.totalTested) setUserInput(parseInt(value))
    }, 1500);
  };

  function between(x, min, max) {
    return x >= min && x <= max;
  }

  const shiftChildHoriVert = (value, HoriVert, reset) => {
    /*
        HoriVert = 0 // horizontal
        HoriVert = 1 // vertical
        */
    if (selectedChild) {
      assetlist_slider_val[selectedChild][HoriVert] = value;
      let [HoriVert_h, HoriVert_v] = assetlist_slider_val[selectedChild];
      setAssetlist_slider_val(assetlist_slider_val);
      let cal_value = 0;
      let v_cal_value = 0;
      if (!reset) {
        console.log("OrientationAngle", OrientationAngle);
        if (between(OrientationAngle, 0, 180)) {
          console.log("OrientationAngle >= 91 && OrientationAngle <= 180");
          // For horizontal
          cal_value = HoriVert_h * 32;
          // For vertical
          v_cal_value = HoriVert_v;
          // Add the vertical calibration value to the horizontal calibration value
          cal_value = cal_value + v_cal_value;
        } else if (between(OrientationAngle, 181, 360)) {
          console.log("OrientationAngle >= 181 && OrientationAngle <= 270");
          //for horizontal
          cal_value = -(HoriVert_h * 32);
          //for vertical
          v_cal_value = HoriVert_v;
          cal_value = cal_value + v_cal_value;
        }
      } else {
        assetlist_slider_val[selectedChild][0] = 0;
        assetlist_slider_val[selectedChild][1] = 0;
        setAssetlist_slider_val(assetlist_slider_val);
        console.log(
          " Inside Rest ",
          cal_value,
          actual_assetlist_pixels[selectedChild]
        );
      }

      console.log(
        "shifting cal_value",
        cal_value,
        actual_assetlist_pixels[selectedChild]
      );
      selectedChildPixels = actual_assetlist_pixels[selectedChild].map(
        (ele) => {
          console.log(ele);
          return ele + cal_value;
        }
      );
      console.log(
        "after upadting ",
        actual_assetlist_pixels[selectedChild],
        selectedChildPixels
      );
      setSelectedChildPixels(selectedChildPixels);
      assetlist_pixels[selectedChild] = selectedChildPixels;
      setAssetlist_pixels({ ...assetlist_pixels });

      const assetByPixel = {};
      Object.entries(assetlist_pixels).forEach(([assetId, pixels]) => {
        pixels.forEach((p) => (assetByPixel[p] = assetId));
      });
      setPixelslist_assetid(assetByPixel);

      let allPixels = [];
      Object.keys(assetlist_pixels).map((asset_id) => {
        if (asset_id === selectedChild) {
          selectedChildPixels.map((pixel) => {
            allPixels.push(pixel);
          });
          console.log("allPixels///////////////", allPixels);
        } else {
          assetlist_pixels[asset_id].map((pixel) => {
            allPixels.push(pixel);
          });
        }
      });
      console.log("my allPixels", allPixels, selectedChildPixels);
      setPixelslist(allPixels);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid g-0">
        <div class="row justify-content-center g-0 mainDiv">
          <div
            ref={ref}
            className="col-sm-12 col-lg-8 position-relative"
            style={{ background: "#ffffff" }}
          >
            <div
              className="row g-0 snapShotClass"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {getNumberToTest ? (
                <Mod1
                  text={{
                    primaryText: modalDetails.primaryText,
                    secondaryText: modalDetails.secondaryText,
                    icon: modalDetails.icon,
                  }}
                  // closeModal={() => setGetNumberToTest(false) }
                  confirmButton={{
                    text: modalDetails.confirmButtonText,
                    onClick: setModalDetailsAfterCheck,
                  }}
                  inputBox={modalDetails.inputBox}
                  value={modalDetails.inputBox ? userInput : null}
                  setValue={modalDetails.inputBox ? checkValueForTest : null}
                />
              ) : null}

              <div
                className="col-12"
                style={{ background: "#F5F5F5", padding: "0 5px" }}
              >
                <div className="row g-0">
                  <div
                    className="col-11 mx-auto"
                    style={{ background: "#F5F5F5" }}
                  >
                    <HeaderSecondary
                      secondaryText={title}
                      primaryText={room}
                      goBack={() => navigate(-1)}
                      tabInd={0}
                      buttonSecondary={
                        countFromSpot &&
                        ((isthermalImage && show) || snapshot) ? (
                          <button
                            style={{ border: "none", background: "none" }}
                            onClick={() => {
                              uploadSnapAndNextTest();
                            }}
                          >
                            <img
                              src={cameraIcon}
                              alt="snapshot"
                              crossOrigin="anonymous"
                            />
                          </button>
                        ) : null
                      }
                    />
                  </div>
                </div>
              </div>
              <></>
              <div className="col-11 mx-auto my-4">
                <InfoBox
                  text={infoText.text}
                  image={infoText.icon}
                  background={infoText.background}
                  confirm={
                    !isthermalImage
                      ? () => setIsthermalImage(!isthermalImage)
                      : null
                  }
                  cancel={() => navigate(-1)}
                />
              </div>

              <div
                className="col-11.5 mx-auto"
                style={{
                  marginTop: reset ? "" : "30px",
                  position: "relative",
                  height: desiredHeight,
                }}
              >
                {imgJson && data && (
                  <div id="arena" style={data.style}>
                    {/* <img src={imgJson.url} crossOrigin="anonymous"/> */}
                    <div id="tim_marker">
                      <GridView
                        data={data}
                        userHeight={spotData.height_set}
                        userAngle={spotData.orientation_set}
                        dataForGrid={dataForGrid}
                        minMax={minMax}
                        pixelslist={pixelslist}
                        setSelectedChild={setSelectedChild}
                        selectedData={true}
                        pixelslist_assetid={pixelslist_assetid}
                        selectedChildPixels={selectedChildPixels}
                        reset={reset}
                      />
                      {/* <GridView data={data} userHeight={heightInput} userAngle={angle} pixelslist={pixelslist} setSelectedChild={setSelectedChild} pixelslist_assetid={pixelslist_assetid} selectedChildPixels={selectedChildPixels} reset={reset} /> */}
                    </div>
                    <div
                      role="img"
                      aria-label="tim"
                      id="tim_marker_test"
                      style={{
                        position: "absolute",
                        top: `${data.timResult.timTempPosition.top}px`,
                        left: `${data.timResult.timTempPosition.left}px`,
                        transform: `rotate(-${spotData.orientation_set}deg)`,
                        height: "0px",
                        width: "0px",
                      }}
                    >
                      {/*this is tim marker */}
                      <img
                        src={tim}
                        alt="tim"
                        style={{
                          margin: 0,
                          position: "absolute",
                          top: "50 %",
                          left: "50 %",
                          transform: "translate(-50%, -50%)",
                          width: calTimWidth(data.style) || "20px",
                          height: calTimWidth(data.style) || "20px",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <br />
              {isthermalImage && !snapshot ? (
                // <Button1
                //     text={"Start Testing"}
                //     style={{ margin: "auto 0 0rem", minHeight: "42px" }}
                //     background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"} color={"#ffffff"}
                //     onClick={getCountFromSpot}
                // />
                <div className={`col-1${reset ? 2 : 1} mx-auto`}>
                  {imgJson &&
                    data &&
                    (reset ? (
                      <BottomGrid
                        title={title}
                        room={room}
                        changeEdit={() => {
                          setReset(!reset);
                          setInfoText({
                            ...infoText,
                            text: "Click on any one of the box you want to setup",
                          });
                        }}
                        setupDone={setupDone}
                      />
                    ) : (
                      <BottomGrid3
                        OrientationAngle={OrientationAngle}
                        defaultSelectedChildPixels={defaultSelectedChildPixels}
                        slider_val={
                          selectedChild
                            ? assetlist_slider_val[selectedChild]
                            : [0, 0]
                        }
                        shiftChildHoriVert={shiftChildHoriVert}
                        data={data}
                        changeEdit={() => {
                          setSelectedChildPixels([]);
                          setDefaultSelectedChildPixels([]);
                          setReset(!reset);
                        }}
                        callback={resetCallback}
                      />
                    ))}
                </div>
              ) : isthermalImage && snapshot ? (
                <div
                  style={{
                    background: "#f5f5f5",
                    display: "flex",
                    alignItems: "center",
                    margin: "auto 0 0rem",
                    minHeight: "42px",
                  }}
                >
                  {show && (
                    <ButtonViewTest
                      text="Rerun Test"
                      borderRadius="0px"
                      borderColor="#ff9170"
                      background="white"
                      color="#ff9170"
                      onClick={() => navigate(-1)}
                    />
                  )}
                  <ButtonViewTest
                    text={
                      countFromSpot ? "Take SnapShot" : "Test Current Space"
                    }
                    borderRadius="0px"
                    borderColor="transparent"
                    background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)"
                    color="white"
                    onClick={
                      countFromSpot
                        ? () => uploadSnapAndNextTest()
                        : () => getCountFromSpot()
                    }
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
{
  /* <div style={{ background: "#f5f5f5", display: "flex", alignItems: "center",margin: "auto 0 0rem",minHeight:"42px"}}>
                        {show && <ButtonViewTest text="Rerun Test" borderRadius="0px" borderColor="#ff9170" background="white" color="#ff9170" onClick={() => navigate(-1)} />}
                        {!countFromSpot ? <ButtonViewTest text={show ? "Test Next Space" : "Test Current Space"} borderRadius="0px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white" 
                        onClick={show ? () => uploadSnapAndNextTest() : () => testNextSpace() } /> :
                        <ButtonViewTest text={"Testing Nxt"} borderRadius="0px" borderColor="transparent" background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)" color="white" 
                        onClick={ () => {uploadSnapAndNextTest()} } />
                        } 

                    </div> :null */
}
export default LiveGridTimp;

// function GridValue(left, top, scalingFactorMeter, rotationAngleFromInput, heightFromFloor, minX, minY,pixelslist,selectedChildPixels) {
function GridValue(
  left,
  top,
  scalingFactorMeter,
  rotationAngleFromInput,
  heightFromFloor,
  minX,
  minY,
  pixelslist,
  selectedChildPixels
) {
  console.log("pixelslist in GridValue", pixelslist);
  // console.log(arguments);
  var XOfReact = parseFloat(left);
  var YOfReact = parseFloat(top);
  let height = heightFromFloor; // in meter
  let width = 1.534 * height; /* Area width*/
  let length = (2.856 * height) / 1.5; /*Area hieght*/
  var rxWidth = width * scalingFactorMeter; //10 * 24;//???
  var ryLength = length * scalingFactorMeter; //10 * 32;//???
  let gridWidth = rxWidth / 24; ////
  let gridLength = ryLength / 32;
  let gridpaint = [];
  let gridpaintArray = [];
  let i = 0;
  let j = 0;
  let p;
  for (j = 0; j < 32; j++) {
    for (i = 0; i < 24; i++) {
      p = i * 32 + (31 - j);
      gridpaint.push(`
		<g transform="rotate(-${rotationAngleFromInput} ${XOfReact + 15} ${
        YOfReact + 15
      }) translate(-${ryLength / 2 - 15} , -${rxWidth / 2 - 15})" >
		<rect  x="${XOfReact + j * gridLength}" y="${
        YOfReact + i * gridWidth
      }" width="${gridLength}" height="${gridWidth}"  style="stroke-width:0.3;" />
		<text font-size="0.1em"  x="${XOfReact + j * gridLength}" y="${
        20 + YOfReact + i * gridWidth
      }" transform="rotate(${rotationAngleFromInput} ${
        XOfReact + j * gridLength + gridLength / 2
      },${YOfReact + i * gridWidth + gridWidth / 2})"  opacity=".4" >${
        i * 32 + (31 - j)
      }</text>
		</g>
		`);

      let tempData = {
        g: {
          transform: `rotate(-${rotationAngleFromInput} ${XOfReact} ${YOfReact}) translate(-${
            ryLength / 2
          } , -${rxWidth / 2})`,
        },
        rect: {
          x: `${XOfReact + j * gridLength}`,
          y: `${YOfReact + i * gridWidth}`,
          width: `${gridLength}`,
          height: `${gridWidth}`,
          style: {
            strokeWidth:
              selectedChildPixels && selectedChildPixels.includes(p)
                ? 2
                : pixelslist.includes(p)
                ? 1
                : 0,
            cursor: pixelslist && pixelslist.includes(p) ? "pointer" : "",
          },
          pixel_val: p,
          clickble: pixelslist && pixelslist.includes(p) ? 1 : 0,
        },
        text: {
          x: `${5 + XOfReact + j * gridLength}`,
          y: `${18 + YOfReact + i * gridWidth}`,
          transform: `rotate(${rotationAngleFromInput} ${
            XOfReact + j * gridLength + gridLength / 2
          },${YOfReact + i * gridWidth + gridWidth / 2})`,
          value: p,
        },
      };
      gridpaintArray.push(tempData);
    }
  }
  // console.log("gridpaintArray",gridpaintArray)
  return [gridpaint, gridpaintArray, rxWidth, ryLength, minX, minY];
}

// const GridView = ({ data, userHeight, userAngle,pixelslist,setSelectedChild,pixelslist_assetid ,selectedChildPixels,reset}) => {
const GridView = ({
  data,
  userHeight,
  userAngle,
  dataForGrid,
  minMax,
  pixelslist,
  setSelectedChild,
  pixelslist_assetid,
  selectedChildPixels,
  reset,
}) => {
  function getselected(pixel, pixellist, selectedpixellistt) {
    return selectedpixellistt && selectedpixellistt.includes(pixel)
      ? "green"
      : pixellist.includes(pixel)
      ? "blue"
      : "";
  }

  function fillOpacity(value, pixel_val, fillt = null) {
    let opacity;
    if (minMax["max"] - minMax["min"] !== 0) {
      opacity =
        value == null
          ? 0
          : ((value - minMax["min"]) * (1 - 0)) /
              (minMax["max"] - minMax["min"]) +
            0;
      // opacity = pixelslist.includes(pixel_val) ? opacity : opacity / 5;
      opacity = pixelslist.includes(pixel_val) ? opacity : opacity / 1.5;
      console.log("opacity",opacity)

      return value > 0
        ? `rgba(255, 0, 0, ${opacity})`
        : `rgba(0,128, 0, ${opacity})`;
    } else return "rgba(0, 128 , 0, 0)";
  }
  // console.log('userAngle', userAngle);
  // console.log('userHeight', userHeight);
  // console.log('GridView', data);
  let { scalingFactor } = data;
  let { left, top } = data.timResult.timTempPosition;
  let { angle } = data.timResult;
  let { heightFromFloor } = data.timResult;
  let { x, y } = data.offset;
  // let [returngridpaint, returngridpaintArray, rxWidth, ryLength] = GridValue(left, top, scalingFactor, userAngle, userHeight, x, y,pixelslist,selectedChildPixels);
  let [returngridpaint, returngridpaintArray, rxWidth, ryLength] = GridValue(
    left,
    top,
    scalingFactor,
    userAngle,
    userHeight,
    x,
    y,
    pixelslist,
    selectedChildPixels
  );

  return (
    <svg
      width={data.originalWidth}
      height={data.originalHeight}
      // style={{ transform: `translate(-${rxWidth / 2}px, -${ryLength / 2}px)` }}
      // viewBox={`${x} ${y} ${data.originalWidth} ${data.originalHeight}`}
      // viewBox="10 10 10 10"
    >
      {/* <rect width={rxWidth} height={ryLength} style={{ strokeWidth: 0.3, fill: 'transparent' }} /> */}
      {returngridpaintArray.map(({ g, rect, text }) => {
        return (
          // < g transform={g.transform} key={text.value}>
          <g
            transform={g.transform}
            key={text.value}
            pixel_val={rect.pixel_val}
            pix={text.value}
            onClick={() => {
              if (rect.clickble && !reset) {
                /*alert(text.value);*/ setSelectedChild(
                  pixelslist_assetid[text.value]
                );
              }
            }}
          >
            {/* <rect x={rect.x} y={rect.y} width={rect.width} height={rect.height} style={{ strokeWidth: 1, stroke: 'rgba(255, 81, 72,0.1)', fill: fillOpacity(dataForGrid[text.value]) }} /> */}
            <rect
              x={rect.x}
              y={rect.y}
              width={rect.width}
              height={rect.height}
              style={{
                strokeWidth: rect.style.strokeWidth,
                /*stroke: rect.style.stroke*/ stroke: getselected(
                  rect.pixel_val,
                  pixelslist,
                  selectedChildPixels
                ),
                cursor: rect.style.cursor,
                fill: fillOpacity(dataForGrid[text.value], rect.pixel_val),
              }}
            />

            {/* <text fontSize="0.3em" x={text.x - 5} y={text.y - 10} transform={text.transform} opacity="1" >{dataForGrid[text.value]}</text> */}
          </g>
        );
      })}
    </svg>
  );
};
