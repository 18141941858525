import capacityIcon from "../../assets/images/capacity.svg"
import "./squareCard.css"

function SquareCard({ name = "hello", capacity = "000", onClick, status, onResetClick }) {


    return (<>
        <div className="border rounded-lg sqaure-card-body" onClick={onClick} tabIndex={0} onKeyDown={(e) => {/*console.log(e.key);*/ if (e.key === 'Enter' || e.key === ' ') onClick() }} >
            <div className="sqaure-card-body-contentDiv">

                <div><h3 className="square-card-space-name">{name}</h3></div>
                <span className="square-card-space-capacity">
                    <img src={capacityIcon} style={{ margin: "0 2px 2px 2px" }}></img>
                    <span className="capaciity">{capacity} capacity</span>
                </span>
                {status ? 
                    <div className="square-card-status-for-space-reset-button">
                    <span className="square-card-status-for-space" style={{ background: status.background }}>{status.status}</span>
                    {onResetClick ? <button onClick={(e) => { e.stopPropagation(); onResetClick() }} onKeyDown={(e) => { if (e.key === "Enter" || e.key === ' ') { e.stopPropagation(); onResetClick() } }} className="buttonStandard2" style={{ marginTop: "0.5rem" }}>Reset</button> : null}
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    </>);
}

export default SquareCard;